import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-view-merchant-dashboard',
  templateUrl: './view-merchant-dashboard.component.html',
  styleUrls: ['./view-merchant-dashboard.component.scss']
})
export class ViewMerchantDashboardComponent implements OnInit {

  storeId: any;
  adminId: any;
  merchantId: any;
  user: any;
  dashboard = [];
  data: any;


  constructor(private route: ActivatedRoute, private router: Router, private service: ApiService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));

    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.storeId = params['id'];
      console.log("params----" + params['id']);
      console.log(this.merchantId);


      // this.getStoreInfo();
      this.getmerchant();

    });

  }

  goToManageMerchant() {
    this.router.navigate(['/admin/manage-merchants']);
  }

  getmerchant() {

    this.service.getMerchantdashboard(this.storeId).subscribe((res): any => {

      this.dashboard.push(res['dashboard'])

      console.log('data', this.dashboard)
    })
  }

}
