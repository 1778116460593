import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';

// import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar, MatInput } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
  startDate: any;
  endDate: any;
  driveradmin: any
  keyword: string;
  bookings = [];
  storeId: string
  adminState: string
  bookingList: any;
  query: string
  search: any;
  store: string
  selected: true
  field: any = '';
  adminId: any;
  readioSelected: any = '';

  currentPage: Number;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  status: any;
  usersession: any;
  permissions: any;
  onload: boolean = false

  @ViewChild('enddate', {
    read: MatInput
  }) enddate: MatInput;
  @ViewChild('startdate', {
    read: MatInput
  }) startdate: MatInput;
  csvList: any;
  constructor(
    private _services: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }
  ngOnInit() {
    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).performance
    console.log(this.permissions, "permissionss")
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    this.searchFilter(this.currentPage);
  }

  getStartDate(ev) {
    this.startDate = moment(ev.value).startOf('day').valueOf();
    // console.log('Startdate', this.startDate)
  }

  getEndDate(ev) {
    console.log("DATE", ev)
    this.endDate = moment(ev.value).endOf('day').valueOf();
    // console.log('enddate', this.endDate)
  }

  searchFilter(page) {
    if (this.endDate < this.startDate)
      return this.toastr.error('Enter a valid dat')
    let data =
    {
      page: page,
    };
    if (this.startDate) {
      data['tripStart'] = this.startDate;
    }
    if (this.endDate) {
      data['tripEnd'] = this.endDate;
    }


    this._services.filterBookingPerformanceList(data).subscribe((res: any) => {
      this.bookings = res.drivers;
      // this.bookings.forEach(ele => {
      //   ele.totalAmount = ele.itemAmount + ele.fare;
      // });
      // this.driveradmin=res.bookings.driverId.driverAdmin
      this.onload = true
      this.config = {
        id: "page",
        currentPage: page,
        itemsPerPage: 10,
        totalItems: res.total
      }

    });
  }

  resetFilter() {
    this.field = '';
    this.keyword = '';
    this.status = '';
    this.startDate = '',
      this.endDate = '',
      this.startdate.value = null;
    this.enddate.value = null;
    this.searchFilter(this.currentPage);

  }

  pageChange(newPage: number) {
    console.log("newPage", newPage)
    // this.getBookingList(newPage)
    this.searchFilter(newPage)
  }





}
