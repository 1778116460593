import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../../services/chat.service';


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  providers: [ChatService]
})

export class TrackingComponent implements OnInit {

  adminId: string
  baseUrl: string
  keyword:any
  cordinates:any
  show: boolean = false
  drivers: any
  driverCount:number = 0
  loadTrack: boolean = false
  public lat: Number=25.276987
  public lng: Number =55.296249

  public origin: any;
  public destination: any;
  markerShow:any[];


  public renderOptions = {
    suppressMarkers: true,
}

public markerOptions = {
    origin: {
        icon: 'assets/images/tracking/source.png'
    },
    destination: {
        icon: 'assets/images/tracking/dest.png'
    },
}
  public waypoints: any
  searchText='';
  locations:any
  message: string;
  messages: string[] = [];
  id: string = ''
  listener: string = ''
  usersession: any;
  permissions: any;
  constructor(
    private chatService: ChatService,
    private router: Router,
    private service: ApiService) {
  }

  ngOnInit() {
    console.log("i updated");
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).tracking
     localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    // console.log("currentUrl====", this.router.url);
    // localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
this.show=true
    this.adminId = JSON.parse(localStorage.getItem('curruntUser'))._id;
    this.baseUrl = this.service.baseUrl

    this.service.getTrackingDrivers(this.adminId)
      .subscribe(
        (data: any) => {
          console.log("data.drivers", data.drivers);
          this.drivers = data.drivers
          console.log("driversdrivers",this.drivers)
          this.driverCount = data.drivers.length
        
          
          // this.cordinates=this.drivers[]
          
        
          // this.cordinates=this.drivers.driverId.coordinates
console.log("cordinates",this.cordinates)
        })
       
        
        // var locations = [
        //   ['Bondi Beach', -33.890542, 151.274856, 4],
        //   ['Coogee Beach', -33.923036, 151.259052, 5],
        //   ['Cronulla Beach', -34.028249, 151.157507, 3],
        //   ['Manly Beach', -33.80010128657071, 151.28747820854187, 2],
        //   ['Maroubra Beach', -33.950198, 151.259302, 1]
        // ];
        // var map = new google.maps.Map(document.getElementById('map'), {
        //   zoom: 10,
        //   // center: new google.maps.LatLng(-33.92, 151.25),
        //   // mapTypeId: google.maps.MapTypeId.ROADMAP
        // });
    
        // var infowindow = new google.maps.InfoWindow();
        
        // var marker, i;
    
        // for (i = 0; i < locations.length; i++) {  
        //   marker = new google.maps.Marker({
        //     position: new google.maps.LatLng(location[i][1], location[i][2]),
        //     map: map
        //   });
    
        //   google.maps.event.addListener(marker, 'click', (function(marker, i) {
        //     return function() {
        //       infowindow.setContent(location[i][0]);
        //       infowindow.open(map, marker);
        //     }
        //   })(marker, i));
        // }

  }
  // let i=0;
  // for (i = 0; i < this.driverCount; i++)
  // if (this.driverCount[i] == i) 
  // console.log("ghfhgf",this.driverCount[i])
 
  change(val) {
    // console.log(val)
    this.markerShow = val.locations;
    this.markerShow.pop();
    if (this.listener) {
      this.chatService.removeListener(this.listener)
    }
    this.listener = val._id
    this.loadTrack = true;
    this.show=false;
    this.origin = { lat: Number(val.source.latitude), lng: Number(val.source.longitude),"stopover":false };
    this.destination = { lat: Number(val.destination.latitude), lng: Number(val.destination.longitude),"stopover":false }
    this.lat = Number(val.source.latitude)
    this.lng = Number(val.source.longitude)
    this.chatService
      .getTracking(this.listener)
    .subscribe((message: any) => {
      this.lat = Number(message.location.latitude)
      this.lng = Number(message.location.longitude)

     this.waypoints = [{location:{ "lat": Number(message.location.latitude), "lng": Number(message.location.longitude)},"stopover":false }];
    

    
    this.messages.push(message);
    });
  }

  getDirection() {

    this.origin = { lat: 30.7191, lng: 76.7487 };
    this.destination = { lat: 30.7398, lng: 76.7827 };
  }


  OnDashClick(){
    this.router.navigate(['/admin/admin-dashboard']);
  }
  searchFilter(){
    var data= {
      "adminId":this.adminId,
      "search":this.keyword,
      "skip":0,
      "limit":10
    }
    this.service.geAllCarList(data).subscribe((res)=>{
      if(res['success'] == true){
        // console.log(res['admin']);
        // this.vehicleData = res['data'];
      }
    });
  }

  resetFilter(){
    this.searchText='';
    this.service.getTrackingDrivers(this.adminId)
      .subscribe(
        (data: any) => {
          console.log("data.drivers", data.drivers);
          this.drivers = data.drivers
          this.driverCount = data.drivers.length

        })

  }
  onMouseOver(infoWindow, gm) {

    if (gm.lastOpen != null) {
        gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
}



  // sendMessage() {
  //   this.chatService.sendMessage(this.message);
  //   this.message = '';
  // }

}
