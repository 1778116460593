import { Component,OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MouseEvent as AGMMouseEvent } from '@agm/core';
import { MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { Router,Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-geofence',
  templateUrl: './edit-geofence.component.html',
  styleUrls: ['./edit-geofence.component.scss']
})
export class EditGeofenceComponent implements OnInit {
  address: string;
  driverName: any;
  private geoCoder;
  selectedZoom: number;
  latitude: number;
  longitude: number;
  zoom: number = 11;
  searchString: any;
  polyarray: any = [];
  geoId:any
  driverId:any
  drawingManager: any;
  addgeofence: boolean = true;
  fencingList: any = [];
  constructTriangle: any;
  @ViewChild("AgmMap") Map: any;
  adminId: any;
  vacantDriversList: any = [];
  selected: any[];
  otherDriversList: any = [];
  vacantDriverView: boolean = false;
  fancingDriverName: any;
  vacantDrivers: any = [];
  fencingDrivers: any;
  geoFenceId: any;
  fencingDriversList: any;
  geoFenceList: any;
  formGroup: any;
  list: any;
  multiple: any;
  usersession: any;
  permissions: any;
  geofenceId: any;
  locationPoints: any;


  constructor(private service: ApiService, private mapsAPILoader: MapsAPILoader,
    public snackBar: MatSnackBar, private ngZone: NgZone, private router: Router,private route:ActivatedRoute ) {
    this.setCurrentLocation();
  }
  ngAfterViewInit() { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.geofenceId = params['id']
     

    });

    // this.multiple.vacantDrivers.setValue(this.selected);

    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));

    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).geofencing

    this.adminId = curruntUser._id;


    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
    });
    this.getFencing()
  }
     getFencing() {
    this.service.getGeofencing(this.geofenceId).subscribe((res) => {
       if (res['success'] == true) {
         console.log(res);
         this.locationPoints = res['geoFencing'].locationPoints;
         console.log(res,  this.locationPoints,this.searchString);
         this.searchString=res['geoFencing'].name

     }
     this.onEdit( this.locationPoints,this.geoFenceId)
     });
   }
   save()
   {
    this.getPolygonCoordinates(this.constructTriangle);
   }
   Back(){
      this.router.navigate(['/admin/geofencing'])
  }
   setCurrentLocation() {
      if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log("name", position);
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 11;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
 
  markerDragEnd(ev) {
    // console.log(ev);
  }
 
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

    });
  }
  onMapReady(map) {
    console.log("DATA")
    this.Map = map;
    // this.initDrawingManager(this.Map);
  }
  onMapReady1(map) {
    this.Map = map;
    this.initDrawingManager(this.Map);

  }

  initDrawingManager(map: any) {
 this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    });
  
    this.drawingManager.setMap(map);


    google.maps.event.addListener(this.drawingManager, 'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) { //this is the coordinate, you can assign it to a variable or pass into another function. 

          this.polyarray = event.overlay.getPath().getArray();
        }
      });

  }

  getPolygonCoordinates(draggablePolygon) {
    const len = draggablePolygon.getPath().getLength();
    const polyArrayLatLng = [];
  
    for (let i = 0; i < len; i++) {
      const vertex = draggablePolygon.getPath().getAt(i);
      const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
      polyArrayLatLng.push(vertexLatLng);
    }
    console.log(polyArrayLatLng);

    if (this.searchString) {
     this.addgeofence = false;
      var geofenceData = {
        "geoId": this.geofenceId,
        "name": this.searchString,
        "locationPoints":polyArrayLatLng
      }
        console.log("geofencedata",geofenceData)
      this.service.updategeoFencing(geofenceData).subscribe((res) => {

        if (res['success'] == true) {
          this.addgeofence = false;
        
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
          this.router.navigate(['/admin/geofencing'])
        }
       
      });
    } else {
      this.snackBar.open('Please select region name', 'x', {
        duration: 5000,
      })
    }
    
  }
    
   

  
 onEdit(locationPoints, geoId) {
    this.geoFenceId = '';
    // console.log('geoId', geoId);

    this.geoFenceId = geoId;
    var drawPolygonArr = [];
   locationPoints.forEach(element => {
      drawPolygonArr.push({
        lat: element.lat,
        lng: element.lng
      })
    });
    console.log("drawPolygonArr",drawPolygonArr)
    if (this.constructTriangle) {
      this.constructTriangle.setMap(null)
    }
    // Construct the polygon.
    this.constructTriangle = new google.maps.Polygon({
      paths: drawPolygonArr,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      editable: true,
      draggable:true
    })
    this.constructTriangle.setMap(null);

    this.constructTriangle.setMap(this.Map);
    
   
    // google.maps.event.addListener(this.constructTriangle, 'dragend',(event) => {
      // this.polyarray = event.overlay.getPath().getArray();
      
          
      // polygon.setPath(arrCoords);
    // (event) => {
    //   console.log(event,"ecehkjjk")
    //  this.polyarray = event.latLng.lat()

        console.log("this.polyarray ",this.polyarray )
    //   // }
    // });
    // })
  }

  }
  

