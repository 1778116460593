import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  userId:any;
  Review:any={}
  review:any;
  avg:any
  constructor(private route: ActivatedRoute,private router: Router, private service:ApiService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      // console.log(this.userId);
      if(this.userId!='' && this.userId!=undefined && this.userId!=null){
        this.getreviewList();
      }
    });
  }

  goTocustomer()
{
  this.router.navigate(['/admin/manage-user']);
}

getreviewList(){
  
  this.service.getUserview(this.userId).subscribe((res:any)=>{

    console.log(res);
    this.Review=res
    this.review=this.Review.review
    console.log("review",this.Review)
    
  })
  // console.log("review",this.Review)
}

}
