import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-view-user-activity',
  templateUrl: './view-user-activity.component.html',
  styleUrls: ['./view-user-activity.component.scss']
})
export class ViewUserActivityComponent implements OnInit {
  userId:any;
  userData:any;
  emails:any;
  status:any;
  public loader: boolean = true
  passwords:any;
  constructor(private route: ActivatedRoute,private router: Router, private service:ApiService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      // console.log(this.userId);
      if(this.userId!='' && this.userId!=undefined && this.userId!=null){
        this.getUserActivityList();
      }
    });
  }

  goToManageUser()
{
  this.router.navigate(['/admin/manage-user']);
}

getUserActivityList(){
  
  this.service.getUserActivityListFromDb(this.userId).subscribe((res)=>{

    console.log(res);
    
    if(res['success']==true){
      this.loader = false
      // this.userData = res['payment'];
       this.userData = res['payment'];
       this.emails=res['email'],
       this.status=res['status'],
       console.log("status",this.status);

       console.log("userData",this.emails);
      this.passwords=res['password']
      console.log("userData",this.userData);

      
    }
    

  })
}

}
