import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { Token } from '@angular/compiler';
import { Observable } from 'rxjs';


// const httpOptions = { 
//   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
//   };

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  // baseUrl: string = "http://192.168.1.13:9005/";
  // baseUrl: string = "https://appgrowthcompany.com:7080";
  baseUrl: string = "https://api.nayo.cm/";
  // baseUrl: string = "https://nayoapi.appgrowthcompany.com/";


  // baseUrl: string = "http://13.232.208.65:9000/";
  // baseUrl: string = "https://trotdrive.com:8002/"
  // baseUrl: string = "https://www.buraqdelivery.com:8001/";



  public allowDoc = ['jpg', 'jpeg', 'png'];
  public notificationMessage: any;
  registerStore: any;
  constructor(private http: HttpClient, public router: Router) {
  }
  getUsers() {
    return this.http.get(this.baseUrl + `users`)
  }

  login(body) {
    return this.http.post(this.baseUrl + `v1/admin/customer/login`, body)
  }

  registerUser(body) {
    return this.http.post(this.baseUrl + `v1/admin/user/register`, body)
  }
  ChangePassword(body) {
    return this.http.put(this.baseUrl + `v1/admin/customer/changepass`, body)
  }
  editUserInfo(body) {
    return this.http.put(this.baseUrl + `v1/admin/user/profile`, body)
  }

  getAllAdminList(data) {
    console.log("data", data)
    let url = this.baseUrl + 'v1/admin/user?page=' + data.page;
    if (data.field) {
      url = url + '&field=' + data.field;
    }
    if (data.keyword) {
      url = url + '&keyword=' + data.keyword;
    }
    if (data.status) {
      url = url + '&status=' + data.status;
    }
    if (data.isExport == 0) {
      url = url + '&isExport=' + data.isExport;
    }
    return this.http.get(url);
  }
  contactus(body) {
    //API call to Update contact us details
    return this.http.put(this.baseUrl + `v1/admin/customer/contactUs`, body)
  }

  refer(body) {
    //API call to Update contact us details
    return this.http.post(this.baseUrl + `v1/admin/customer/booking/referCommission`, body)
  }

  getUserInfoFromDb(userId) {
    return this.http.get(this.baseUrl + `v1/admin/user/` + userId)
  }

  getRefralSetting(adminId) {
    //API call to get refral setting
    return this.http.get(this.baseUrl + `v1/customer/refralsetting/` + adminId)
  }

  getDriverSearchRadious() {
    //API call to get radious in meter for driver search
    return this.http.get(this.baseUrl + 'v1/admin/customer/driver/getDistance')
  }

  updateDriverSearchRadious(body): Observable<any> {
    //API call to Update radious in meter for driver search
    return this.http.post(this.baseUrl + 'v1/admin/customer/driver/updateDistance', body)
  }



  getDatafromJSON(): Observable<any> {
    return this.http.get("./assets/data.json");
  }

  updateCommisionPercentage(body): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/admin/customer/driver/updateCommsion', body)
  }

  getCommisionPercentage(): Observable<any> {
    return this.http.get(this.baseUrl + 'v1/admin/customer/driver/getCommsion')
  }


  getRewardSetting(adminId) {

    return this.http.get(this.baseUrl + `v1/customer/rewardssetting/` + adminId)
  }
  getContactus(body) {
    return this.http.get(this.baseUrl + `v1/admin/customer/contactUs/`, body)
  }

  referal() {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/getreferCommission`)
  }
  addRefralSetting(form) {
    return this.http.post(this.baseUrl + `v1/customer/refralsetting`, form)
  }
  addRewardSetting(form) {
    return this.http.post(this.baseUrl + `v1/customer/rewardssetting`, form)
  }
  chnageUserStatus(data) {
    return this.http.put(this.baseUrl + `v1/admin/user`, data)
  }
  filterBookingListFrom(status, field, search) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/book?status=${status}&&field=${field}&search=${search}`)
  }
  createDriver(form, carLicence, drivingLicence, numberPlate, taxiPermit) {
    var fd = new FormData();
    fd.append("data", JSON.stringify(form));
    fd.append('licence', drivingLicence)
    fd.append('plate', numberPlate)
    fd.append('inzi', taxiPermit)
    fd.append('rc', carLicence)
    return this.http.post(this.baseUrl + `v1/admin/customer/driver/register`, fd)
  }

  geAlltDriversList(field, key, status, page, limit, isAvailable) {
    return this.http.get(this.baseUrl + `v1/admin/customer/driver?status=` + status + `&field=` + field + `&keyword=` + key + '&page=' + page + `&limit=` + limit + `&isAvailable=` + isAvailable)
  }

  // getVehicleListFromDb(){
  //   return this.http.get(this.baseUrl+`v1/customer/vehicle/category/5d2eb89f6773f6243b5738fe`)
  // }




  // "isDeleted":true

  deleteCarCategory(body) {
    return this.http.post(this.baseUrl + 'v1/customer/vehicle/deletetypes', body)

  }




  //see here
  getVehicleListFromDb(adminId) {
    return this.http.get(this.baseUrl + `v1/driver/vehicletype/` + adminId)
  }

  chnageDriverStatus(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driver/changedriverstatus`, data)
  }
  chnageCODStatus(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driver/iscod`, data)
  }
  userData(data) {
    return this.http.post(this.baseUrl + ``, data)

  }

  getDriverInfoFromDb(driverId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/driver/` + driverId)
  }

  getDriverDoc(driverId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/driver/docs/` + driverId)
  }
  getadminDriverDoc(userId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/driverAdmin/` + userId)
  }
  updateDriverDocuments(formDocuments) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driver/docs/`, formDocuments)

  }

  updateDriver(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driver/editdriver`, data)
  }

  getAllDriverAdminList(field, keyword, status, page) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/driverAdmin?field=' + field + '&keyword=' + keyword + '&status=' + status + '&page=' + page)
  }

  registerDriverAdmin(firstDoc, secondDoc, thirdDoc, form) {
    console.log('body', form)
    var fd = new FormData();
    fd.append("data", JSON.stringify(form));
    fd.append('document1', firstDoc)
    fd.append('document2', secondDoc)
    fd.append('document3', thirdDoc)
    // fd.append('profileImages', fourthDoc)
    return this.http.post(this.baseUrl + `v1/admin/customer/driverAdmin/register`, fd)
  }

  chnageDriverAdminStatus(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driverAdmin/status`, data)
  }

  getDriverAdminInfoFromDb(userId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/driverAdmin/` + userId)
  }

  editDriverAdminInfo(body) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driverAdmin`, body)
  }

  registerDeliveryAdmin(body) {
    return this.http.post(this.baseUrl + `v1/admin/customer/deliveryAdmin/register`, body)
  }

  getDeliveryAdminList(field, keyword, status, page) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/deliveryAdmin?field=' + field + '&keyword=' + keyword + '&status=' + status + '&page=' + page)
  }

  editDeliveryAdminInfo(body) {
    return this.http.put(this.baseUrl + `v1/admin/customer/deliveryAdmin`, body)
  }

  getDeliveryAdminInfoFromDb(userId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/deliveryAdmin/` + userId)
  }

  chnageDeliveryAdminStatus(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/deliveryAdmin/status`, data)
  }

  getMerchantsList(field, keyword, status, adminId, page) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/stores?adminId=` + adminId + `&field=` + field + `&keyword=` + keyword + `&status=` + status + '&page=' + page)
  }
  getMerchantsBooking(data) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/storesMerchant`, data)
  }

  registerMerchants(firstDoc, secondDoc, thirdDoc, fourthDoc, form) {

    console.log('form', form);
    var fd = new FormData();
    fd.append("body", JSON.stringify(form));
    fd.append('document1', firstDoc)
    fd.append('document2', secondDoc)
    fd.append('document3', thirdDoc)
    fd.append('profileImages', fourthDoc)
    return this.http.post(this.baseUrl + `v1/admin/customer/merchant/register`, fd)
  }

  getMerchantCategoryListFromDb() {
    return this.http.put(this.baseUrl + `v1/admin/customer/merchant/category`, { name: "" });
  }

  getMerchantList(adminId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/stores?adminId=` + adminId)
  }

  editMerchantsInfo(body) {
    return this.http.post(this.baseUrl + `v1/admin/customer/merchant/update`, body)
  }
  deletegeofence(geoId) {
    return this.http.delete(this.baseUrl + 'v1/geoFencing?geoId=' + geoId)
  }
  getSearchList(phone, countryCode, storeId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/getBooking?phone=` + phone + '&countryCode=' + countryCode + '&storeId=' + storeId)

  }
  chnageMerchantStatus(status) {
    return this.http.put(this.baseUrl + `v1/admin/customer/merchant/status`, status)
  }

  getStoreInfoFromDb(storeId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/` + storeId)
  }

  getMerchantStoreDoc(storeId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/` + storeId + `?docs=true`)
  }

  updateMerchantDocuments(data) {
    return this.http.post(this.baseUrl + `v1/admin/customer/merchant/update/docs`, data)
  }
  updatedriverDocuments(data) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driverAdmin/docs`, data)
  }

  getBookingListFromDb({ page }) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/book?page=${page}&status=&sendall=0`)
  }



  getPendingbookings({ page }) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/unbook?page=${page}&&status=`)
  }
  getreview({ page }) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/bookRewiew?page=${page}&&status=`)
  }
  getscheduled(body) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/futureBookings`
      , body)
  }
  changeBookingStatusFromDb(data) {

    console.log("sending data mto api", data);

    return this.http.put(this.baseUrl + `v1/admin/customer/booking/book`, data)
  }

  updateVerifyStatus(form) {
    return this.http.put(this.baseUrl + `v1/admin/customer/driver/changeverify`, form)
  }

  changeBookingStatus(data) {
    return this.http.get(this.baseUrl + `v1/deliveryAdmin/assignDriver?data=` + JSON.stringify(data));

  }

  forgotPassword(body) {
    return this.http.post(this.baseUrl + 'v1/deliveryAdmin/forgot', body);
  }

  getNotificationList(field) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/notification?field=' + field);

  }
  sendNotificationList(body) {
    return this.http.post(this.baseUrl + 'v1/admin/customer/notification', body);

  }

  getUserActivityListFromDb(userId) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/user/booking?userId=' + userId);
  }
  getUserview(userId) {
    return this.http.get(this.baseUrl + 'v1/admin/user/review?userId=' + userId);
  }
  getdrivers(source, vehicletype) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/stores?source=` + source + '&vehicletype=' + vehicletype)

  }
  getdriverslist(latitude, longitude, vehicletype) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/getDrivers?latitude=` + latitude + '&longitude=' + longitude + '&vehicletype=' + vehicletype)

  }
  filterBookingPerformanceList(data) {
    let url = this.baseUrl + 'v1/admin/customer/booking/manageRevenueDriverByDate?page=' + data.page;
    if (data.tripStart) {
      url = url + '&tripStart=' + data.tripStart
    }
    if (data.tripEnd) {
      url = url + '&tripEnd=' + data.tripEnd
    }
    return this.http.get(url);
  }
  getInvoiceById(id) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/getAllstoresMerchant?merchantId=` + id)
  }
  getInvoicePDFById(id) {
    return this.http.get(this.baseUrl + `v1/admin/customer/merchant/getInvoicePdf?merchantId=` + id)

  }
  filterInvoiceDb(data) {
    let url = this.baseUrl + 'v1/admin/customer/merchant/getAllstoresMerchant?page=' + data.page + '&limit=' + data.limit
    // if (data.field) {
    //   url = url + '&field=' + data.field;
    // }
    if (data.startdate) {
      url = url + '&tripStart=' + data.startdate;
    }
    if (data.enddate) {
      url = url + '&tripEnd=' + data.enddate;
    }
    // if (data.status) {
    //   url = url + '&status=' + data.status;
    // }
    // if (data.search) {
    //   url = url + '&search=' + data.search;
    // }
    return this.http.get(url);
  }

  filterBookingListFromDb(data) {
    let url = this.baseUrl + 'v1/admin/customer/booking/book?page=' + data.page + '&sendall=0' + '&isCod=' + data.isCod;
    if (data.field) {
      url = url + '&field=' + data.field;
    }
    if (data.startdate) {
      url = url + '&startdate=' + data.startdate;
    }
    if (data.enddate) {
      url = url + '&enddate=' + data.enddate;
    }
    if (data.status) {
      url = url + '&status=' + data.status;
    }
    if (data.search) {
      url = url + '&search=' + data.search;
    }
    return this.http.get(url);
  }

  getCsvBookingList(data) {
    let url = this.baseUrl + 'v1/admin/customer/booking/book?page=' + data.page + '&sendall=1' + '&isCod=' + data.isCod;
    if (data.field) {
      url = url + '&field=' + data.field;
    }
    if (data.startdate) {
      url = url + '&startdate=' + data.startdate;
    }
    if (data.enddate) {
      url = url + '&enddate=' + data.enddate;
    }
    if (data.status) {
      url = url + '&status=' + data.status;
    }
    if (data.search) {
      url = url + '&search=' + data.search;
    }
    return this.http.get(url);
  }


  getTrackingDrivers(adminId) {
    return this.http.get(this.baseUrl + `v1/admin/customer/trackdrivers/` + adminId)
  }

  getSelectedDateFromDb(type, start, end) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/dashboard?type=' + type + '&startdate=' + start + '&enddate=' + end, {});
  }
  notifications(adminId, receverType) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/notification/notilist?receiver=' + adminId + '&receverType=' + receverType);
  }

  getVacantDrivers(locationPoints, geoId) {
    var newlocation = {
      location: [locationPoints, locationPoints[0]],
      geoId

    }
    return this.http.put(this.baseUrl + `v1/geoFencing/getDriver`, newlocation)
  }

  selectDriversForGeofencing(data) {
    return this.http.post(this.baseUrl + `v1/geoFencing/getDriver`, data)
  }
  //  getDriverList(geoFenceId){
  //    return this.http.get(this.baseUrl +'v1/geoFencing/getDriver?geoId='+geoFenceId)
  //  }



  //shilpa'sWork

  /**Manage car categories section **/
  //  createCarCategories(form, vehicleImage,vehicleActiveImage){
  //   var fd = new FormData();
  //   fd.append("data", JSON.stringify(form));
  //   fd.append('image', vehicleImage)
  //   fd.append('activeImage', vehicleActiveImage)
  //   return this.http.post(this.baseUrl + `v1/customer/vehicle/vehicletype`, fd)
  // }
  // geAllCarList(data){
  //   return this.http.post(this.baseUrl+`v1/customer/vehicle/types`,data);
  // }
  // getCarInfoFromDb(carId){
  //   return this.http.get(this.baseUrl+`v1/customer/vehicle/types/`+ carId)
  // }
  // editCarInfo(form, vehicleImage,vehicleActiveImage){
  //   var fd = new FormData();
  //   fd.append("data", JSON.stringify(form));
  //   fd.append('image', vehicleImage)
  //   fd.append('activeImage', vehicleActiveImage)
  //   return this.http.put(this.baseUrl + `v1/customer/vehicle/vehicletype`, fd)
  // }
  // changeCarStatus(data){
  //   return this.http.put(this.baseUrl+`v1/customer/vehicle/types/status`,  data)
  // }
  // creategeoFencing(data){
  //   return this.http.post(this.baseUrl+`v1/geoFencing/create`,data);
  // }
  // getGeofencingList(){
  //   return this.http.get(this.baseUrl+`v1/geoFencing`)
  // }

  // addCoupon(data){
  //   return this.http.post(this.baseUrl+'v1/admin/customer/promo/',{data:data});
  //  }
  //  getCouponList(data){
  //   return this.http.post(this.baseUrl+'v1/admin/customer/promo/get',data);
  //  }
  //  getCouponProfile(couponid){
  //   return this.http.get(this.baseUrl+'v1/admin/customer/promo/'+ couponid)
  //  }
  //  editCoupon(data){
  //   return this.http.put(this.baseUrl+'v1/admin/customer/promo',  data)
  //  }
  //  changeCouponStatus(data){
  //   return this.http.put(this.baseUrl+'v1/admin/customer/promo/status',  data)
  //  }

  //  getPaymentList(){
  //   return this.http.get(this.baseUrl+'v1/admin/customer/earning');
  //  }


  /**Manage car categories section **/
  createCarCategories(form, vehicleImage, vehicleActiveImage) {
    var fd = new FormData();
    fd.append("data", JSON.stringify(form));
    fd.append('image', vehicleImage)
    fd.append('activeImage', vehicleActiveImage)
    return this.http.post(this.baseUrl + 'v1/customer/vehicle/vehicletype', fd)
  }
  geAllCarList(data) {
    return this.http.post(this.baseUrl + 'v1/customer/vehicle/types', data);
  }
  getCarInfoFromDb(carId) {
    return this.http.get(this.baseUrl + 'v1/customer/vehicle/types/' + carId)
  }
  editCarInfo(form, vehicleImage, vehicleActiveImage) {
    var fd = new FormData();
    fd.append("data", JSON.stringify(form));
    fd.append('image', vehicleImage)
    fd.append('activeImage', vehicleActiveImage)
    return this.http.put(this.baseUrl + 'v1/customer/vehicle/vehicletype', fd)
  }
  changeCarStatus(data) {
    return this.http.put(this.baseUrl + 'v1/customer/vehicle/types/status', data)
  }
  creategeoFencing(data) {
    return this.http.post(this.baseUrl + 'v1/geoFencing/create', data);
  }
  updategeoFencing(data) {
    return this.http.put(this.baseUrl + 'v1/geoFencing', data);
  }
  getGeofencingList(keyword) {
    let url = this.baseUrl + 'v1/admin/customer/geoFencing';
    if (keyword) {
      url = url + '?keyword=' + keyword;
    }
    return this.http.get(url)
  }

  getGeofencing(geoFenceId) {
    return this.http.get(this.baseUrl + 'v1/geoFencing?geoId=' + geoFenceId)
  }
  addCoupon(data) {
    return this.http.post(this.baseUrl + 'v1/admin/customer/promo/', { data: data });
  }
  getCouponList(data) {
    return this.http.post(this.baseUrl + 'v1/admin/customer/promo/get', data);
  }
  getCouponProfile(couponid) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/promo/' + couponid)
  }
  editCoupon(data) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/promo', data)
  }
  changeCouponStatus(data) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/promo/status', data)
  }
  getDataList(url) {
    return this.http.get(this.baseUrl + url);

  }
  getData({ page, limit, bookType }) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/earning?page=' + page + '&limit=' + limit + '&bookType=' + bookType);
  }
  getrevenueData(data) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/booking/manageRevenueDriver?page=' + data.page + '&startdate=' + data.startdate + '&enddate=' + data.enddate + '&sendall=' + data.sendall + '&isCod=' + data.isCod);
  }
  getdriverdata(data1) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/booking/manageRevenue?driverId=' + data1.id + '&startdate=' + data1.startdate + '&enddate=' + data1.enddate);
  }
  getpaymentData(data) {
    // return this.http.get(this.baseUrl+'v1/admin/customer/earning/driver?page='+page);
    let url = this.baseUrl + 'v1/admin/customer/earning/driver?page=' + data.page;
    if (data.field) {
      url = url + '&field=' + data.field;
    }
    if (data.keyword) {
      url = url + '&keyword=' + data.keyword;
    }
    if (data.status) {
      url = url + '&status=' + data.status;
    }
    if (data.isExport == 0) {
      url = url + '&isExport=' + data.isExport;
    }
    if (data.bookType) {
      url = url + '&bookType=' + data.bookType;
    }
    return this.http.get(url);
  }
  getpaymentDat(data) {

    let url = this.baseUrl + 'v1/admin/customer/earning/driver?page=' + data.page;
    if (data.field) {
      url = url + '&field=' + data.field;
    }
    if (data.keyword) {
      url = url + '&keyword=' + data.keyword;
    }
    return this.http.get(url);
  }
  getHomeData() {
    return this.http.get(this.baseUrl + 'v1/admin/customer/earning');
  }
  changereview(data) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/booking/userReview', data);
  }
  changedriverreview(data) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/booking/driverReview'
      , data);
  }
  getSelectedDateDataFromDb(type, date) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/dashboard?type=' + type + '&date=' + date, {});
  }
  getdata(type, adminId) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/dashboard?type=' + type + '&adminId=' + adminId, {});
  }
  getusergraph(field, type) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/dashboard/report?field=' + field + '&type=' + type, {});
  }
  getbookinggraph(field, type1) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/dashboard/report?field=' + field + '&type=' + type1, {});
  }

  //for check specific extentions.
  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }
  createsubadmin(body) {
    console.log(body)
    return this.http.post(this.baseUrl + 'v1/admin/customer/register', body)
  }

  getsubadmin(page, limit) {

    return this.http.get(this.baseUrl + 'v1/admin/customer/subadmins?page=' + page + '&limit=' + limit)
  }
  permission(subadminid) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/permission/' + subadminid)
  }
  getsubadminId(subadminid) {
    return this.http.get(this.baseUrl + 'v1/admin/customer/subAdmin/' + subadminid)
  }
  updatesubadmin(data) {
    return this.http.put(this.baseUrl + 'v1/admin/customer/subAdmin', data)
  }

  deleteadmin(id: String) {
    return this.http.delete(this.baseUrl + 'v1/admin/customer/subAdmin/' + id)
  }
  deletegeofencedriver(driverId, geoId) {
    return this.http.delete(this.baseUrl + 'v1/geoFencing/getDriver?geoId=' + geoId + '&driverId=' + driverId
    )
  }
  deleteDrivers(body) {
    return this.http.post(this.baseUrl + 'v1/admin/user/delete/', { "id": body })
  }

  addBooking(body) {
    return this.http.post(this.baseUrl + 'v1/store/booking/add', body)
  }
  getAllType(data) {
    console.log('type')
    let url = this.baseUrl + 'v1/admin/customer/driver/vehicleTypes/' + data.adminId;
    if (data.type) {
      url = url + '?type=' + data.type;
    }
    return this.http.get(url);

  }
  getDrivers(body) {
    console.log('dfaddg')
    return this.http.post(this.baseUrl + 'v1/admin/customer/booking/getDeliveryDrivers/', body)

  }
  getStoreProducts(body) {
    return this.http.post(this.baseUrl + `v1/store/products/`, body)
  }
  getMerchants() {
    return this.http.post(this.baseUrl + `v1/store/stores`, '')
  }
  getMerchantdashboard(storeId) {
    return this.http.post(this.baseUrl + 'v1/store/dashboard', { storeId: storeId })
  }

  getFaq(adminId) {
    return this.http.get(this.baseUrl + `v1/customer/faq/` + adminId)
  }
  getQueries(page, limit) {
    return this.http.get(this.baseUrl + `v1/admin/customer/booking/contactus?page=` + page + `&limit=` + limit)
  }

  getFaqById(faqId) {
    return this.http.get(this.baseUrl + `v1/customer/getfaq/` + faqId)
  }
  addFaq(form) {
    return this.http.post(this.baseUrl + `v1/customer/faq`, form)
  }
  editFaq(form) {
    return this.http.put(this.baseUrl + `v1/customer/faq`, form)
  }
  getStaticPages(form) {
    return this.http.post(this.baseUrl + `v1/customer/getpage`, form)
  }

  saveStaticPages(form) {
    return this.http.post(this.baseUrl + `v1/customer/page`, form)
  }


  refundResponse(form): Observable<any> {
    return this.http.post(this.baseUrl + `v1/user/refundBooking`, form)
  }



}
