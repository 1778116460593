import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { ChatService } from '../../../services/chat.service'

@Component({
  selector: 'app-manage-delivery-admin',
  templateUrl: './manage-delivery-admin.component.html',
  styleUrls: ['./manage-delivery-admin.component.scss'],
  providers: [ChatService]

})
export class ManageDeliveryAdminComponent implements OnInit {

  adminList: any;
  page = 1;
  adminId: string
  adminType: number
  skip: number = 0
  limit: number = 10
  search: string = ''
  field: any = '';
  keyword: any = '';
  status: any = '';
  usersession: any;
  onload: boolean = false
  currentPage: Number;
  admin: any
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  permissions: any;
  constructor(private route: ActivatedRoute,
     private router: Router,
     private chatService: ChatService,

      private service: ApiService,
       public snackBar: MatSnackBar, ) { }

  ngOnInit(): void {
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession  = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if(JSON.parse(localStorage.getItem('permissions'))) 
    this.permissions = JSON.parse(localStorage.getItem('permissions')).deliveryadmin
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
   this.currentPage=1
    this.getDeliveryAdminListFromDb(this.currentPage);
  }

  goToAddUser() {
    this.router.navigate(['admin/add-delivery-admin']);
  }


  getDeliveryAdminListFromDb(current) {
this.currentPage=current
    // console.log(this.field, this.keyword, this.status);

    this.service.getDeliveryAdminList(this.field, this.keyword, this.status,this.currentPage).subscribe((res) => {

      // console.log(res);

      this.admin = res;
      this.adminList = this.admin.admin
        // console.log(res['admin']);
       
        this.onload = true
        this.config = {
          id: "page",
          currentPage: this.currentPage,
          itemsPerPage: 10,
          totalItems: this.admin.total
  
  
        }
    });

  }
  pageChange(newPage: number){
    console.log("newPage",newPage)
    this.getDeliveryAdminListFromDb(newPage)
    }

  

  resetFilter() {
    this.field = '';
    this.keyword = '';
    this.status = '';
    this.getDeliveryAdminListFromDb(this.currentPage);
  }


  editUser(user) {
    // console.log("user----", user);
    var id = user._id;
    this.router.navigate(['admin/add-delivery-admin', { id }]);
  }


  changeToggle(list: any, status) {
    if(status==1){

      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
  
          // console.log(list._id, status);
          let form = {
            adminId: list._id,
            status: status ? 0 : 1,
            remark:result.value
          }
  
          this.chatService.deliveryStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
  
                  // console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()
                  }
  
  
                
                }
            
              }




            )
        }
        else{
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }
      });

    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to change status",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value == true) {
  
          // console.log(list._id, status);
          let form = {
            adminId: list._id,
            status: status ? 0 : 1,
            remark:''
          }
  
          this.chatService.deliveryStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
  
                  // console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()
                  }
  
  
                } 
              }
            )
        }
        
        else  {

          console.log("no",status )
 
    this.ngOnInit()
    console.log("cancel for not active user")

      //if cancled 

  }
      });
    }

   
  }
}