import { Component, OnInit, DebugElement } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-edit-sub-admin',
  templateUrl: './edit-sub-admin.component.html',
  styleUrls: ['./edit-sub-admin.component.scss']
})
export class EditSubAdminComponent implements OnInit {
  public user:any={};
  public users:any={};
  public driver:any={};
  public merchant:any={};
  public driveradmin:any={};
  public deliveryadmin:any={};
  public geofencing:any={};
  public booking:any={};
  public category:any={};
  public cms:any={};
  public payment:any={};
  public discount:any={};
  public analytics:any={};
  public notification:any={};
  public tracking:any={};
  public revenue:any={};
  public performance=<any>{};
  public invoice=<any>{};
  public cod=<any>{};
  public pendingBooking=<any>{};
  adminId: any;
  userId: any;
  editsubAdmin: string;
  formValid: boolean;
  submitting: boolean;
  toastr: any;
  response: any;
  FormData: any;
  ManageuserPermision: any = [];
  MerchantPermision: any = [];
  DriverPermision: any = [];
  form: any;

  constructor(private service: ApiService, private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }
  ngOnInit() {
    this.getsubadminId();
  }
  goTosubadmin() {
    this.router.navigate(['/admin/subadmin'])
  }
  Update() {
    this.submitting = true
    let data:any = {};
    data.firstName = this.user.firstName
    data.lastName = this.user.lastName
    data.phone = this.user.phone
    data.email = this.user.email
    data.countryCode = this.user.countryCode
    data.users = this.users
    data.driver = this.driver
    data.merchant = this.merchant
    data.driveradmin = this.driveradmin
    data.deliveryadmin = this.deliveryadmin
    data.geofencing = this.geofencing
    data.booking = this.booking
    data.category = this.category
    data.payment = this.payment
    data.discount = this.discount
    data.analytics = this.analytics
    data.notification = this.notification
    data.tracking = this.tracking
    data.revenue=this.revenue
    data.cms = this.cms
    data.cod =this.cod
    data.invoice = this.invoice,
    data.pendingBooking = this.pendingBooking,
    data.performance = this.performance
    data.id = this.route.params['value'].id

    let form = data; 
    this.service.updatesubadmin(form).subscribe(res => {
      this.response = res
      if (res['success'] == true) {
        // this.submitting = false
        // console.log('data', this.form)
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })
        this.router.navigate(['/admin/subadmin']);

      } else {
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })
      }

    });

  }
  async getsubadminId(){
     this.service.getsubadminId(this.route.params['value'].id).subscribe((response:any) => {
    
      if(response['success']==true){
        // console.log("user", response);
        this.users = response['subAdmin']['users'] ?response['subAdmin']['users'] : {}
        this.driver = response['subAdmin']['driver']? response['subAdmin']['driver'] : {}
        this.merchant = response['subAdmin']['merchant']? response['subAdmin']['merchant'] : {}
        this.driveradmin = response['subAdmin']['driveradmin']?response['subAdmin']['driveradmin'] :{}
        this.deliveryadmin = response['subAdmin']['deliveryadmin']?response['subAdmin']['deliveryadmin'] :{}
        this.geofencing = response['subAdmin']['geofencing']? response['subAdmin']['geofencing']: {}
        this.booking = response['subAdmin']['booking'] ?response['subAdmin']['booking'] :{}
        this.category = response['subAdmin']['category'] ? response['subAdmin']['category']:{}
        this.payment = response['subAdmin']['payment'] ? response['subAdmin']['payment'] :{}
        this.discount = response['subAdmin']['discount'] ?response['subAdmin']['discount'] :{}
        this.analytics = response['subAdmin']['analytics'] ? response['subAdmin']['analytics'] :{}
        this.notification = response['subAdmin']['notification'] ? response['subAdmin']['notification'] :{} 
        this.tracking = response['subAdmin']['tracking'] ?response['subAdmin']['tracking']:{}
        this.cms = response['subAdmin']['cms'] ?response['subAdmin']['cms']:{}
        this.revenue = response['subAdmin']['revenue'] ?response['subAdmin']['revenue']:{}
        // this.users.view = true
        this.cod = response['subAdmin']['cod'] ?response['subAdmin']['cod']:{}
        this.performance = response['subAdmin']['performance'] ?response['subAdmin']['performance']:{}
        this.pendingBooking = response['subAdmin']['pendingBooking'] ?response['subAdmin']['pendingBooking']:{}
        this.invoice = response['subAdmin']['invoice'] ?response['subAdmin']['invoice']:{}

  this.user.firstName= response['subAdmin'].adminId.firstName    
  this.user.lastName= response['subAdmin'].adminId.lastName    
  this.user.email= response['subAdmin'].adminId.email    
  this.user.firstName= response['subAdmin'].adminId.firstName  
  this.user.phone=response['subAdmin'].adminId.phone  
  this.user.countryCode=response['subAdmin'].adminId.countryCode  
// console.log('data......',this.user.firstName) 
 }
  
    });
  }

  validateForm(form: NgForm) {
    // console.log("forma",formV);
    // console.log("ff",Object.keys(formV));
    // Object.keys(form.value).forEach((field)=>{
    //   console.log("field",field);

    //   if(form.value[field]==undefined || '')  {
    //     this.toastw(`${field} is required`)
    //   }
    // })
    if (form.value.firstName == undefined) {
      return this.toastw(" name is required.")
    }
    if (form.value.email == undefined) {
      return this.toastw("email is required.")
    }


    if (form.value.countrycode == undefined) {
      return this.toastw("countrycode is required.")
    }
    if (form.value.phone == undefined) {
      return this.toastw("phone is required.")
    }
    if (form.value.password == undefined) {
      return this.toastw("password is required.")
    }

    this.formValid = true
  }
  toastw(mesg) {
    this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  }

  Country(event){
    if(event.target.value.length==0){
      console.log("jhgjhg",event.target.value.length)
      event.target.value= "+"
    }
    if(event.target.value.charAt(0)!=="+"){
      event.target.value="+"+event.target.value
      console.log("eveney Down",event.target.value.charAt(0))
    }
  }

}
