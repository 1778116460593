import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-driver-location',
  templateUrl: './driver-location.component.html',
  styleUrls: ['./driver-location.component.scss']
})
export class DriverLocationComponent implements OnInit {
  drivers: any = [];
  driverCount: any;
  adminId: any;
  avialable: any = [];
  field: any = 'All';
  keyword: any = '';
  status: any = '';
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  onload: boolean = false

  busy: any = [];
  offline: any = [];
  currentPage: number;
  isAvailable: any = ""
  offlineDriver: any;
  busyDriver: any;
  onlineDriver: any;
  constructor(private router: Router,
    private service: ApiService, ) { }
  public lat: Number = 25.276987
  public lng: Number = 55.296249
  ngOnInit() {
    this.adminId = JSON.parse(localStorage.getItem('curruntUser'))._id;
    this.currentPage = 1
    this.getdata(this.currentPage)
  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getdata(newPage)
  }
  getdata(page) {
    let limit: 0
    this.busy = []
    this.offline = []
    this.avialable = []
    console.log(this.isAvailable, "isAvailable")
    this.service.geAlltDriversList(this.field, this.keyword, this.status, this.currentPage, limit = 0, this.isAvailable).subscribe((res) => {
      console.log("data.drivers", res);
      if (res['success'] == true)
        this.drivers = res['driver']
      this.onlineDriver = res['onlineDriver']
      this.offlineDriver = res['offlineDriver'],
        this.busyDriver = res['busyDriver'],

        this.onload = true
      this.config = {
        id: "page",
        currentPage: page,
        itemsPerPage: 10,
        totalItems: res['total']
      }
      res['driver'].forEach(element => {
        if (element.isAvailable == '1' && element.currentBooking == '0') {
          // element.push(this.avialable)
          this.avialable.push(element)
          //  console.log("data11.......",this.avialable)

        }
        if (element.isAvailable == 0 && element.currentBooking == 0) {
          this.offline.push(element)
          //   console.log("data.22......",this.avialable)
        }
        if (element.currentBooking > 0) {
          this.busy.push(element)
          //    console.log("data.33......",this.avialable)
        }
      })
      // data.push({})

      console.log("driversdrivers", this.drivers);
      console.log("ONLINE", this.avialable);
      console.log("OFFLINE", this.offline);
      console.log("BUSY", this.busy);

      // this.driverCount = res.driver.length


      // this.cordinates=this.drivers[]


      // this.cordinates=this.drivers.driverId.coordinates
      // console.log("cordinates",this.cordinates)
    })
  }
  goToback() {
    this.router.navigate(['/admin/manage-drivers']);

  }
  onMouseOver(infoWindow, gm) {

    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }
}
