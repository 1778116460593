import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ConstantPool } from '@angular/compiler';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  form: FormGroup;
  form1: FormGroup
  form2: FormGroup
  inviteDriver: FormGroup;
  inviteUser: FormGroup;
  adminId: string
  userChecked: boolean = false
  driverChecked: boolean = false
  driverSearchRadiousStartsFrom: number
  driverSearchRadiousEndTo: number;
  commissionPaymentPercent: number;
  taxPercent: number;
  refundPercent: number;
  private formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private fg: FormBuilder,
    private service: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.adminId = JSON.parse(localStorage.getItem('curruntUser'))._id
    this.getDriverSearchRadious()
    this.getCommissionPercentage()
    this.form = this.fb.group({
      rewards: ['', Validators.required],
      currency: ['', Validators.required],
      Charges: ['', Validators.required],
    });
    this.form1 = this.fg.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],

    });
    this.form2 = this.fg.group({
      refercommisn: ['', Validators.required],

    });



    this.service.getRewardSetting(this.adminId)
      .subscribe(
        (data: any) => {
          console.log(data)
          if (data.reward) {
            this.form = this.fb.group({
              rewards: data.reward.rewardsPercentage,
              currency: data.reward.pointToCurrency,
              Charges: data.reward.Charges
            });
          }
        })
    this.service.getContactus(this.adminId)
      .subscribe(
        (data1: any) => {
          // console.log('dta',data1)
          if (data1.success) {
            this.form1 = this.fg.group({
              email: data1.contact.email,
              phone: data1.contact.phone,

            });
          }
        })


    this.service.referal()
      .subscribe(
        (data2: any) => {
          if (data2.success) {
            this.form2 = this.fg.group({
              refercommisn: data2.data[0].refercommisn,
            });
          }
        })

    // this.service.getRefralSetting(this.adminId)
    //   .subscribe(
    //     (data: any) => {

    //       if (data.refrals.length > 0) {

    //         data.refrals.map(val => {
    //           if (val.refralType) {
    //             this.driverChecked = val.status
    //             this.inviteDriver = this.fb.group({
    //               driversenderShare: val.senderShare,
    //               driverrecieverShare: val.recieverShare
    //               // cancelcharges: val.Charges
    //             });
    //           } else {
    //             this.userChecked = val.status
    //             this.inviteUser = this.fb.group({
    //               senderShare: val.senderShare,
    //               recieverShare: val.recieverShare,
    //               // Charges: val.Charges

    //             });
    //           }
    //         })
    //       }
    //     })

    this.form = this.fb.group({
      rewards: ['', Validators.required],
      currency: ['', Validators.required],
      Charges: ['', Validators.required],
    });
    this.form1 = this.fg.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],

    });




    this.inviteDriver = this.fb.group({
      driversenderShare: ['', Validators.required],
      driverrecieverShare: ['', Validators.required]
      // Charges:['',Validators.required]

    });
    this.inviteUser = this.fb.group({
      senderShare: ['', Validators.required],
      recieverShare: ['', Validators.required]
      // Charges:['',Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  getCommissionPercentage() {

    // Method to get commission percentage
    this.service.getCommisionPercentage().subscribe(res => {
      console.log(res)
      this.commissionPaymentPercent = res.data.commission;
      this.taxPercent = res.data.tax
      this.refundPercent = res.data.refundPercent
    })
  }

  updateCommissionPercentage() {

    // method to update commission percentage
    console.log(this.commissionPaymentPercent)
    let body = {
      'commission': this.commissionPaymentPercent,
      'tax': this.taxPercent,
      'refundPercent': this.refundPercent
    }
    this.service.updateCommisionPercentage(body).subscribe(res => {
      console.log(res);
      this.snackBar.open(res.message, 'x', {
        duration: 5000,
      })
    })
  }
  getDriverSearchRadious() {

    // method to get radious for driver search
    this.service.getDriverSearchRadious().subscribe(res => {
      console.log(res);
      let data = res['data']
      if (data.minimum != undefined && data.maximum != 0) {

        this.driverSearchRadiousStartsFrom = data.minimum != 0 ? data.minimum / 1609 : 0;
        this.driverSearchRadiousEndTo = data.maximum / 1609;
      }
    })
  }

  updateDriverSearchRadious() {

    console.log(this.driverSearchRadiousEndTo)
    if (this.driverSearchRadiousStartsFrom >= 0 && this.driverSearchRadiousEndTo > this.driverSearchRadiousStartsFrom) {
      let startsFrom = this.driverSearchRadiousStartsFrom
      let endsTo = this.driverSearchRadiousEndTo
      startsFrom != 0 ? startsFrom = startsFrom * 1609
        : 0
      endsTo = endsTo * 1609

      let body = {

        "minimum": startsFrom,
        "maximum": endsTo

      }
      console.log(body)
      this.service.updateDriverSearchRadious(body).subscribe((res) => {
        console.log(res);
        this.snackBar.open(res.message, 'x', {
          duration: 5000,
        })
      })
    }



  }

  onSubmit() {

    console.log('data', this.form.value)
    if (this.form.valid) {
      this.form.value.adminId = this.adminId
      this.service.addRewardSetting(this.form.value)
        .subscribe(
          (data: any) => {
            console.log(data)
            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })
          }
        )
    }
    this.formSubmitAttempt = true;
  }
  onSubmit1() {
    console.log('data', this.form1.value)
    if (this.form1.valid) {

      this.form1.value.adminId = this.adminId
      this.form1.value.countryCode = "+91"
      // this.form1.value.email = this.email
      // this.form1.value.phone = this.phone


      console.log('data', this.form1)
    }
    this.service.contactus(this.form1.value)
      .subscribe(
        (data: any) => {
          console.log('data', this.form)
          if (data.success)

            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })

        }
      )
  }

  
  onSubmit2() {
    if (this.form2.valid) {
    this.service.refer(this.form2.value)
      .subscribe(
        (data: any) => {
          if (data.success)
            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })
        }
      )
    }
  }

  onUserRefralSubmit() {

    this.inviteUser.value.type = 0
    this.inviteUser.value.status = this.userChecked ? 1 : 0

    if (this.inviteUser.valid) {
      this.inviteUser.value.adminId = this.adminId
      this.service.addRefralSetting(this.inviteUser.value)
        .subscribe(
          (data: any) => {
            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })
          }
        )
    }

  }

  userToggleChange() {
    this.userChecked = !this.userChecked
  }

  onDriverRefralSubmit() {

    this.inviteDriver.value.type = 1
    this.inviteDriver.value.status = this.driverChecked ? 1 : 0
    if (this.inviteDriver.valid) {
      this.inviteDriver.value.adminId = this.adminId
      this.inviteDriver.value.senderShare = this.inviteDriver.value.driversenderShare
      this.inviteDriver.value.recieverShare = this.inviteDriver.value.driverrecieverShare
      // this.inviteDriver.value.Charges = this.inviteDriver.value.Charges

      this.service.addRefralSetting(this.inviteDriver.value)
        .subscribe(
          (data: any) => {
            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })
          }
        )
    }

  }

  driverToggleChange() {
    this.driverChecked = !this.driverChecked
  }

}
