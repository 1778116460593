import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { log } from 'util';

@Component({
  selector: 'app-add-drivers-admin',
  templateUrl: './add-drivers-admin.component.html',
  styleUrls: ['./add-drivers-admin.component.scss']
})
export class AddDriversAdminComponent implements OnInit {

  user: any = {}
  response: any;
  userId: any;
  firstDoc: any;
  secondDoc: any;
  thirdDoc: any;
  adminId: any;
  formValid: boolean = false
  profilePic: any;
  public allowDoc = ['jpg', 'jpeg', 'png', 'pdf'];
  public allowDocError: string;
  userInfo: any;
  editDriverAdmin: string = '';
  constructor(private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private service: ApiService, public snackBar: MatSnackBar) { }
  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.user.countryCode = '+';

    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      // console.log(this.userId);
      if (this.userId != '' && this.userId != undefined && this.userId != null) {
        this.getDriverAdminInfo();
        this.editDriverAdmin = 'Edit'
        // console.log("params----"+params['id']);
      }


    });



  }
  validateForm(form: NgForm) {
    let i = 0;
    let FormKeys = Object.keys(form.value);
    for (i = 0; i < FormKeys.length; i++) {
      if (form.value[FormKeys[i]] == "" || form.value[FormKeys[i]] == undefined) {
        this.toastw(`${FormKeys[i]} is required`)
        break;
      }
    }
    this.formValid = true
  }
  toastw(mesg) {
    this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  }



  onSubmit(form) {

    // if(form.valid && this.firstDoc &&  this.secondDoc &&  this.thirdDoc){
    if (this.editDriverAdmin != '') {
      console.log("id", this.userId);
      var data = {
        "adminId": this.userId,
        "firstName": this.user.firstName,
        "lastName": this.user.lastName,
        "email": this.user.email,
        "password": this.user.password,
        "phone": this.user.phone,
        "countryCode": this.user.countryCode,
        "companyName": this.user.company
      }
      console.log("data", data)
      var fd = new FormData();
      fd.append("body", JSON.stringify(data));
      this.service.editDriverAdminInfo(data).subscribe((res) => {

        // console.log(JSON.stringify(res));

        if (res['success'] == true) {
          // console.log(res);
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })

          this.router.navigate(['/admin/manage-driver-admin']);
        } else {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }

      });

    } else {

      if (form.valid && this.firstDoc && this.secondDoc && this.thirdDoc) {

        var userData = {
          "firstName": this.user.firstName,
          "lastName": this.user.lastName,
          "email": this.user.email,
          "password": this.user.password,
          "phone": this.user.phone,
          "countryCode": this.user.countryCode,
          "companyName": this.user.company
        }

        console.log(userData);
        this.service.registerDriverAdmin(this.firstDoc, this.secondDoc, this.thirdDoc, userData).subscribe((res) => {

          // console.log(JSON.stringify(res));

          if (res['success'] == true) {
            // console.log(res);
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.user = '';
            this.router.navigate(['/admin/manage-driver-admin']);
          } else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
          }

        });
      }
    }

    // } else {
    //   Swal.fire({
    //     type: 'error',
    //     title: 'Oops...',
    //     confirmButtonColor:'#821727',
    //     allowOutsideClick:false,
    //     text: 'Please fill all details',
    //   })
    // }




  }


  documentFirstUpload(event) {

    // console.log("this.profilePic", this.firstDoc);
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.firstDoc = event.target.files[0];
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg,pdf and png',
      })
      // console.log("this.profilePic", this.fourthDoc);
    }
  }
  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }
  documentSecondUpload(event) {

    // console.log("this.profilePic", this.secondDoc);
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.secondDoc = event.target.files[0];
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg,pdf and png',
      })
      // console.log("this.profilePic", this.fourthDoc);
    }
  }

  documentThirdUpload(event) {

    // console.log("this.profilePic", this.thirdDoc);
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.thirdDoc = event.target.files[0];
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg ,pdf and png',
      })

    }
  }


  resetFormValue() {

    this.user.countryCode = '+';


    this.getDriverAdminInfo();

  }

  getDriverAdminInfo() {
    // console.log("userId----", this.userId);/
    this.service.getDriverAdminInfoFromDb(this.userId).subscribe((res) => {

      if (res['success'] == true) {

        // console.log("userInfo", res);
        this.user = res['admin'];

      }

    });
  }


  goToManageUser() {
    this.router.navigate(['/admin/manage-driver-admin']);
  }

  changeValue(vm) {
    // this.user.countryCode= '+'+vm.target.value
    if (this.user.countryCode != null && this.user.countryCode.includes('+')) {
      this.user.countryCode = vm.target.value;
    } else {
      this.user.countryCode = '+' + vm.target.value;

    }
    // console.log('changeValue', this.user.countryCode);
  }

}
