import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  
  privacyText:any;
  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    let data ={
      pageName: "Users Privacy"
    }
    this.service.getStaticPages(data).subscribe((data: any) => {
      if (data.content) {
        this.privacyText =data.content
      }
    });
  }
}
