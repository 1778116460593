import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
declare let $: any

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {
  activeTab: any;
  permissions: any = {};
  clickcls: boolean = false

  usersession: any;
  @Input('activeTabIndex') activeTabIndex;
  constructor(private service: ApiService, private route: ActivatedRoute, private router: Router) {
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
  }

  ngOnInit() {
    this.activeTab = this.activeTabIndex
    this.getPermission();

    $('.cms_pages').click(function () {
      var abc = document.getElementsByClassName('sidebar-is-reduced');
      $(abc).removeClass('sidebar-collapse');
    })
  }

  getPermission() {
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    if (!permissions && this.route.snapshot.url[1].path == "admin-dashboard") {
      this.service.permission(this.usersession._id).subscribe((resp: any) => {
        localStorage.setItem("permissions", JSON.stringify(resp.permissions));
        this.permissions = resp.permissions;
      })
    } else {
      this.permissions = permissions
    }

  }
  OnclsClick(click) {
    this.clickcls = click
  }

  checkDefined(tab) {
    try {
      if (this.usersession.adminType == 1)
        return true
      return (this.usersession.adminType != 1 && this.permissions[tab] || this.permissions[tab].view) ? true : (this.usersession.adminType == 1) ? true : false;
    } catch (err) {
      return (this.usersession.adminType == 1) ? true : false
    }
  }

  logout(val) {
    if (val == '14') {
      this.activeTab = val;
      localStorage.clear();
      this.router.navigate(['']);
    }
  }
  booking(val) {
    if (val == '17') {
      this.activeTab = val;
      this.router.navigate(['/admin/scheduled']);
    }

  }
  goTopendingbooking(val) {
    if (val == '20') {
      this.activeTab = val;
      this.router.navigate(['/admin/pending-booking']);
    }

  }
  goToSubAdmin(val) {
    if (val == '15') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/subadmin']);
    }

  }

  goToAdmindashboard(val) {
    console.log('val1', val);
    if (val == '1') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/admin-dashboard']);
    }

  }
  goToManageUser(val) {
    console.log('val2', val);

    if (val == '2') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-user']);
    }

  }

  goToManagereview(val) {
    console.log('val16', val);

    if (val == '16') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-review']);
    }
  }
  goToManageMerchants(val) {
    if (val == '6') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-merchants']);
    }

  }
  goToManageDrivers(val) {
    if (val == '3') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-drivers']);
    }
  }
  goTobooking(val) {
    if (val == '8') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/admin-booking']);
    }

  }
  goToManageCarCategory(val) {
    if (val == '9') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-car-category']);
    }

  }
  goToPayment(val) {
    if (val == '10') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/payment']);
    }

  }
  goTodriverPayment(val) {
    if (val == '18') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/driverpayment']);
    }
  }
  goToCod(val) {
    if (val == '22') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/cod']);
    }
  }
  Revenue(val) {
    if (val == '19') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/revenue']);
    }
  }
  goToGeofencing(val) {
    if (val == '7') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/geofencing']);
    }

  }
  goToReview() {

    this.router.navigate(['/admin/review']);
  }

  goToNotification(val) {
    if (val == '13') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/notification']);
    }

  }
  goToAnalytics(val) {
    if (val == '12') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/analytics']);
    }

  }

  goToManageDriversAdmin(val) {
    if (val == '4') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/manage-driver-admin']);
    }

  }

  goToManageDeliveryAdmin(val) {
    if (val == '5') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['admin/manage-delivery-admin']);
    }

  }

  goToDiscount(val) {
    if (val == '11') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/discount-coupon']);
    }
  }
  goToTracking(val) {
    if (val == '14') {
      // this.activeTab=true;
      this.activeTab = val;
      this.router.navigate(['/admin/driverLocation']);
    }
  }
  goToperformance(val) {
    if (val == '23') {
      this.activeTab = val;
      this.router.navigate(['admin/performance']);
    }
  }
  goToInvoice(val) {
    if (val == '24') {
      this.activeTab = val;
      this.router.navigate(['admin/invoice']);
    }
  }
  goTouserterms() {
    this.router.navigate(['/admin/user-terms']);
  }
  goTouserprivacy() {
    this.router.navigate(['/admin/user-policy']);
  }
  goToterms() {
    this.router.navigate(['/admin/driver-terms']);
  }
  goToprivacy() {
    this.router.navigate(['/admin/driver-policy']);
  }
  goToAbout() {
    this.router.navigate(['/admin/about-us']);
  }

  goToGDPR() {
    this.router.navigate(['/admin/gdpr']);
  }


  goToFaq() {
    this.router.navigate(['/admin/faq']);
  }

  goToQueries() {
    this.router.navigate(['/admin/queries']);
  }
}



