import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'util';
import { NgModel } from '@angular/forms';
// import undefined = require('firebase/empty-import');
import { MatOption } from '@angular/material';
import { ChatService } from '../../../services/chat.service'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit {
  // @ViewChild('allSelected') private allSelected: MatOption;
  user: any = {}


  notificationList: any[];
  selectedUser: any[];
  notifications: number
  driversSelected = [];
  selectedItems: any[];
  showbtn: boolean = false
  dropdownSettings: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  msg: any;
  field: any;
  adminId: any;
  permissions: any;
  usersession: any;
  notification2: any
  selectedOption: any;
  constructor(private chatService: ChatService, private service: ApiService, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {

    this.user.notifications = 'test'


    // console.log("currentUrl====", this.router.url);
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));

    if (JSON.parse(localStorage.getItem('permissions'))) {
      this.permissions = JSON.parse(localStorage.getItem('permissions')).notification
    }



  }

  onSubmit() {
    this.adminId = JSON.parse(localStorage.getItem('curruntUser'))._id;
    let selectedUser = []
    this.notificationList.forEach(p => {
      selectedUser.push(p._id)
      console.log("selectedUser", this.selectedUser);

    });
    // this.form.value.products = products
    // console.log(this.user);
    // console.log("selectedUser",this.user,this.adminId);

    let body = {

      "sender": this.selectedUser,
      "message": this.user.msg,
      "field": parseInt(this.user.notifications),
      "adminId": this.adminId
    }
    // console.log('abc',body);
    if (this.user.notifications == 2 || this.user.notifications == 5) {
      this.service.sendNotificationList(body).subscribe((res) => {
        // this.selectedUser = res['adminId'];
        if (res['success'] == true) {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })

        } else {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }
      });
    }
    else {
      this.chatService.sendnotification(body)
        .subscribe((message: any) => {
          if (message['success'] == true) {
            this.snackBar.open(message['message'], 'x', {
              duration: 5000,
            })
          }
          console.log("sendnotification", message)
        })
    }
  }




  selectAll(checkAll, select: NgModel, values: any) {
    this.showbtn = true

    console.log("onSelectionChange", select, "values", values);
    let storeIds = []
    values.forEach((store: any) => {
      console.log("store._id");
      storeIds.push(store._id)
    })
    this.selectedUser = storeIds
    console.log("this.selectedUser", this.selectedUser);
    if (checkAll) {
      this.selectedUser = values[0]._id
      select.update.emit(this.selectedUser);
      // select.update.emit(values);


      console.log("gjhghj", values)
    }
    else {
      // select.update.emit([]);

    }
  }


  deselectAll(select: NgModel) {
    this.showbtn = false

    select.update.emit([]);
  }
  // adduser()
  // {
  //   var selectedUser = {
  //     "adminId": this.selectedUser,

  //   }

  //   this.service.sendNotificationList(selectedUser).subscribe((res) => {

  //     if (res['success'] == true) {

  //       // console.log("resOtherDrivers",res);
  //       this.snackBar.open(res['message'], 'x', {
  //         duration: 5000,
  //       })

  //     } else {
  //       this.snackBar.open(res['message'], 'x', {
  //         duration: 5000,
  //       })
  //     }



  //   });
  // }

  // setParams(ObjectArry) {
  //   ObjectArry.forEach(d => {
  //     this.driversSelected.push({ _id: d._id, firstName: d.firstName })
  //   });
  // }
  getList(val) {


    // console.log(val);

    if (val === "test") {
      this.snackBar.open("Please select an option", 'x', {
        duration: 5000,
      })

    } else {

      this.selectedOption = val

      this.service.getNotificationList(val).subscribe((res) => {
        this.notificationList = res['notification'];
        // console.log('res', res);
        if (res['success'] == true) {

          this.notificationList = res['list'];

          console.log("this.notificationList", this.notificationList);

          // this.getnotificationList({ driverId: this.driverId });

        }

      });

    }
  }
  //   
}