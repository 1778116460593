import { Component, OnInit, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { MouseEvent as AGMMouseEvent } from '@agm/core';
import { MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { log } from 'util';
import Swal from 'sweetalert2';

declare var google: any;

@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss']
})
export class GeofencingComponent implements OnInit {
  address: string;
  driverName: any;
  private geoCoder;
  selectedZoom: number;
  latitude: number;
  longitude: number;
  zoom: number = 11;
  searchString: any;
  polyarray: any = [];
  geoId: any
  driverId: any
  drawingManager: any;
  addgeofence: boolean = true;
  fencingList: any = [];
  constructTriangle: any;
  @ViewChild("AgmMap") Map: any;
  adminId: any;
  vacantDriversList: any = [];
  selected: any[];
  otherDriversList: any = [];
  vacantDriverView: boolean = false;
  fancingDriverName: any;
  vacantDrivers: any = [];
  fencingDrivers: any;
  geoFenceId: any;
  fencingDriversList: any;
  geoFenceList: any;
  formGroup: any;
  list: any;
  multiple: any;
  usersession: any;
  permissions: any;
  keyword: string = '';


  constructor(private service: ApiService, private mapsAPILoader: MapsAPILoader,
    public snackBar: MatSnackBar, private ngZone: NgZone, private router: Router,) {
    this.setCurrentLocation();
  }
  ngAfterViewInit() { }
  ngOnInit() {

    // this.multiple.vacantDrivers.setValue(this.selected);

    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));

    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));


    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).geofencing

    this.adminId = curruntUser._id;


    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
    });
    this.view()
  }
  setCurrentLocation() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log("name", position);
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 11;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  deletegeofence(v) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Delete this Geo-fence ? ",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {

      if (result.value == true) {
        console.log("data", v);
        this.service.deletegeofence(v).subscribe(res => {
          console.log(res, "---------------------");
          if (res['success'] == true) {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.getFencing();

          }

        })
      }
    })
  }

  markerDragEnd(ev) {
    // console.log(ev);
  }
  Reset() {
    this.searchString = '';
  }
  resetFilter() {
    this.keyword = '';
    this.getFencing()
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

    });
  }
  onMapReady(map) {
    this.Map = map;
    this.initDrawingManager(this.Map);
  }
  onMapReady1(map) {
    this.Map = map;

  }

  initDrawingManager(map: any) {

    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };


    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);


    google.maps.event.addListener(this.drawingManager, 'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) { //this is the coordinate, you can assign it to a variable or pass into another function. 

          this.polyarray = event.overlay.getPath().getArray();
        }
      });

  }
  save() {
    if (this.searchString) {


      this.addgeofence = false;
      var geofenceData = {
        "adminId": this.adminId,
        "name": this.searchString,
        "locationPoints": this.polyarray
      }

      this.service.creategeoFencing(geofenceData).subscribe((res) => {

        if (res['success'] == true) {
          this.addgeofence = false;
          this.getFencing();
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }
      });
    } else {
      this.snackBar.open('Please select region name', 'x', {
        duration: 5000,
      })
    }

  }
  add() {
    this.addgeofence = true;
  }
  view() {

    this.addgeofence = false;
    this.getFencing();
  }
  deleteClick(list, v) {
    this.service.deletegeofencedriver(list, v).subscribe(res => {
      if (res['success'] == true) {
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })
      }
    })
  }
  getFencing() {

    this.service.getGeofencingList(this.keyword).subscribe((res) => {
      if (res['success'] == true) {
        this.fencingList = res['driver'];



      }
    });
  }
  editgeofence(id) {
    this.router.navigate(['/admin/edit-geofence', id])
  }
  onEdit(locationPoints, geoId) {
    this.geoFenceId = '';

    this.geoFenceId = geoId;
    this.vacantDriversList = [];
    this.otherDriversList = [];
    var drawPolygonArr = [];

    locationPoints.forEach(element => {
      drawPolygonArr.push({
        lat: element.lat,
        lng: element.lng
      })
    });
    if (this.constructTriangle) {
      this.constructTriangle.setMap(null)
    }
    // Construct the polygon.
    this.constructTriangle = new google.maps.Polygon({
      paths: drawPolygonArr,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      editable: false
    });
    this.constructTriangle.setMap(null);

    this.constructTriangle.setMap(this.Map);
    this.service.getVacantDrivers(locationPoints, this.geoFenceId).subscribe((res) => {

      this.vacantDriverView = true;
      this.addgeofence = false;

      // console.log(this.vacantDriverView);

      // console.log(this.addgeofence);
      // this.


      this.vacantDriversList = res['driver'];

      this.otherDriversList = res['otherDriver'];
      this.geoFenceList = res['geoDrivers'];
      // this.vacantDrivers =  this.geoFenceList
      let arr = []
      this.geoFenceList.forEach(element => {
        arr.push(element._id)
      });
      this.vacantDrivers = arr

      // console.log("vacantDrivers",this.vacantDrivers);

      // this.vacantDrivers = this.vacantDrivers


      // this.fencingDrivers = ["5d3aabba2729571714a76fc7","5d4167af4e10fb156f3e88db","5d3ab8bc212a5d639c326a91"]

      // console.log("res---",res['otherDriver'], res);
      // console.log('geoDriver', this.otherDriversList);


    });

    // this.formGroup.controls['category'].setValue(this.otherDriversList.category.geoId);

    //           this.service.getDriverList(this.geoFenceId).subscribe((res)=>{

    //             if(res['success']==true){
    //               console.log('geoFenceId',this.geoFenceId)
    //             // console.log('driver',this.vacantDriversList);

    //               // this.vacantDriversList= res['Driver'];
    //               console.log(res);
    //               console.log('otherDriversList',this.otherDriversList);

    //               // console.log('vacantDriverList',this.driver)
    // ;              


    //             }


    //           });
    // this.multiple.vacantDrivers.setValue(this.selected);
    // let dropDownData = this.fencingList.find((data: any) => data.fencingList ===name);
    //     if (dropDownData) {
    //       this. vacantDrivers= dropDownData.vacantDriversList;
    //       this.otherDriversList=dropDownData.otherDriversList;
    //       if(this.vacantDriversList && this.otherDriversList){
    //         this.vacantDrivers=this.vacantDriversList[0];
    //         this.fencingDrivers=this.otherDriversList[0];      }


    //     }

  }
  goBack() {
    this.vacantDriverView = false;
    this.addgeofence = false;
  }


  addVacantDriver() {
    // console.log(this.driverName);
  }

  addtDriver() {
    // console.log(this.vacantDrivers);
    // console.log(this.geoFenceId);
    var driverData = {
      "driverId": this.vacantDrivers,
      "geoId": this.geoFenceId
    }

    this.service.selectDriversForGeofencing(driverData).subscribe((res) => {

      if (res['success'] == true) {

        // console.log("vacantDrivers",res);
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })

      } else {
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })
      }


    });
  }

  //   addtOtherDriver() {
  //     var otherDriverData = {
  //       "driverId": this.fencingDrivers,
  //       "geoId": this.geoFenceId
  //     }

  // this.service.selectDriversForGeofencing(otherDriverData).subscribe((res) => {

  //       if (res['success'] == true) {

  //         // console.log("resOtherDrivers",res);
  //         this.snackBar.open(res['message'], 'x', {
  //           duration: 5000,
  //         })

  //       } else {
  //         this.snackBar.open(res['message'], 'x', {
  //           duration: 5000,
  //         })
  //       }



  //     });

  //   }

}
