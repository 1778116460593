import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!localStorage.getItem('curruntUser')) {
            this.router.navigate(['']);
            return false;
          } else {
            var activeSession = sessionStorage.getItem('x-auth');
            if (activeSession || localStorage.getItem('rememberUser') == '1') {
              return true
            }
            else if (!activeSession) {
              this.router.navigate(['/'])
            }
          }
        })
      )
  }
}

