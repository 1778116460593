import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';

export class ChatService {
    // private url = "http://13.232.208.65:9000/";
    // private url = "https://nayoapi.appgrowthcompany.com/";
    private url = "https://api.nayo.cm/";
    // private url = "https://api.rydzgroup.com/";
    socket;

    constructor() {


    }

    startUp() {
        let userSeesion = JSON.parse(localStorage.getItem('curruntUser'))
        if (userSeesion) {
            this.socket = io(this.url);

            this.socket.emit('onlineAdmin', { adminId: userSeesion._id })
        }
    }

    public sendMessage(message) {
        this.socket.emit('new-message', message);
    }

    public sendBooking(data) {
        console.log(data)
        this.socket.emit('sendBooking', data);

    }


    public sendBookingListener = (id) => {
        return Observable.create((observer) => {
            this.socket.on(id, (data) => {
                observer.next(data);
            });
        });
    }


    public getMessages = (id) => {
        return Observable.create((observer) => {

            this.socket.on(id, (message) => {
                observer.next(message);
            });
        });
    }

    public getTracking = (id) => {
        return Observable.create((observer) => {
            console.log(id)
            this.socket.on(id, (message) => {
                observer.next(message);
            });
        });
    }

    public removeListener(name) {
        this.socket.removeListener(name);
    }

    public changeStatus(status, bookingId) {
        this.socket.emit('driverChangeStatus', { 'bookingId': bookingId, 'status': status });
    }
    public createBooking(bookingForm) {
        console.log("bookingFomm", bookingForm)
        this.socket.emit('    ', bookingForm)
        this.socket.removeListener('sendBooking');
        return Observable.create((observer) => {
            this.socket.on('sendBooking', (message) => {
                observer.next(message);
            })
        });

    }
    public createcarBooking(bookingForm) {
        console.log("bookingFomm", bookingForm)
        this.socket.emit('carBooking', bookingForm)
        this.socket.removeListener('carBooking');
        return Observable.create((observer) => {
            this.socket.on('carBooking', (message) => {
                observer.next(message);
            })
        });

    }
    public sendnotification(body) {
        console.log("body", body)
        this.socket.emit('notification', body)
        this.socket.removeListener('notification');
        return Observable.create((observer) => {
            this.socket.on('notification', (message) => {
                observer.next(message);
            })
        });
    }
    public notification(id) {
        return Observable.create((observer) => {
            this.socket.on(id, (message) => {
                observer.next(message);
            })
        });
    }
    public merchantStatus(from) {
        console.log("status", from)
        this.socket.emit('storeAdminStatus', from)
        this.socket.removeListener('storeAdminStatus');
        return Observable.create((observer) => {
            this.socket.on('storeAdminStatus', (message) => {
                console.log("storeAdminStatus", message)
                observer.next(message);
                console.log("storeAdminStatus", message)

            })
        });

    }
    public driverStatus(from) {
        console.log("status", from)
        this.socket.emit('driverAdminStatus', from)
        this.socket.removeListener('driverAdminStatus');
        return Observable.create((observer) => {
            this.socket.on('driverAdminStatus', (message) => {
                console.log("driverAdminStatus", message)
                observer.next(message);
                console.log("driverAdminStatus", message)

            })
        });

    }
    public deliveryStatus(from) {
        console.log("status", from)
        this.socket.emit('deliveryAdminStatus', from)
        this.socket.removeListener('deliveryAdminStatus');
        return Observable.create((observer) => {
            this.socket.on('deliveryAdminStatus', (message) => {
                console.log("deliveryAdminStatus", message)
                observer.next(message);
                console.log("deliveryAdminStatus", message)

            })
        });

    }
    public pendingBooking(bookingForm) {
        console.log("bookingFomm", bookingForm)
        this.socket.emit('createBooking', bookingForm)
        this.socket.removeListener('createBooking');
        return Observable.create((observer) => {
            this.socket.on('createBooking', (message) => {
                observer.next(message);
            })
        });

    }
}
