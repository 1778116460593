

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

import { NgForm } from '@angular/forms';
import { combineAll } from 'rxjs/operators';
@Component({
  selector: 'app-add-merchant',
  templateUrl: './add-merchant.component.html',
  styleUrls: ['./add-merchant.component.scss']
})
export class AddMerchantComponent implements OnInit {
  user:any={}
  messagee:any
  editMerchant:any='';
  adminId:any;
  merchantId:any;
  country:any;
  state:any;
  indexValue:any=1;
  city:any;
  formValid: boolean = false
  formatedAddress:any;
  firstDoc:any;
  secondDoc:any;
  thirdDoc:any;
  fourthDoc:any;
  category : any ;
  add: boolean = true

  // categoryList:any[]
  merchantCategoryList:any;
  public allowDoc = ['jpg','jpeg','png','pdf'];
  public allowDocError:string;
  storeId: string;
  latitude: any;
  longitude: any;
  slabList: any=[]
  fairPerKm:any;
  fareChangekm:any;
  selectfairPerKm:any;
  selectfareChangekm:any;
  baseFare: any;
  selectbaseFare: any;
  constructor(private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router, private service:ApiService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    // this.storeId = localStorage.getItem('storeId') ? localStorage.getItem('storeId') : ""
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.user.countrycode ='+';
    console.log("countryCode",this.user.countrycode)
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.merchantId = params['id'];
      // console.log(this.merchantId);
      if(this.merchantId!='' && this.merchantId!=undefined && this.merchantId!=null){
        this.getStoreInfo();
        this.editMerchant = 'Edit';
        // console.log("params----"+params['id']);
      }
    });
    this.getCategoryList();
  }
  

  goToMerchant()
  {
    this.router.navigate(['/admin/manage-merchants']);
  }

  onSubmit(form){
   console.log(form)
   if(this.editMerchant==''){
  
      if(this.firstDoc &&  this.secondDoc &&  this.thirdDoc &&  this.fourthDoc){
        console.log("data")
      var merchantData = {
        'storeName':this.user.storeName,
        'email':this.user.email,
        'password':this.user.password,
        'bio':this.user.bio,
        'phone':this.user.phone,
        'countrycode':this.user.countrycode,
        'category':this.user.category,
        'add':{"formatedAddress":this.formatedAddress, 'city':this.city,lat: this.latitude,
        long: this.longitude},
        'slabs':this.slabList,
        'baseFare':this.user.baseFare,
        // 'add':{"formatedAddress":this.formatedAddress,'city':this.city},
        // 'add':{"formatedAddress":this.formatedAddress, 'state':this.state,'city':this.city,'country':this.country},
        'storeId':this.merchantId,
        'adminId':this.adminId,
      };

      // console.log(merchantData);
  
      this.service.registerMerchants(this.firstDoc, this.secondDoc, this.thirdDoc, this.fourthDoc, merchantData).subscribe((res)=>{
  
        if(res['success']==1){
          console.log('success', res);
          this.user='';
          this.formatedAddress='';
          this.state='';
          this.country='';
           this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })

          this.router.navigate(['/admin/manage-merchants']);
  
        } else {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }
  
      })
    } 
  
    }
     else  {
      
      console.log("form",form)
      if(form){
        console.log("formddd",form)

      var merchantInfoData = {
        'storeName':this.user.storeName,
        'email':this.user.email,
        'password':this.user.password,
        'bio':this.user.bio,
        'phone':this.user.phone,
        'countrycode':this.user.countrycode,
        'category':this.user.category,
        'slabs':this.slabList,
        'baseFare':this.user.baseFare,
       'add':{"formatedAddress":this.formatedAddress, 'city':this.city, lat: this.latitude,
       long: this.longitude,},

        // 'add':{"formatedAddress":this.formatedAddress, 'city':this.city, 'state':this.state,'country':this.country},
        'adminId':this.adminId,
        'storeId':this.merchantId
      };

      // console.log("merchantInfoData", merchantInfoData);

      var fd = new FormData();
      fd.append("body", JSON.stringify(merchantInfoData));
  
      this.service.editMerchantsInfo(fd).subscribe((res)=>{
  
        if(res['success']==1){
  
          // console.log(res);
          // this.user='';
          // this.formatedAddress='';
          // this.city='';
          // this.country='';
          // this.firstDoc='';
          // this.secondDoc='';
          // this.thirdDoc='';
          // this.fourthDoc='';
          
          this.snackBar.open('Updated sucessfully', 'x', {
            duration: 5000,
          })
  
        } else {
          this.snackBar.open('Please try later', 'x', {
            duration: 5000,
          })
        }
  
      })
    }

    else 
    {
    //   let i = 0;
    //   this.messagee= ''
    // let FormKeys = Object.keys(form.value);
    // for (i = 0; i < FormKeys.length; i++) {
    //   if (form.value[FormKeys[i]] == "" || form.value[FormKeys[i]] == undefined) {
    //     this.messagee=FormKeys[i]
    //     console.log('mesaamessage',this.messagee)
    //     break;
    //   }
    // }
      Swal.fire({
        type: 'error',
        title: this.messagee,
        confirmButtonColor:'#821727',
        allowOutsideClick:false,
        text: 'please fill all details',
      })
    }

  } 
  }
  // validateForm(form: NgForm) {
  //   let i = 0;
  //   let FormKeys = Object.keys(form.value);
  //   for (i = 0; i < FormKeys.length; i++) {
  //     if (form.value[FormKeys[i]] == "" || form.value[FormKeys[i]] == undefined) {
  //       this.toastw(`${FormKeys[i]} is required`)
  //       break;
  //     }
  //   }
  //   this.formValid = true
  // }
  // toastw(mesg) {
  //   this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  // }
validateForm(f:NgForm) {
    console.log(f.value);
    f.value.address = this.formatedAddress;
    f.value.city = this.city;
    
   if(f.value.name==undefined){
    return this.toastw("Merchant name is required.")
   }
   
   if(f.value.email==undefined){
    return this.toastw(" email is required.")
   }
   if(!f.value.password && this.editMerchant == ''){
    return this.toastw(" password is required.")
   }
   
   if(f.value.bio==undefined){
    return this.toastw("bio is required.")
   }
   if(f.value.profileImages =="" && this.editMerchant == ''){
    return this.toastw("product image is required.")
   }
    if(f.value.phone==undefined  ){
     return this.toastw("Phone number is required.")
    }
    let Num = /^[0-9]*$/;
    // console.log("Num.test(form.value.phone)", regx.test(f.value.countrycode,),form.value.phone.length);
    if (!(Num.test(f.value.phone)) || (f.value.phone.length < 7 || f.value.phone.length > 15)) {
      
      return this.toastw("phone Number is invalid.")
    }
    // if(f.value.countryCode.length<1){
    //   return this.toastw("Phone number is invalid.")
    //  }
     if (f.value.countryCode== undefined) {
      return this.toastw("country code is required.")
    }

    console.log("form.value.countrycode", f.value.countryCode);

    let regx = new RegExp(/^(\+?\d{1,3}|\d{1,4})$/)
    console.log("regx.test(form.value.countrycode)", regx.test(f.value.countryCode));
    if (!(regx.test(f.value.countryCode))) {
      return this.toastw("country code is required.")
    }
      console.log(f.value.LImage ,f.value.RCImage,f.value.TImage,"documents")
      if (f.value.category== undefined) {
        return this.toastw("category is required.")
        }
     if(!this. firstDoc && this.editMerchant == ''){
     return this.toastw(" Driving License image is required.")
    }

     if(!this. secondDoc && this.editMerchant == ''){
      return this.toastw("Number Plate image is required.")
     }

      if(!this. thirdDoc && this.editMerchant == ''){
      return this.toastw("TaxiPermit image is required.")
     } 
     
     if(!this. fourthDoc && this.editMerchant == ''){
      return this.toastw("Profile image is required.")
     }
      //   if(!f.value.address){
      //  return this.toastw("Address  is required")

      //   }
    console.log(f.value.address, '------address')
        if(f.value.address==''){
         return this.toastw("Address  is required")
        }
        if(f.value.baseFare ==''){
          return this.toastw("Base Fare  is required")
         }
         if(this.slabList && this.slabList.length==0){
         return this.toastw("Slab  is required")
        }
    this.formValid = true
   this.onSubmit(f)
  }
  toastw(mesg){
    this.toastr.warning(mesg, "", { closeButton: true,timeOut: 1000, positionClass: 'toast-bottom-center' });
   }

  handleCountryChange(address) {
    this.country='';
    // console.log(address);

    // if (address.formatted_address) {
    //   this.formatedAddress = address.formatted_address
    // }
    address.address_components.forEach(key => {
      if (key.types[0] =='country') {
        this.country = key.long_name
      }
    });

    // console.log('-----', this.country);
   
  }

  onChangevechicle(event) {
  
    let obj = this.merchantCategoryList.find(o => o._id == event);
    this.category= obj._id

    console.log(obj)
    console.log('category',this.category)

  }

  handleStateChange(address){
    this.state='';
    address.address_components.forEach(key => {
     
      if (key.types[0] == 'administrative_area_level_1') {
        this.state = key.long_name
      }
    });
    // console.log('-----', this.state);
  }

  handleCityChange(address){
    this.city='';
    address.address_components.forEach(key => {
      if(key.types[0]=='locality'){
        this.city = key.long_name
      }
    });
    // console.log('-----', this.city);
  }

  handleAddressChnage(address){
    this.formatedAddress='';
    address.address_components.forEach(key => {
      if(key.types[0]=='locality'){
        this.city = key.long_name
      }
    });
    if (address.formatted_address) {
      console.log("address.formatted_address",address)
      this.formatedAddress = address.formatted_address;
      
      this.latitude = address.geometry.location.lat()
      this.longitude = address.geometry.location.lng()
    }
    
  }


  documentFirstUpload(event){
    
    // console.log("this.profilePic", this.firstDoc);
    this.allowDocError = '';
    var fileName =  event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if(this.allowFile(fileName.name))
    this.firstDoc = event.target.files[0];
    else{
    event.target.value='';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor:'#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    // console.log("this.profilePic", this.fourthDoc);
    }
    console.log(this.firstDoc)
  }

  documentSecondUpload(event){
    
    // console.log("this.profilePic", this.secondDoc);
    this.allowDocError = '';
    var fileName =  event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if(this.allowFile(fileName.name))
    this.secondDoc  = event.target.files[0];
    else{
    event.target.value='';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor:'#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    // console.log("this.profilePic", this.fourthDoc);
    }
  }

  documentThirdUpload(event){
   
    // console.log("this.profilePic", this.thirdDoc);
    this.allowDocError = '';
    var fileName =  event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if(this.allowFile(fileName.name))
    this.thirdDoc = event.target.files[0];
    else{
    event.target.value='';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor:'#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    console.log("this.profilePic", this.fourthDoc);
    }
  }

  documentFourthUpload(event){
    this.allowDocError = '';
    var fileName =  event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if(this.allowFile(fileName.name))
    this.fourthDoc = event.target.files[0];
    else{
    event.target.value='';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor:'#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    // console.log("this.profilePic", this.fourthDoc);
    }
  }

  allowFile(filename){
    if(filename)
     return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1?true:false;
  }

  getCategoryList(){

    this.service.getMerchantCategoryListFromDb().subscribe((res)=>{

      if(res['success']==1){

        console.log("category=--", res);

        this.merchantCategoryList = res['store'];

        

        // this.user.category = this.merchantCategoryList.title;

      }

    });

  }

  getStoreInfo(){
    this.service.getStoreInfoFromDb(this.merchantId).subscribe((res)=>{


      if(res['success']==1){

        console.log('ttt', res);

        this.user = res['store'];
       
        this.slabList=this.user.slabs;


      }

    });
  }

  changeValue(vm){
    // this.user.countryCode= '+'+vm.target.value
    if(this.user.countrycode!=null && this.user.countrycode.includes('+')){
      this.user.countrycode = vm.target.value;
      console.log(this.user.countrycode ,"this.user.countryCode ")
    } else {
      
      this.user.countrycode= '+'+ vm.target.value;
      
    }
    console.log('changeValue', this.user.countrycode);
  }
  editSlab(data, i) {
    this.indexValue = i
    this.fairPerKm = data.fairPerKm
    this.fareChangekm = data.fareChangekm
    // this.baseFare  = data.baseFare
    console.log(data,this.fairPerKm,"fairPerKm")
    this.add = false
    this.selectfairPerKm = data.fairPerKm
    this.selectfareChangekm= data.fareChangekm
    // this.selectbaseFare =data.baseFare
  }
  deleteSlab(data){
    let index = this.slabList.indexOf(data);
    this.slabList.splice(index, 1)
    if(this.slabList.length==0){
      this.add = true;
    }
  }
  AddSlabs(f:NgForm){
    console.log(f.value)
    if(f.value.fairPerKm=='' ||f.value.fairPerKm == undefined ){
    return this.toastr.error('Enter Fare')
    }
     if(f.value.fareChangekm =='' || f.value.fareChangekm ==undefined ){
    return this.toastr.error('Enter Fare Change per km')
    }
  
    if(this.slabList && this.slabList.length > 4){
      return this.toastr.error('Maximum 5 Slabs you can Enter')

    }
    console.log(f.value,this.fareChangekm,"fare")

    let data1 = {
      fairPerKm: f.value.fairPerKm,
      fareChangekm: f.value.fareChangekm,
      // baseFare: f.value.baseFare
    }
    this.slabList.push(data1)
    this.toastr.success('Added Succesfully')
    this.fairPerKm ='';
    this.fareChangekm =''
    // this.baseFare =''
    this.add = true;

    console.log(this.slabList,"data")
  }
  newaddlab(f){
    this.fairPerKm ='';
    this.fareChangekm =''
    // this.baseFare =''
    this.add = true;
  }
  updateslab() {
    if (this.fareChangekm == '' || this.fairPerKm == '')
      return this.toastr.error('Enter  Fare and Fare change per Km')
    if (this.selectfairPerKm || this.selectfareChangekm ) {
      console.log("ataaffff")
      const index = this.slabList.indexOf(this.fareChangekm, this.fairPerKm);
      console.log(this.slabList, "data")
      this.slabList[this.indexValue].fareChangekm = this.fareChangekm;
      this.slabList[this.indexValue].fairPerKm = this.fairPerKm;

      this.toastr.success('Updated Successfully!');
      this.fairPerKm ='';
       this.fareChangekm =''
      //  this.baseFare =''
      this.add = true;
      // }
    }
    else {
      if (this.slabList.indexOf(this.fairPerKm) == -1 && this.slabList.indexOf(this.fareChangekm == -1)) {
        let data = {
          fareChangekm: this.fareChangekm,
          fairPerKm: this.fairPerKm,
          // baseFare :this.baseFare
        }
        this.slabList.push(data)
      this.toastr.success('Updated Successfully!');
      this.fairPerKm ='';
       this.fareChangekm =''
      //  this.baseFare =''
      this.add = true;
      console.log("list",this.slabList)

  }
    }

  }
 

}






