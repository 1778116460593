import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar,MatInput } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  startDate: any;
  endDate: any;
  driveradmin: any
  keyword: string;
  bookings = [];
  storeId: string
  adminState: string
  bookingList: any;
  query: string
  search: any;
  store: string
  selected: true
  field: any = '';
  adminId: any;
  readioSelected: any='';

  currentPage: Number;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  status: any;
  usersession: any;
  permissions: any;
  onload: boolean = false

  @ViewChild('enddate', {
    read: MatInput
  }) enddate: MatInput;
  @ViewChild('startdate', {
    read: MatInput
  }) startdate: MatInput;
  csvList: any;
  pdfview:boolean=false;
  imageUrl:string;
  constructor(
    private _services: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }
  ngOnInit(){
    if (JSON.parse(localStorage.getItem('permissions'))){
    this.imageUrl=this._services.baseUrl
    this.permissions = JSON.parse(localStorage.getItem('permissions')).invoice
    console.log(this.permissions,"permissionss")
  localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    }
  let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
  this.adminId = curruntUser._id;
  this.currentPage = 1
  this.searchFilter(this.currentPage);
}

getStartDate(ev) {
  this.startDate = moment(ev.value).valueOf();
  // console.log('Startdate', this.startDate)
}
goToInvoice(id){
  this._services.getInvoicePDFById(id).subscribe((res: any) => {
    console.log(res,"====" )
    // if(res.success == 1)
  });
}
getEndDate(ev) {
  console.log("DATE", ev)
  this.endDate = moment(ev.value).valueOf();
  // console.log('enddate', this.endDate)
}

searchFilter(page) {
  let data =
  {
    page: page,
    limit:10

  };
   if (this.startDate) {
    data['startdate'] = this.startDate;
  }
  if (this.endDate) {
    data['enddate'] = this.endDate;
  }
 

  this._services.filterInvoiceDb(data).subscribe((res: any) => {
    console.log(res,"====" )
    if(res.success == 1)
    this.bookings = res.stores.result;
    // this.bookings.forEach(ele => {
    //   ele.totalAmount = ele.itemAmount + ele.fare;
    // });
    // this.driveradmin=res.bookings.driverId.driverAdmin
    this.onload = true
    this.config = {
      id: "page",
      currentPage: page,
      itemsPerPage: 10,
      totalItems: res.stores.count
    }

  });
}

resetFilter() {
  // this.field = '';
  // this.keyword = '';
  // this.status = '';
  this.startDate = '',
    this.endDate = '',
    this.startdate.value = null;
  this.enddate.value = null;
  this.searchFilter(this.currentPage);

}

pageChange(newPage: number) {
  console.log("newPage", newPage)
  // this.getBookingList(newPage)
  this.searchFilter(newPage)
}


exportCSV(id) {
  this._services.getInvoiceById(id).subscribe((response:any) => {
    // this.stores=response.stores.result[0]
    // })
      this.csvList = response.stores.result
      // this.driversList = this.drivers.driver
      this.onload = true
      var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      useBom: true,
      headers: ["Merchant Name", "Contact Number	"," Email","Total Booking	","Total Earnings	"]
    };
    var data = [];
    this.csvList.forEach(element => {
      data.push({
             name:element.storeName? element.storeName : "",
             contactno: element.countryCode + " " + element.phone,
             email: element.email,
             totalBookings:element.totalBookings?element.totalBookings:"",
             totalAmount:element.totalAmount?element.totalAmount:'',
           
      })
      if(element.slabs.length>0){
        data.push({})
        data.push({"Kilometers":"Kilometers","Qty":"Qty", "Rate":"Rate", "Total":"Total","Amount (AED)":"Amount (AED)"})
      element.slabs.forEach(element => {
          data.push({
            Kilometers:element.slabPerKm? element.slabPerKm +'km' : "",
            Qty: element.fareChangekm? element.fareChangekm : "",
            Rate: element.fairPerKm ? element.fairPerKm : "",
            Total: element.price ? element.price : "",
            TotalAmount:'',
        })
      });
    
       data.push({
        Kilometers: "",
        Qty: "",
        Rate: "",
        Total: "",
        TotalAmount: element.totalAmount
      
      })
    }
      if(element.currentBookings.length>0){
        data.push({})
        data.push({"OrderNo":"OrderNo","Source":"Source", "Destination":"Destination", "Time":"Time","Fare":"Fare","Product Amount":"Product Amount","Initial Payment":"Initial Payment","payment":"payment","Distance":"distance"})
      element.currentBookings.forEach(element => {
        let status =''
       
        if(element.isPaidInitial==1 ||element.isPaidInitial==0){
          switch(Number(element.isPaid)){
            case 0 : status= "customer not available";
            break;
            case 1 :status= "paid";
            break;
            case 2 : status= "customer return product"
            break;
            case 3: status ='Product Delivered but not paid'
          }
        }
        data.push({
          OrderNo:element.OrderNo? element.OrderNo : "",
          source: element.source.name? element.source.name : "",
          destination: element.destination.name ? element.destination.name : "",
          tripStartTime: moment(element.tripStartTime).format('MMMM Do YYYY, h:mm:ss a'),
         fare: element.fare,
         ProductAmount:element.itemAmount?element.itemAmount:"0",
         InitialPayment:element.isPaidInitial ? "Online" : "COD",
        //  merchant:element.storeId?element.storeId.storeName:'',
         payment:status,
         distance:element.distance?element.distance:''
  
        })
      });
      
      // data.push({
      //   source: "",
      //   destination: "",
      //   tripStartTime: "Total",
      //  fare: total,
      // })
    }
    });
  
  
    new AngularCsv(data, 'Invoice  Report', options);
  })
}


}
