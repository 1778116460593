import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';

import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-drivers',
  templateUrl: './manage-drivers.component.html',
  styleUrls: ['./manage-drivers.component.scss']
})
export class ManageDriversComponent implements OnInit {
  field: any = 'All';
  keyword: any = '';
  status: any = '';
  driversList: any;
  permissions: any;
  drivers: any
  onload: boolean = false
  currentPage: Number;

  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  usersession: any;
  limit: any;
  // driversList:any=[];

  constructor(private route: ActivatedRoute, private router: Router, private service: ApiService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).driver
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.currentPage = 1
    this.getDriversList(this.currentPage);
  }
  goToAddDriver() {
    this.router.navigate(['admin/add-drivers']);
  }

  getDriversList(current) {
    this.currentPage = current;
    this.service.geAlltDriversList(this.field, this.keyword, this.status, this.currentPage, this.limit, "").subscribe((res) => {
      console.log(res);
      this.drivers = res
      this.driversList = this.drivers.driver
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: this.drivers.total
      }
    });
  }


  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getDriversList(newPage)
  }
  resetFilter() {
    this.field = 'All';
    this.keyword = '';
    this.status = '';
    this.getDriversList(this.currentPage);
  }


  changeToggleCOD(list: any, isCod) {
    let form = {
      driverId: list._id,
      isCod: isCod ? 0 : 1,

    }
    console.log("form", form, list);

    this.service.chnageCODStatus(form)
      .subscribe(
        (data: any) => {
          if (data.success == true) {
            this.ngOnInit()
          }

        }
      )




  }
  goToMapDriver() {
    this.router.navigate(['/admin/driverLocation']);

  }
  changeToggle(list: any, status) {
    if (status == 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        // console.log(list._id, status);
        if (result.value) {
          let form = {
            driverId: list._id,
            status: status ? 0 : 1,
            remark: result.value
          }

          this.service.chnageDriverStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
                  // console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()
                  }

                }
              }
            )
        }
        else {
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }
      })

    } else {

      Swal.fire({
        title: 'Are you sure?',
        text: "You want to change status",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        // console.log(list._id, status);
        if (result.value == true) {
          let form = {
            driverId: list._id,
            status: status ? 0 : 1,
            remark: ''
          }

          this.service.chnageDriverStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
                  // console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()
                  }
                }
              }
            )
        }


        else {
          console.log("gjhffjhfhjg")
          this.ngOnInit()
        }
      })
    }



  }

  editDriver(user) {
    var id = user.id;
    this.router.navigate(['/admin/add-drivers', { id }]);

  }

  editDocument(user) {
    var id = user.id;
    this.router.navigate(['/admin/driver-document', { id }])
  }

  checkClick(driverId) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Please add Commission",
      type: 'warning',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        let form = {
          driverId: driverId,
          status: 1,
          commission: result.value
        }
        this.service.updateVerifyStatus(form)
          .subscribe(

            (res: any) => {
              if (res.success) {
                if (res.success) {
                  // console.log(res);
                  this.snackBar.open(res.message, 'x', {
                    duration: 5000,
                  });
                  this.getDriversList(this.currentPage);

                }


              }

            })
      }
    })
  }

  crossClick(driverId) {

    Swal.fire({
      title: 'Are you sure?',
      text: "Please remark",
      type: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        // this.uncheckedToggle = true;


        // console.log(list._id, status);
        // var newStatus;
        // if (status == 'Unapproved') {
        //   newStatus = 'Approved';
        // } else if (status == 'Approved') {
        //   newStatus = 'Unapproved';
        // }
        // console.log(newStatus);
        let form = {
          driverId: driverId,
          reason: result.value,
          status: 2
        }

        this.service.updateVerifyStatus(form)
          .subscribe(
            (res: any) => {
              if (res.success) {

                if (res.success) {

                  // console.log(res);
                  this.snackBar.open(res.message, 'x', {
                    duration: 5000,
                  });

                  this.getDriversList(this.currentPage);

                }


              }

            }
          )
      } else {
        console.log("no", status)
        // this.uncheckedToggle = false;
        this.ngOnInit()
        //console.log("uncheckedToggle", this.uncheckedToggle)
      }
    })



  }
  exportCSV() {
    let limit = 0
    let isAvailable = ''
    this.service.geAlltDriversList(this.field, this.keyword, this.status, this.currentPage, limit, isAvailable).subscribe((res) => {

      console.log(res);
      this.drivers = res
      this.driversList = this.drivers.driver
      this.onload = true
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: ["Driver Name", "E-mail", "createdAt", "Mobile", "DriverType", "Working hours/time", "status"]
      };
      var data = [];
      this.driversList.forEach(element => {
        element.createdAt = moment(element.createdAt).format('ll');
        data.push({
          drivername: element.firstName + " " + element.lastName,
          email: element.email,
          createdAt: element.createdAt,
          Mobile: element.countryCode + "" + element.phone,
          DriverType: element.driverAdmin ? element.driverAdmin.companyName : "inhouse",
          shiftStartTime: element.shiftStartTime.hour + "" + ":" + element.shiftStartTime.minute + "-" + element.shiftEndTime.hour + "" + ":" + element.shiftEndTime.minute,
          Status: element.status ? "active" : "disable"

          // ridername : element.userId ? element.userId.firstName +"" +element.userId.lastName : element.firstName  +"" +element.lastName, 

        })
      });
      new AngularCsv(data, 'Driver  report', options);
    })
  }

}
