import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-merchant-documents',
  templateUrl: './manage-merchant-documents.component.html',
  styleUrls: ['./manage-merchant-documents.component.scss']
})
export class ManageMerchantDocumentsComponent implements OnInit {


  public licence: any
  public plate: any
  public inzi: any
  public rc: any
  public document1: string
  public document2: string
  public document3: string
  public document4: string;
  public merChantId: any
  public baseUrl: any
  public storeId: any;

  public allowDoc = ['jpg', 'jpeg', 'png', 'pdf', 'JPG', 'PNG', 'JPEG', 'PDF'];
  public imageExtentions = ['jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG'];
  // public docExtentions = ['doc','docx']
  public allowDocError: string;
  profileImage: any;

  constructor(
    private service: ApiService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.baseUrl = this.service.baseUrl
    this.route.params.subscribe(params => {
      this.merChantId = params['id']
    });

    this.getMerchantDocFromDb();

    // this.service.getMerchantDoc(this.merchantId)
    //   .subscribe(
    //     (data: any) => {
    //       let store = data.store
    //       this.storeId = store._id
    //       if (store) {
    //         console.log("data", data);
    //         // this.document1 = data._id
    //         this.document1 = this.baseUrl + store.document1
    //         // this.document2 = data._id
    //         this.document2 = this.baseUrl + store.document2
    //         // this.document3 = data._id
    //         this.document3 = this.baseUrl + store.document3
    //       }

    //       else {
    //         this.merchantId = 'noId'
    //       }
    //     })
  }

  // onDocumen1tAdded(event) {
  //   this.document1 = event.target.files[0]
  //   this.changeImage(this.document1, 'document1')
  // }

  // onDocumen2tAdded(event) {
  //   this.document2 = event.target.files[0]


  //   this.changeImage(this.document2, 'document2')
  // }

  // onDocumen3tAdded(event) {
  //   this.document3 = event.target.files[0]
  //   this.changeImage(this.document3, 'document3')
  // }

  // onDocumentChanage(event, doc) {

  //   var file = event.target.files[0]
  //   this.changeImage(file, doc);
  // }
  download() {
    console.log("download")
  }

  changeImage(file, doc) {

    var docName;

    switch (doc) {
      case 'document1':
        docName = 'document1'
        break
      case 'document2':
        docName = 'document2'
        break
      case 'document3':
        docName = 'document3'
        break
      case 'profileImages':
        docName = 'profileImages'
        break
    }



    let docId = { storeId: this.merChantId };
    // console.log(docName);
    let formDocuments = new FormData()
    formDocuments.append(docName, file)
    formDocuments.append('data', JSON.stringify(docId));

    this.service.updateMerchantDocuments(formDocuments)
      .subscribe((res) => {
        if (res['success'] == 1) {

          this.getMerchantDocFromDb();
        }

      })
  }


  getMerchantDocFromDb() {
    this.service.getMerchantStoreDoc(this.merChantId).subscribe((res) => {
      if (res['success'] == 1) {
        console.log(res);
        this.document1 = res['store'].document1;
        this.document2 = res['store'].document2;
        this.document3 = res['store'].document3;
        this.profileImage = res['store'].profileImage
        console.log("profileimage", this.profileImage);
      }
    });
  }
  checkFileExtention(filename) {
    return filename.split('.').pop();
  }

  goToBack() {
    this.router.navigate(['/admin/manage-merchants']);
  }

  onDocument1Chanage(event, doc) {
    var file = event.target.files[0];
    this.allowDocError = '';
    if (this.allowFile(file.name))
      this.changeImage(file, doc);
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg png and pdf',
      })
    }
  }
  onDocument2Added(event, doc) {
    var file = event.target.files[0]
    // this.changeImage(file, doc);
    this.allowDocError = '';
    if (this.allowFile(file.name))
      this.changeImage(file, doc);
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg , png and pdf',
      })
    }
  }
  onDocument3Added(event, doc) {
    var file = event.target.files[0]
    // this.changeImage(file, doc);
    this.allowDocError = '';
    if (this.allowFile(file.name))
      this.changeImage(file, doc);
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg , png and pdf',
      })
    }
  }

  onDocument4Added(event, doc) {
    var file = event.target.files[0]
    // this.changeImage(file, doc);
    this.allowDocError = '';
    if (this.allowFile(file.name))
      this.changeImage(file, doc);
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg , png and pdf',
      })
    }
  }

  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }
  toDataURL(url) {
    return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
}
  async forceDownload(baseUrl, name) {
    const a = document.createElement("a");
    a.href = await this.toDataURL(baseUrl + name);
    a.download = name.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

