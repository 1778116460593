import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { store } from '@angular/core/src/render3';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.scss']
})
export class ScheduledComponent implements OnInit {
  adminId: any;
  status:any;
  public bookings:any
  usersession: any;
  permissions: any;
  constructor( private _services: ApiService, private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
  
  this.usersession = JSON.parse(localStorage.getItem('curruntUser'));

  // this.service.currentUserPath = this.router.url;
  // console.log(this.service.currentUserPath);

  if (JSON.parse(localStorage.getItem('permissions')))
    this.permissions = JSON.parse(localStorage.getItem('permissions')).Scheduled


  localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
  let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
  this.adminId = curruntUser._id;
  this.getBookingList();
  }
  getBookingList() {

    this._services.getscheduled(this.adminId).subscribe((res) => {

      // if (res['success'] == true) {

         console.log(res);
        
        this.bookings= res
        this.bookings=   this.bookings.booking['result']
        console.log("bookings",this.bookings)

      // }

    });
  }
}
