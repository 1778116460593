import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

import { NgForm, FormControl } from '@angular/forms'
import { HttpClient } from '@angular/common/http'

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete'
import { Address } from 'ngx-google-places-autocomplete/objects/address'
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-sub-admin',
  templateUrl: './add-sub-admin.component.html',
  styleUrls: ['./add-sub-admin.component.scss']
})
export class AddSubAdminComponent implements OnInit {
//  user ={add:null,delete:null,edit:null,view:null};
  public user=<any>Object;
 public users=<any>{};
 public driver=<any>{};
 public merchant=<any>{};
 public driveradmin=<any>{};
 public deliveryadmin=<any>{};
 public geofencing=<any>{};
 public cms=<any>{};
 public booking=<any>{};
 public category=<any>{};
 public payment=<any>{};
 public discount=<any>{};
 public analytics=<any>{};
 public notification=<any>{};
 public tracking=<any>{};
 public revenue=<any>{};
 public performance=<any>{};
 public invoice=<any>{};
 public cod=<any>{};
 public pendingBooking=<any>{};

  adminId: any;
  userId: any;
  editsubAdmin: string;
  formValid: boolean;
  submitting: boolean;
  toastr: any;
  response: any;
  FormData: any;
  ManageuserPermision: any = [];
  MerchantPermision: any = [];
  DriverPermision: any = [];
  form: any;
  subadmins: any;
  countrycode: string;

  constructor(private service: ApiService, private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }


  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    this.user.countrycode = "+"
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.userId = params['id'];

    
  });
  }
  getSubAdmin(){
    this.service.getsubadmin(1,10).subscribe((data:any)=>{
      this.subadmins = data.subAdmins
   })
  }
  
  goTosubadmin()
{
  this.router.navigate(['/admin/subadmin'])
}
Country(event){
  if(event.target.value.length==0){
    console.log("jhgjhg",event.target.value.length)
    event.target.value= "+"
  }
  if(event.target.value.charAt(0)!=="+"){
    event.target.value="+"+event.target.value
    console.log("eveney Down",event.target.value.charAt(0))
  }
}
RegisterStore(registerForm: NgForm) {
  this.submitting = true
  let formData = new FormData()
  registerForm.value.adminId=this.adminId 
  registerForm.value.type = "2"
  registerForm.value.users  = this.users
  registerForm.value.driver  = this.driver
  registerForm.value.merchant  = this.merchant
  registerForm.value.driveradmin  = this.driveradmin
  registerForm.value.deliveryadmin  = this.deliveryadmin
  registerForm.value.geofencing  = this.geofencing
  registerForm.value.booking  = this.booking
  registerForm.value.category  = this.category
  registerForm.value.payment  = this.payment
  registerForm.value.discount  = this.discount
  registerForm.value.analytics  = this.analytics
  registerForm.value.notification  = this.notification
  registerForm.value.tracking  = this.tracking
  registerForm.value.revenue=this.revenue
  registerForm.value.cms = this.cms
  registerForm.value.pendingBooking = this.pendingBooking
  registerForm.value.invoice = this.invoice
  registerForm.value.cod = this.cod
  registerForm.value.performance = this.performance


  // console.log(registerForm)
  let form = registerForm.value; this.service.createsubadmin(form).subscribe(res => {
    this.response = res
    if (res['success'] == true){
      // this.submitting = false
      // console.log('data',this.form)
      this.snackBar.open(res['message'], 'x', {
        duration: 5000,
      
      })
      this.router.navigate(['/admin/subadmin']);

    } else {
      this.snackBar.open(res['message'], 'x', {
        duration: 5000,
      })
    }

  });

} 

validateForm(form:NgForm) {
  // console.log("forma",formV);
  // console.log("ff",Object.keys(formV));
  // Object.keys(form.value).forEach((field)=>{
  //   console.log("field",field);
    
  //   if(form.value[field]==undefined || '')  {
  //     this.toastw(`${field} is required`)
  //   }
  // })
  if(form.value.firstName==undefined){
    return this.toastw(" name is required.")
   }
   if(form.value.email==undefined){
    return this.toastw("email is required.")
   }
   
  
   if(form.value.countrycode ==undefined){
    return this.toastw("countrycode is required.")
   }     
   if(form.value.phone ==undefined){
    return this.toastw("phone is required.")
   }     
   if(form.value.password ==undefined){
    return this.toastw("password is required.")
   }     
   
   this.formValid =true
  }
  toastw(mesg){
    this.toastr.warning(mesg, "", { closeButton: true,timeOut: 1000, positionClass: 'toast-bottom-center' });
   }
  }
