import { Component, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
// import {jsPDF} from 'jspdf';
import * as jsPDF from 'jspdf';
import html2canvas from  'html2canvas';
@Component({
  selector: 'app-invoice-pdf',
  templateUrl: './invoice-pdf.component.html',
  styleUrls: ['./invoice-pdf.component.scss']
})
export class InvoicePdfComponent implements OnInit {
  stores: any;

  constructor( private _service: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }
    // @ViewChild('search')
    // public searchElementRef: ElementRef;
    @ViewChild('pdfTable') public pdfTable: ElementRef;

  ngOnInit() {
    this.getMerchantById()
  }
  getMerchantById(){
    this._service.getInvoiceById(this.route.params['value'].id).subscribe((response:any) => {
    this.stores=response.stores.result[0]
    })
  }
  public downloadAsPDF() {

//     const doc = new jsPDF();
// console.log(doc,"doc")
//     const specialElementHandlers = {
//       '#editor': function (element, renderer) {
//         console.log(element,renderer,"====")
//         return true;
//       }
//     };

//     const pdfTable = this.pdfTable.nativeElement;

//     doc.fromHTML(pdfTable.innerHTML, 15, 15, {
//       width: 190,
//       'elementHandlers': specialElementHandlers
//     });
var data = document.getElementById('pdfTable');
html2canvas(data).then(canvas =>{
  var imgWidth = 209;
  var pageHeight = 250;
  var imgHeight = canvas.height * imgWidth / canvas.width;
  var heightLeft = imgHeight ;
  const contentDataURl = canvas.toDataURL('image/png')
  let pdf = new jsPDF('p' ,'pt','a4');
  var position = 0;
  pdf.setFontSize(9);

  // const imgData = canvas.toDataURL('image/png');
  // const pdf = new jsPDF({
  //   orientation: 'landscape',
  // });
  // const imgProps= pdf.getImageProperties(imgData);
  // const pdfWidth = pdf.internal.pageSize.getWidth();
  // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  // pdf.save('download.pdf');
  // var pdf = new jsPDF('p','pt',) 
  // var contentDataURl =canvas.toDataURL("image/jpeg",1.0)
  //   pdf.addImage(contentDataURl,0,0,canvas.width,canvas.height,)

  pdf.addImage(contentDataURl, 'PNG', 0, position,imgWidth,imgHeight)
  pdf.save('Invoice.pdf');
});
  }  
  backpage(){
    this.router.navigate(['/admin/invoice'])
  }    
}
