import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  response: any
  formValid: boolean = false

  form: any = {};
  private formSubmitAttempt: boolean;
  constructor(private _services: ApiService,
    private toastr: ToastrService,

    private router: Router, private snackBar:MatSnackBar) { }

  ngOnInit() {
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  // validateForm(forgotForm:NgForm) {
  //   console.log(forgotForm.value);
  // //  console.log("Number(form.value.qunatity)",Number(form.value.qunatity));
    
  //  if(forgotForm.value.email==undefined){
  //   return this.toastw("Email is required.")
  //  }
  
  //  this.formValid = true
  // }
  // toastw(mesg){
  //   this.toastr.warning(mesg, "", { closeButton: true,timeOut: 1000, positionClass: 'toast-bottom-center' });
  //  }
  onSubmit(form) {
    if (this.form && form.valid) {
      let obj = this.form
      obj.field = `Customer`// Driver here in place of `DeliveryAdmin` if Driver login // DriverAdmin if him
      this._services.forgotPassword(obj).subscribe(
        (res: any) => {
          console.log('ForgotPassword',res)
          if (res && res.success == true) {
            this.showToaster();
            this.router.navigate(['/'])
          }
          else { 
            // alert(res.message) 
            this.snackBar.open(res.message, 'x', {
              duration: 3000,
            })
          }
        },
        err => { alert(err) })
    }
    this.formSubmitAttempt = true;
  }
  back()
  {
    this.router.navigate(['/'])
  }
  showToaster() {
    // this.toastr.success("Reset Password Link Send to Your Registered Email.")
    this.snackBar.open('Reset Password Link Send to Your Registered Email.', 'x', {
      duration: 5000,
    })
  }


}
