import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../app/services/chat.service'
import { CommonService } from '../app/services/common.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ChatService]

})
export class AppComponent {
  message: any;
  title = 'vip';
  cookieValue: any;
  adminId: any;
  constructor(private router: Router,
    private chatService: ChatService, public snackBar: MatSnackBar, private common: CommonService
  ) { }
  ngOnInit() {
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    if (localStorage.getItem('curruntUser')) {
      let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
      if (curruntUser && curruntUser._id) {
        this.adminId = curruntUser._id
        this.chatService.socket.emit('onlineAdmin', { adminId: this.adminId })
        this.chatService.socket.on(this.adminId, res => {
          if (res) {

            this.common.setNotification(true);
            this.message = res.message;
            if (res.message) {
              var option = {
                'body': 'Message From RYDZ',
                'icon': 'assets/images/logo-2.png',
                'silent': false,
                'sound': 'assets/sound/sound.mp3'
              }
              var notification = new Notification(this.message, option);
            }
          }
        });
        this.chatService.socket.on('sendNotificationToAdmin', res => {
          this.common.setNotification(true);
          this.message = res.message;
          var option = {
            'body': 'Message From RYDZ',
            'icon': 'assets/images/logo-2.png',
            'silent': false,
            'sound': 'assets/sound/sound.mp3'
          }
          var notification = new Notification(this.message, option);
        });
      }
    }
  }

}



