import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-subadmin',
  templateUrl: './subadmin.component.html',
  styleUrls: ['./subadmin.component.scss']
})
export class SubadminComponent implements OnInit {
  editDriver: string;
  user: any;
  subadmins:any
  limit:number=10
  subadminId:any;
  adminId: any;
  totalRecords:any;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  };
  currentPage: any=1;
  constructor(private route: ActivatedRoute, private router: Router, private service:ApiService, public snackBar: MatSnackBar,) { }


  ngOnInit() {
   
    this.getSubAdmin()
    
  }

  getSubAdmin(){
    
    this.service.getsubadmin(this.currentPage,this.limit).subscribe((data:any)=>{
      this.subadmins = data.subAdmins
      this.totalRecords = data.count
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: data.count
  
  
      }
      
   })
  }
  goToSubAdmin() {
    this.router.navigate(['/admin/add-sub-admin']);

  
  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.currentPage=newPage
    this.getSubAdmin()
  }
  editUser(id:String){
    this.router.navigate(['/admin/edit-sub-admin',id])
  }
  deleteClick(id:string)
  {
this.service.deleteadmin(id).subscribe(res => {
  
  if (res['success'] == true){
    // this.submitting = false

    this.snackBar.open(res['message'], 'x', {
      duration: 5000,
    })
    this.getSubAdmin()

  } else {
    this.snackBar.open(res['message'], 'x', {
      duration: 5000,
    })
  }

});

} 
  }
  


