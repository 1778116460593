import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-manage-car-category',
  templateUrl: './manage-car-category.component.html',
  styleUrls: ['./manage-car-category.component.scss']
})
export class ManageCarCategoryComponent implements OnInit {
  keyword: any = '';
  status: any = '';
  page = 1;
  onload: boolean = false
  vehicleData: any = [];
  adminId: any;
  usersession: any;
  vehicle: any
  permissions: any;
  currentPage: Number;

  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  constructor(public snackBar: MatSnackBar, private service: ApiService, private route: ActivatedRoute, private router: Router) {

  }
  getVehicles(current) {
    this.currentPage = current;
    var data = {
      "adminId": this.adminId,
      "search": this.keyword,
      "page": this.currentPage
    }
    this.service.geAllCarList(data).subscribe((res) => {

      // console.log(res);
      this.vehicle = res;
      this.vehicleData = this.vehicle.data
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: this.vehicle.recordsTotal


      }

    });
  }
  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));


    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));

    // this.service.currentUserPath = this.router.url;

    // console.log(this.service.currentUserPath);

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).category


    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1

    this.getVehicles(this.currentPage);

  }


  resetFilter() {
    this.keyword = '';
    this.getVehicles(this.currentPage);
  }
  goToAddCarCategories() {
    this.router.navigate(['/admin/add-car-categories']);
  }
  editCar(user) {
    var id = user._id;
    this.router.navigate(['/admin/add-car-categories', { id }]);
  }


  updateStatus(list: any, status) {
    // console.log(list._id, status);
    if (status == 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {

        if (result.value) {
          let form = {
            "typeId": list._id,
            "status": status ? 0 : 1,
            remark: result.value
          }

          this.service.changeCarStatus(form).subscribe(
            (data: any) => {
              if (data.success) {
                this.getVehicles(this.currentPage)
                // console.log('status-----', data);
                this.snackBar.open(data.message, 'x', {
                  duration: 5000,
                })
              }
            }
          )

        }
        else {
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }

      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to change status",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {

        if (result.value == true) {
          let form = {
            "typeId": list._id,
            "status": status ? 0 : 1,
            remark: ''
          }

          this.service.changeCarStatus(form).subscribe(
            (data: any) => {
              if (data.success) {
                this.getVehicles(this.currentPage)
                // console.log('status-----', data);
                this.snackBar.open(data.message, 'x', {
                  duration: 5000,
                })
              }
            }
          )

        }
        else {
          //code for cacel

          console.log("cancel for  actssive user")
          this.ngOnInit()

        }

      })

    }

  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getVehicles(newPage)
  }



  deleteCarCategory(category) {
    //method to delete car cateogry
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to Delete this category ? ",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {

      if (result.value == true) {
        console.log(category._id)
        let body = {
          "adminId": this.adminId,
          "typeId": category._id,
          "isDeleted": true
        }

        this.service.deleteCarCategory(body).subscribe((res: any) => {
          console.log(res)
          if (res.success) {
            this.getVehicles(this.currentPage);
            this.snackBar.open(res.message, 'x', {
              duration: 5000,
            })

          } else {
            this.snackBar.open(res.message, 'x', {
              duration: 5000,
            })
          }
        })
      }
      else {
        console.log("nothing changed");

      }
    })

  }





}
