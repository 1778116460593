import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {

  form: FormGroup;
  adminId: string
  baseUrl: string
  pageName: string
  permissions: any;
  usersession: any;

  private formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.pageName = 'gdpr'
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser.adminType == 1 ? curruntUser._id : curruntUser.adminId
    console.log('id', this.adminId)

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));;
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).cms

    let form = {
      adminId: this.adminId,
      pageName: this.pageName
    }

    this.service.getStaticPages(form)
      .subscribe(
        (data: any) => {

          if (data.content) {
            this.form = this.fb.group({
              content: data.content
            });
          }
        })

    this.form = this.fb.group({
      content: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {

    console.log(this.form.value)
    if (this.form.valid) {

      let form = {
        adminId: this.adminId,
        pageName: this.pageName,
        content: this.form.value.content
      }

      this.service.saveStaticPages(form)
        .subscribe(
          (data: any) => {

            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })

          }
        )
    }
    this.formSubmitAttempt = true;
  }


}
