import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import { ChatService } from '../../../services/chat.service'

import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-merchants',
  templateUrl: './manage-merchants.component.html',
  styleUrls: ['./manage-merchants.component.scss'],
  providers: [ChatService]
})
export class ManageMerchantsComponent implements OnInit {
  merchantsList: any;
  field: any = '';
  keyword: any = '';
  status: any = '';
  adminId: any;
  uncheckedToggle: any;
  usersession: any;
  permissions: any;
  onload: boolean = false
  currentPage: Number;
  merchants: any
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  constructor(private route: ActivatedRoute,
    private chatService: ChatService,
    private router: Router,
    private service: ApiService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).merchant
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    this.getMerchantsListFromDb(this.currentPage);
  }

  goToAddMerchant() {
    this.router.navigate(['/admin/Add-merchant']);
  }


  getMerchantsListFromDb(current) {
    this.currentPage = current

    this.service.getMerchantsList(this.field, this.keyword, this.status, this.adminId, this.currentPage).subscribe((res) => {



      // console.log('merchant---', res);
      this.merchants = res
      this.merchantsList = this.merchants.stores
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: this.merchants.total


      }


    });

  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getMerchantsListFromDb(newPage)
  }
  resetFilter() {
    this.field = '';
    this.keyword = '';
    this.status = '';
    this.getMerchantsListFromDb(this.currentPage);
  }


  editMerchant(user) {
    // console.log("user----", user);
    var id = user._id;
    this.router.navigate(['/admin/Add-merchant', { id }]);
  }

  changeToggle(list: any, status) {
    
    if (status == 'Approved') {
      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.uncheckedToggle = true;


          // console.log(list._id, status);
          var newStatus;
          if (status == 'Unapproved') {
            newStatus = 'Approved';
          } else if (status == 'Approved') {
            newStatus = 'Unapproved';
          }
          // console.log(newStatus);
          let form = {
            'merchantId': list._id,
            'status': newStatus,
            'remark': result.value
          }

          this.chatService.merchantStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {

                  // console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()

                  }
                }
                else {
                  //code for cancel

                  console.log("cancel for  active user")
                  this.ngOnInit()

                }
              }
            )
        } else {
          console.log("no", status)
          this.uncheckedToggle = false;
          this.ngOnInit()
          console.log("uncheckedToggle", this.uncheckedToggle)
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to change status",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value == true) {
          this.uncheckedToggle = true;


          console.log(list._id, status);
          var newStatus;
          if (status == 'Unapproved') {
            newStatus = 'Approved';
          } else if (status == 'Approved') {
            newStatus = 'Unapproved';
          }
          console.log(newStatus);
          let form = {
            'merchantId': list._id,
            'status': newStatus,
            'remark': ''
          }

          this.chatService.merchantStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {

                  console.log('status-----', data);
                  if (data.success) {
                    Swal.fire(
                      'Status Changed Successfully!',
                      '',
                      'success'
                    )
                    this.ngOnInit()
                  }
                  // this.ngOnInit()
                }
                // this.snackBar.open(data.message, 'x', {
                //   duration: 5000,
                // })
              }
            )
        } else {
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }

      })
    }

  }
  viewActivityLog(stores) {
    var id = stores._id;
    //  console.log('gdhgdgdghg',id);
    this.router.navigate(['admin/ViewMerchant', { id }])
  }


  editDocuments(merchantId) {
    var id = merchantId._id;
    // console.log(id);
    this.router.navigate(['/admin/manage-merchant-documents', { id }])
  }
}
