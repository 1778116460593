import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  response: any
  formValid: boolean = false

  cookieValue: any;
  browserToken: any
  constructor(private http: HttpClient, private service: ApiService, private router: Router, private cookieService: CookieService
    , private toastr: ToastrService,
  ) {

  }
  ngOnInit() {
    if (localStorage.getItem('curruntUser')) {
      console.log("current", localStorage.getItem('curruntUser'))
      this.router.navigate(['/admin/admin-dashboard']);

    }

  }
  // updateToken(curruntUser, token) {
  //   // we can change this function to request our backend service
  //   this.angularFireAuth.authState.pipe(take(1)).subscribe(
  //     () => {
  //       const data = {};
  //       data[curruntUser] = token
  //       this.angularFireDB.object('fcmTokens/').update(data)
  //     })
  // }
  validateForm(loginForm: NgForm) {
    console.log("fgffjhkjjk", loginForm.value)
    if (loginForm.value.email == undefined || loginForm.value.email == "") {
      console.log("email ");

      return this.toastw("Email is required.")
    }

    if (loginForm.value.password == undefined || loginForm.value.password == "") {
      console.log("password");

      return this.toastw("Password is required")
    }


    this.formValid = true
  }
  toastw(mesg) {
    this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  }
  // requestPermission(curruntUser) {
  //   // console.log("re per fired");

  //   this.angularFireMessaging.requestToken.subscribe(
  //     (token) => {
  //       // console.log("tokenndd",token);
  //       this.browserToken= token
  //       // console.log('abc',this.browserToken)
  //       this.updateToken(curruntUser, token);
  //     },
  //     (err) => {
  //       // console.error('Unable to get permission to notify.', err);
  //     }
  //   );
  // }


  Login(loginForm: NgForm) {
    //learn remember true
    if (this.formValid) {
      loginForm.value.deviceToken = JSON.parse(localStorage.getItem('deviceToken'))
      // console.log('a',loginForm.value);

      this.service.login(loginForm.value).subscribe((res) => {

        sessionStorage.setItem('x-auth', "1")

        if (res['success'] == true) {
          // console.log(res['user']);
          localStorage.setItem("curruntUser", JSON.stringify(res['customer']));

          // if(loginForm.value.remember){

          // }

          // console.log(loginForm.value.remember);
          if (loginForm.value.remember == true) {
            localStorage.setItem("rememberUser", '1');
            // this.cookieService.set( 'rememberUser', JSON.stringify(res['customer']));


          }

          this.router.navigate(['/admin/admin-dashboard']);

        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            confirmButtonColor: '#821727',
            text: res['message'],
          })
        }
      })
    }
  }
  isString(value) {
    return typeof value === 'object'
  }
  onsubmit() {
    this.router.navigate(['/admin/admin-dashboard']);
  }

  forgotPassword() {
    this.router.navigate(['/admin/forgot-password']);
  }


}
