import { BrowserModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { NumberOnlyDirective } from '../app/Pages/directives/number-only/number-only.directive';
import { DecimalnumberDirective } from '../app/Pages/directives/decimal-only/decimalnumber.directive';
import { CharacterOnlyDirective } from '../app/Pages/directives/character-only/character-only.directive';
// import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgmDirectionModule } from 'agm-direction';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { NgxDocViewerModule } from "ngx-doc-viewer";


import { AdminComponent } from './Pages/admin/admin.component';
import { LoginComponent } from './Pages/admin/login/login.component';
import { AdminDashboardComponent } from './Pages/admin/admin-dashboard/admin-dashboard.component';
import { ManageUserComponent } from './Pages/admin/manage-user/manage-user.component';
import { ManageMerchantsComponent } from './Pages/admin/manage-merchants/manage-merchants.component';
import { ManageDriversComponent } from './Pages/admin/manage-drivers/manage-drivers.component';
import { ManageCarCategoryComponent } from './Pages/admin/manage-car-category/manage-car-category.component';
import { PaymentComponent } from './Pages/admin/payment/payment.component';
import { AdminBookingComponent } from './Pages/admin/admin-booking/admin-booking.component';
import { AddCarCategoriesComponent } from './Pages/admin/add-car-categories/add-car-categories.component';
import { ManageAddUserComponent } from './Pages/admin/manage-add-user/manage-add-user.component';
import { AddMerchantComponent } from './Pages/admin/add-merchant/add-merchant.component';
import { AddDriversComponent } from './Pages/admin/add-drivers/add-drivers.component';
import { GeofencingComponent } from './Pages/admin/geofencing/geofencing.component';
import { SidebarAdminComponent } from './components/sidebar-admin/sidebar-admin.component';
import { ReviewComponent } from './Pages/admin/review/review.component';
import { NotificationComponent } from './Pages/admin/notification/notification.component';
import { NavbarAdminComponent } from './components/navbar-admin/navbar-admin.component';
import { AnalyticsComponent } from './Pages/admin/analytics/analytics.component';

import { PaginationComponent } from './components/pagination/pagination.component';
import { DocumentComponent } from './Pages/admin/document/document.component';
import { ManageDriverAdminComponent } from './Pages/admin/manage-driver-admin/manage-driver-admin.component';
import { ManageDeliveryAdminComponent } from './Pages/admin/manage-delivery-admin/manage-delivery-admin.component';
import { AddDriversAdminComponent } from './Pages/admin/add-drivers-admin/add-drivers-admin.component';
import { AddDeliveryAdminComponent } from './Pages/admin/add-delivery-admin/add-delivery-admin.component';
import { ManageMerchantDocumentsComponent } from './Pages/admin/manage-merchant-documents/manage-merchant-documents.component';
import { AddCouponComponent } from './Pages/admin/add-coupon/add-coupon.component';
import { DiscountCouponComponent } from './Pages/admin/discount-coupon/discount-coupon.component';

import { CookieService } from 'ngx-cookie-service';

import { FusionChartsModule } from 'angular-fusioncharts';
// Load FusionCharts
import * as FusionCharts from 'fusioncharts';
// Load Charts module
import * as Charts from 'fusioncharts/fusioncharts.charts';
// Load fusion theme
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { ForgotPasswordComponent } from './Pages/admin/forgot-password/forgot-password.component';
import { ViewUserActivityComponent } from './Pages/admin/view-user-activity/view-user-activity.component';
import { TrackingComponent } from './Pages/admin/tracking/tracking.component';
import * as firebase from 'firebase';
// import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { VacantDriversListComponent } from './Pages/admin/vacant-drivers-list/vacant-drivers-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { from } from 'rxjs';
// import { PdfViewerModule } from "ng2-pdf-viewer";
import { SubadminComponent } from './Pages/admin/subadmin/subadmin.component';
import { AddSubAdminComponent } from './Pages/admin/add-sub-admin/add-sub-admin.component';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { EditSubAdminComponent } from './Pages/admin/edit-sub-admin/edit-sub-admin.component';
import { CreateBookingComponent } from './Pages/admin/create-booking/create-booking.component';
import { ViewMerchantDashboardComponent } from './Pages/admin/view-merchant-dashboard/view-merchant-dashboard.component';
import { SettingComponent } from './Pages/admin/setting/setting.component';
import { UsertermComponent } from './Pages/admin/userterm/userterm.component';
import { UserpolicyComponent } from './Pages/admin/userpolicy/userpolicy.component';
import { DriverpolicyComponent } from './Pages/admin/driverpolicy/driverpolicy.component';
import { DrivertermComponent } from './Pages/admin/driverterm/driverterm.component';
import { AboutusComponent } from './Pages/admin/aboutus/aboutus.component';
import { FaqComponent } from './Pages/admin/faq/faq.component';
import { AddFaqComponent } from './Pages/admin/add-faq/add-faq.component';

//options for text editor
// import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
// import { EditorModule } from '@tinymce/tinymce-angular';

import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ManageReviewComponent } from './Pages/admin/manage-review/manage-review.component';
import { FilterPipe } from 'src/filter.pipe';
import { ManageDriverDocumentComponent } from './Pages/admin/manage-driver-document/manage-driver-document.component';
import { ScheduledComponent } from './Pages/admin/scheduled/scheduled.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReviewsComponent } from './Pages/admin/reviews/reviews.component';
import { DriverPaymentComponent } from './Pages/admin/driver-payment/driver-payment.component';
import { ManageRevenueComponent } from './Pages/admin/manage-revenue/manage-revenue.component';
import { PendingBookingComponent } from './Pages/admin/pending-booking/pending-booking.component'; // <-- import the module
// import { AgmOverlays } from "agm-overlays";
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { EditGeofenceComponent } from './Pages/edit-geofence/edit-geofence.component';
import { CarBookingComponent } from './Pages/car-booking/car-booking.component';
import { P404Component } from './Pages/admin/p404/p404.component';
import { DriverLocationComponent } from './Pages/admin/manage-drivers/driver-location/driver-location.component';
import { CodComponent } from './Pages/admin/cod/cod.component';
import { PerformanceComponent } from './Pages/performance/performance.component';
import { InvoiceComponent } from './Pages/invoice/invoice.component';
import { InvoicePdfComponent } from './Pages/admin/invoice-pdf/invoice-pdf.component';
import { GdprComponent } from './Pages/admin/gdpr/gdpr.component'; // this line you need
import { QueriesComponent } from './Pages/admin/queries/queries.component';
import { PrivacyComponent } from './Pages/privacy/privacy.component';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';

// var firebaseConfig = {
//   apiKey: "AIzaSyAOwg0nC25I6UoaFr_3Es6vO6ug9sR9pPI",
//   authDomain: "viplimosuperadmin.firebaseapp.com",
//   databaseURL: "https://viplimosuperadmin.firebaseio.com",
//   projectId: "viplimosuperadmin",
//   storageBucket: "",
//   messagingSenderId: "200867171190",
//   appId: "1:200867171190:web:5cfeca0653bf135a",
//   vapidKey:'BHeAHr8TsmSUAErBRFQ_h0Miz08naofGVGn3g9y6T-gdF5ukCxaUchRZoioLqwuxByfXvOl6vmw_WIVKDBLbgIM'
// };
// Initialize Firebase

FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme)
// firebase.initializeApp(firebaseConfig)
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    FilterPipe,
    NumberOnlyDirective,
    DecimalnumberDirective,
    CharacterOnlyDirective,
    AdminDashboardComponent,
    ManageUserComponent,
    ManageMerchantsComponent,
    ManageDriversComponent,
    ManageCarCategoryComponent,
    PaymentComponent,
    AdminBookingComponent,
    AddCarCategoriesComponent,
    ManageAddUserComponent,
    AddMerchantComponent,
    AddDriversComponent,
    GeofencingComponent,
    SidebarAdminComponent,
    ReviewComponent,
    NotificationComponent,
    NavbarAdminComponent,
    AnalyticsComponent,
    PaginationComponent,
    DocumentComponent,
    ManageDriverAdminComponent,
    ManageDeliveryAdminComponent,
    AddDriversAdminComponent,
    AddDeliveryAdminComponent,
    ManageMerchantDocumentsComponent,
    AddCouponComponent,
    // CommonModule,

    DiscountCouponComponent,
    ForgotPasswordComponent,
    ViewUserActivityComponent,
    TrackingComponent,
    VacantDriversListComponent,
    SubadminComponent,
    AddSubAdminComponent,

    EditSubAdminComponent,

    CreateBookingComponent,

    ViewMerchantDashboardComponent,

    SettingComponent,
    UsertermComponent,

    UserpolicyComponent,

    DriverpolicyComponent,

    DrivertermComponent,

    AboutusComponent,

    FaqComponent,

    AddFaqComponent,

    QueriesComponent,

    ManageReviewComponent,

    ManageDriverDocumentComponent,

    ScheduledComponent,

    ReviewsComponent,

    DriverPaymentComponent,

    ManageRevenueComponent,

    PendingBookingComponent,

    EditGeofenceComponent,

    CarBookingComponent,

    P404Component,

    DriverLocationComponent,

    CodComponent,

    PerformanceComponent,

    InvoiceComponent,

    InvoicePdfComponent,

    GdprComponent,

    PrivacyComponent,

  ],
  // RichTextEditorAllModule
  imports: [BrowserModule, OwlDateTimeModule, RichTextEditorAllModule,
    OwlNativeDateTimeModule, AmazingTimePickerModule, AppRoutingModule,
    FormsModule, ReactiveFormsModule, HttpClientModule, NgxPaginationModule,
    // AngularFireModule.initializeApp(firebaseConfig),
    NgMultiSelectDropDownModule.forRoot(),
    // AngularFireMessagingModule,
    AgmOverlays,
    NgxDocViewerModule,
    SelectDropDownModule, AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBEACOIxlaj4MrN9-XcAHFQctWCfe_gYN8',

      libraries: ['places', 'geometry', 'drawing'],

    }),

    GooglePlaceModule,
    ToastrModule.forRoot(),
    MaterialModule,
    FusionChartsModule,
    BrowserAnimationsModule
  ],
  providers: [
    // AuthGuard, AuthService, GoogleMapsAPIWrapper,
    CookieService,
    // MessagingService, 
    // ,AsyncPipe,
    //  AngularFireDatabase, AngularFireAuth,
    { provide: APP_BASE_HREF, useValue: '' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [
    NumberOnlyDirective,
    DecimalnumberDirective
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
