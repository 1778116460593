import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  form: FormGroup;
  adminId: string
  faqId: string
  title:string = 'Add FAQ'
  pageName = 'About Us'
  usersession: any;
  permissions: any;

  private formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.usersession  = JSON.parse(localStorage.getItem('curruntUser'));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
   console.log('id',this.adminId)
    this.route.params.subscribe(params => {
      this.faqId = params['id']
    });

    if (this.faqId != 'add') {
      this.service.getFaqById(this.faqId)
        .subscribe(
          (data: any) => {
            if (data.faq) {
              this.title = 'Edit FAQ'
              this.form = this.fb.group({
                content: data.faq.content,
                title: data.faq.title
              });
            } else {
              this.faqId = 'add'
            }
          })
    }

    this.form = this.fb.group({
      content: ['', Validators.required],
      title: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {

    console.log(this.form.value)
    if (this.form.valid) {

      if (this.faqId == 'add') {

        let form = {
          adminId: this.adminId,
          title: this.form.value.title,
          content: this.form.value.content
        }

        this.service.addFaq(form)
          .subscribe(
            (data: any) => {

              this.snackBar.open(data.message, 'x', {
                duration: 5000,
              })
              this.router.navigate(['/admin/faq']);
            }
          )
      } else {

        this.form.value.updateId = this.faqId

        this.service.editFaq(this.form.value)
          .subscribe(
            (data: any) => {

              this.snackBar.open(data.message, 'x', {
                duration: 5000,
              })
              this.router.navigate(['/admin/faq']);
            }
          )
      }
    }
    this.formSubmitAttempt = true;
  }

  OnDashClick(){
    this.router.navigate(['/dashboard']);
  }
  
  goTofaq(){
    this.router.navigate(['/admin/faq']);
  }

}
