import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import { ToastrService } from 'ngx-toastr'
import Swal from 'sweetalert2';
import { ChatService } from '../../../services/chat.service';
import { User } from 'firebase';
import * as moment from 'moment';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'app-pending-booking',
  templateUrl: './pending-booking.component.html',
  styleUrls: ['./pending-booking.component.scss'],
  providers: [ChatService]

})
export class PendingBookingComponent implements OnInit {
  adminId: any;
  bookingId: any
  driverId: any
  booking: any
  currentPage: Number;
  latitude: any
  longitude: any
  data: {}
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }

  vehicletype: any
  source: any
  status: any;
  usersession: any;
  permissions: any;
  onload: boolean = false
  bookings: any
  drivers: any
  gotData: boolean;

  constructor(
    private _services: ApiService,
    private chatService: ChatService,

    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }


  ngOnInit() {
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));


    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).pendingBooking
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    this.getBookingList(this.currentPage);
  }
  getBookingList(current) {
    this.currentPage = current;
    this._services.getPendingbookings({ page: current }).subscribe((res: any) => {
      this.bookings = res.booking;
      this.checkDate(res.bookings)
      this.latitude = this.bookings.source,
        this.longitude = this.bookings.source
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: res.total


      }
    })
  }

  checkDate(bookings) {

    let temp = this.bookings
    temp.forEach(element => {
      let today = moment(new Date()).format('DD/MM/YYYY')
      let bookingdate = moment(element.date).format('DD/MM/YYYY')
      console.log(today, "/", bookingdate)

      if (bookingdate === today) {
        let assignDriverflag = true;
        element['assignDriverFlag'] = assignDriverflag
      } else {
        let assignDriverflag = false;
        element['assignDriverFlag'] = assignDriverflag

      }
    });
    this.bookings = temp
  }
  onChangedriver(source, bookingId, ride, vehicletype) {
    this.onload = true
    this.booking = ride
    console.log("this.booking", this.booking)
    console.log("source", source)
    this.vehicletype = vehicletype
    this.bookingId = bookingId
    this.latitude = source.latitude
    this.longitude = source.longitude
    console.log('latitude', this.latitude)
    console.log('longitude', this.longitude)
    console.log('khlkjh', this.bookings)

    this._services.getdriverslist(this.latitude, this.longitude, this.vehicletype).subscribe((res: any) => {
      if (res.booking.length > 0) {
        this.gotData = true;
        this.drivers = res.booking;
      } else {
        this.gotData = false
        this.drivers = []
        // this.drivers.push({ 'firstName': 'No driver avialable', 'lastName': 'nearby' })
      }
      console.log("drivers", this.drivers)
    })
  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getBookingList(newPage)
  }

  assignDriver(type) {
    console.log("dObtypej", type)
    const dObj = this.drivers.filter(val => val._id.toString() == type.toString());

    var data = {

      ...this.booking,
      "driverId": type

    }
    console.log("data", data)
    this.chatService.pendingBooking(data)
      .subscribe((message: any) => {
        this.toastr.success(message.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
        // window.location.reload()
        this.getBookingList(this.currentPage)
      });
  }

}

