import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { ChatService } from '../../../services/chat.service'

@Component({
  selector: 'app-manage-driver-admin',
  templateUrl: './manage-driver-admin.component.html',
  styleUrls: ['./manage-driver-admin.component.scss'],
  providers: [ChatService]

})
export class ManageDriverAdminComponent implements OnInit {

  adminList: any;
  page = 1;
  adminId: string
  adminType: number
  skip: number = 0
  limit: number = 10
  search: string = ''
  field: any = '';
  keyword: any = '';
  status: any = '';
  permissions: any;
  usersession: any;
  onload: boolean = false
  currentPage: Number;
  admin: any
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }

  constructor(private route: ActivatedRoute,
    private chatService: ChatService,
    private router: Router, 
    private service: ApiService,
     public snackBar: MatSnackBar, ) { }

  ngOnInit(): void {
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));


    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).driveradmin
    this.currentPage = 1
    this.getAdminListFromDb(this.currentPage);

    console.log('hello', this.currentPage)
  }

  goToAddUser() {
    this.router.navigate(['admin/add-drivers-admin']);
  }


  getAdminListFromDb(current) {
    this.currentPage = current;
    this.service.getAllDriverAdminList(this.field, this.keyword, this.status, this.currentPage).subscribe((res) => {
      this.admin = res;
      this.adminList = this.admin.admin
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: this.admin.total


      }
    });

  }



  resetFilter() {
    this.field = '';
    this.keyword = '';
    this.status = '';
    this.getAdminListFromDb(this.currentPage);
  }


  editUser(user) {
    var id = user._id;
    this.router.navigate(['/admin/add-drivers-admin', { id }]);
  }


  changeToggle(list: any, status) {
    if (status == 1) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      }).then((result) => {

        if (result.value) {
          console.log(list._id, status,result.value);
          let form = {
            adminId: list._id,
            status: status ? 0 : 1,
            remark: result.value
          }

          this.chatService.driverStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
                  Swal.fire(
                    'Status Changed Successfully!',
                    '',
                    'success'
                  )
                  this.ngOnInit()
                }

              }
            )

        }
        else {
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to change status",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false,



      }).then((result) => {

        if (result.value == true) {

          console.log(list._id, status);
          let form = {
            adminId: list._id,
            status: status ? 0 : 1,
            remark: ''
          }

          this.chatService.driverStatus(form)
            .subscribe(
              (data: any) => {
                if (data.success) {
                  Swal.fire(
                    'Status Changed Successfully!',
                    '',
                    'success'
                  )
                  this.ngOnInit()
                }
              }

            )


        }

        else {
          console.log("gjhffjhfhjg")
          this.ngOnInit()
        }
      })
    }
}
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getAdminListFromDb(newPage)
  }
  editDocuments(user) {
    var id = user._id;
    this.router.navigate(['/admin/manage-driver-documents', { id }])
  }

  

      
}

