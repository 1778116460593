import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import{ ToastrService} from 'ngx-toastr'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-review',
  templateUrl: './manage-review.component.html',
  styleUrls: ['./manage-review.component.scss']
})
export class ManageReviewComponent implements OnInit {
  adminId: any;
  currentPage:Number;
  reviewId:any
  rating:any
  data:{}
  review:any
  config:any ={
    id:"page",
    currentPage:1,
          itemsPerPage:10,
  }
  status:any;
  usersession: any;
  permissions: any;
  onload: boolean = false
  bookings:any

  constructor(
    private _services: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }


  ngOnInit() {
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).review
     localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage=1 
    this.getBookingList(this.currentPage);




   
   
    
  }
  getBookingList(current) {
    this.currentPage=current;
    this._services.getreview({page:current}).subscribe((res:any) => {
     this.bookings = res.booking;
        this.onload=true
        this.config={
          id:"page",
          currentPage:this.currentPage,
          itemsPerPage:10,
          totalItems:res.total
          
         
         }
    })
  }
  pageChange(newPage: number){
    console.log("newPage",newPage)
    this.getBookingList(newPage)
    }
    async edituser(userRate)
 
    {
      console.log("userRate",userRate)
      this.reviewId = userRate._id;
      this.rating=userRate.rating
      this.review=userRate.review
     
      let obj: any = {

        required: true,
        autocapitalize: 'off'
      }
Swal.fire({
        title: 'Are you sure to change review/rating ?',
        // input: 'text',
        html:
        `Review<input id="swal-input1" class="swal2-input" value="${userRate.review}" >` ,
        // `Rating<input id="swal-input2" class="swal2-input" value="${userRate.rating}">`,
        inputPlaceholder: 'please remark',
        inputAttributes: obj,
        showCancelButton: true,
        
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
        allowOutsideClick: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1'),
            // document.getElementById('swal-input2'),
          ]
        }
      })
      .then(result => {
        if (result && result.value) {
          
          this.review = result.value[0].value;
          // this.rating = result.value[1].value;
        
      
      console.log("result",result)
      let  data = {
        'reviewId':this.reviewId,
    'rating':this.rating,
    'review':this.review
  }
      this._services.changereview(data)
      .subscribe(
        (data: any) => {
          console.log("data",data)
              if (data.success) {
                Swal.fire(
                  ' Successfully!',
                  '',
                  'success'
                )
                this.getBookingList(this.currentPage);
                // this.booking();
              }
              else
              {  
                this.toastr.success(data.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
              }
              

            
            })
        
        }
      })
    }
 editdriver(driverRate)
  
   
       {
        console.log("userRate",driverRate)
        this.reviewId = driverRate._id;
        this.review=driverRate.review
        this.rating=driverRate.rating
        let obj: any = {
          required: true,
          autocapitalize: 'off'
        }
   Swal.fire({
          title: 'Are you sure to change review ?',
          html:
        `Review<input id="swal-input1" class="swal2-input" value="${driverRate.review}">`,
        // `Rating<input id="swal-input2" class="swal2-input"value="${driverRate.rating}">`,
          inputPlaceholder: 'please remark',
          inputAttributes: obj,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update',
          allowOutsideClick: false, preConfirm: () => {
            return [
              document.getElementById('swal-input1'),
              // document.getElementById('swal-input2'),
            ]
          }
        })
        .then(result => {
          if (result && result.value) {
            
            this.review = result.value[0].value;
            // this.rating = result.value[1].value;
          
        
        console.log("result",result)
        let  data = {
          'reviewId':this.reviewId,
      'rating':this.rating,
      'review':this.review
    }
      
          this._services.changedriverreview(data)
            .subscribe(
              (data: any) => {
                if (data.success) {
                  Swal.fire(
                    'Successfully!',
                    '',
                    'success'
                  )
                 
                  this.getBookingList(this.currentPage);
                  // this.booking();
                }
                else
                {  
                  this.toastr.success(data.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
                }
                
  
                }
              
            )
         }
        })
      }
}
