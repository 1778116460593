import { Component, OnInit } from '@angular/core';
import { AngularCsv } from 'angular7-csv';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  keyword: any;
  bookType: any = 'All';

  adminId: any;
  limit: any
  paymentData: any = [];
  page = 1;
  admin: any
  onload: boolean = false
  currentPage: Number;
  refundPercent: number
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  // bookType: '';

  constructor(private service: ApiService, private router: Router, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    this.getPaymentList(this.currentPage);
    // this.resetFilter();
  }
  searchFilter() {
    // console.log(this.keyword);
    // console.log(this.field);
    this.getPaymentList(this.currentPage);
  }
  resetFilter() {
    this.keyword = '';
    this.bookType = 'All';
    this.getPaymentList(this.currentPage);
  }
  getPaymentList(current) {
    this.currentPage = current;
    this.service.getData({ page: current, limit: this.limit, bookType: this.bookType }).subscribe((res) => {

      console.log(res);
      this.onload = true

      this.admin = res
      this.refundPercent = res['refundPercent']
      this.refundAmount(this.admin.data);
      // this.paymentData = this.admin.data
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: this.admin.total


      }
    });
  }


  refundAmount(data) {

    let tax = 30
    data.forEach(element => {
      if (element.refund) {
        let refundableAmount = element.fare * (this.refundPercent / 100)
        element.refund.refundAmount = refundableAmount
      }
    });

    this.paymentData = data
  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getPaymentList(newPage)
  }
  exportCSV() {
    // this.=current;
    this.service.getData({ page: 1, limit: 0, bookType: this.bookType }).subscribe((res) => {

      console.log(res);
      this.onload = true

      this.admin = res
      this.paymentData = this.admin.data
      this.onload = true
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: ["Driver Name (Average Rate)", "Rider Name", "Rate", "Date", "Comment"]
      };
      var data = [];
      this.paymentData.forEach(element => {
        element.createdAt = moment(element.createdAt).format('ll');
        data.push({
          drivername: element.driverId.firstName + " " + element.driverId.lastName,
          ridername: element.userId ? element.userId.firstName + "" + element.userId.lastName : element.firstName + "" + element.lastName,
          raste: element.fare,
          date: element.createdAt,
          note: element.note
        })
      });

      // var data = [
      //   {
      //     name: "Test 1",
      //     age: 13,
      //     average: 8.2,
      //     approved: true,
      //     description: "using 'Content here, content here' "
      //   },
      //   {
      //     name: 'Test 2',
      //     age: 11,
      //     average: 8.2,
      //     approved: true,
      //     description: "using 'Content here, content here' "
      //   },
      //   {
      //     name: 'Test 4',
      //     age: 10,
      //     average: 8.2,
      //     approved: true,
      //     description: "using 'Content here, content here' "
      //   },
      // ];

      new AngularCsv(data, 'Payment report', options);
    })
  }

  checkClick(Id, amount) {

    //Method to approve refund request
    console.log("Clicked On :-", Id)

    let form = {

      "bookingId": Id,
      "refundAmount": amount,
      "status": "approved",
      "reason": "Refund request is approved. Refund amound is added to the wallet"

    }
    this.service.refundResponse(form).subscribe(res => {
      console.log(res);
      this.snackBar.open('Refund approved', 'x', {
        duration: 5000,
      });
      this.getPaymentList(this.currentPage);
    })
  }

  crossClick(id, amount) {


    Swal.fire({
      title: 'Please enter the reason?',
      text: "Please remark",
      type: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
    }).then((result) => {

      if (result.value) {




        let form = {

          "bookingId": id,
          "refundAmount": amount,
          "status": "disapproved",
          "reason": result.value

        }
        this.service.refundResponse(form).subscribe(res => {
          console.log(res);
          this.snackBar.open(res.messages, 'x', {
            duration: 5000,
          });
          this.getPaymentList(this.currentPage);
        })

        //Method to decline refund request
      } else {
        this.snackBar.open('Please enter a reason', 'x', {
          duration: 5000,
        });
      }

    })
  }
}