import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar, MatInput } from '@angular/material';
import * as moment from 'moment';
import { AngularCsv } from 'angular7-csv';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

declare const google: any;
@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.scss']
})
export class AdminBookingComponent implements OnInit {
  startDate: any;
  endDate: any;
  driveradmin: any
  keyword: string;
  bookings = [];
  storeId: string
  adminState: string
  bookingList: any;
  query: string
  search: any;
  store: string
  selected: true
  field: any = '';
  adminId: any;
  readioSelected: any = '';

  currentPage: Number;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 25,
  }
  status: any = "All";
  usersession: any;
  permissions: any;
  onload: boolean = false

  @ViewChild('enddate', {
    read: MatInput
  }) enddate: MatInput;
  @ViewChild('startdate', {
    read: MatInput
  }) startdate: MatInput;
  csvList: any;
  constructor(
    private _services: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).booking
    console.log(this.permissions, "permissionss")
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    this.searchFilter(this.currentPage);
  }

  getStartDate(ev) {
    this.startDate = moment(ev.value).startOf('day').valueOf();
    // console.log('Startdate', this.startDate)
  }

  getEndDate(ev) {
    console.log("DATE", ev)
    this.endDate = moment(ev.value).endOf('day').valueOf();
    // console.log('enddate', this.endDate)
  }

  searchFilter(page) {
    let data =
    {
      page: page
    };
    if (this.status) {
      data['status'] = this.status;
    }
    if (this.startDate) {
      data['startdate'] = this.startDate;
    }
    if (this.endDate) {
      data['enddate'] = this.endDate;
    }
    if (this.search) {
      data['search'] = this.search.trim();
    }
    if (this.field) {
      data['field'] = this.field;
    }
    this._services.filterBookingListFromDb(data).subscribe((res: any) => {

      this.bookings = res.booking;
      if (this.bookings.length) {
        this.bookings.forEach(ele => {
          ele.radioVal = null;
        });
        this.bookings.forEach(ele => {
          var endtime = moment(ele.tripEndTime)
          var starttime = moment(ele.tripStartTime)
          // ele.tripStartTime = starttime.format()
          var miniutes = endtime.diff(starttime, 'minutes')
          var hours = endtime.diff(starttime, 'hours') // 745
          if (miniutes >= 60) {
            miniutes = miniutes - (hours * 60);
          }
          ele.adt = hours + 'hour' + ' ' + miniutes + '' + 'Minutes'
          var time = ele.tripStartTime - ele.timearrived
          // var min = Math.floor((time/1000/60) << 0);
          var min = Math.floor(time / 60000);

          // var min = ele.timearrived.diff(ele.tripStartTime, 'minutes') 
          ele.WaitTime = min
          console.log(min, time)
          //  adt = hours + miniutes
          //  console.log(adt,element.tripEndTime.diff(moment(starttime), 'minutes') ,"adttt")
          // var duration = moment.duration(element.tripEndTime.diff(element.tripStartTime));
          // var hours = duration.asHours();
          // ele.adt =  ele.tripEndTime.getMoment().diff(ele.tripStartTime).getMoment(), 'days';
          console.log(miniutes, hours, "adttt")
        });
      }
      // this.driveradmin=res.bookings.driverId.driverAdmin
      this.onload = true
      this.config = {
        id: "page",
        currentPage: page,
        itemsPerPage: 25,
        totalItems: res.total
      }

    });
  }

  resetFilter() {
    this.field = '';
    this.keyword = '';
    this.status = '';
    this.startDate = '',
      this.endDate = '',
      this.startdate.value = null;
    this.enddate.value = null;
    this.searchFilter(this.currentPage);

  }

  pageChange(newPage: number) {
    console.log("newPage", newPage)
    // this.getBookingList(newPage)
    this.searchFilter(newPage)
  }


  exportCSV() {
    let data =
    {
      page: 1
    };
    if (this.status) {
      data['status'] = this.status;
    }
    if (this.startDate) {
      data['startdate'] = this.startDate;
    }
    if (this.endDate) {
      data['enddate'] = this.endDate;
    }
    if (this.search) {
      data['search'] = this.search;
    }
    if (this.field) {
      data['field'] = this.field;
    }

    this._services.getCsvBookingList(data).subscribe((res: any) => {
      console.log(res);
      this.csvList = res.booking
      // this.driversList = this.drivers.driver
      this.onload = true
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: ["Day", "Date", "Order ID", "User Name", "Number", "Name", "Rider ID", "Driver Name", "Driver Number", "Driver Type",
          "Category", "Source", "Destination", "Distance", "Fare", "Amount", "Intinal Payment", "Payment",
          "Time Placed", "Accepted", "Time Arrived", "Time Picked", "Time Delivered", "ADT", "Wait Time", "user Rating", "Driver Rating", "Status", "Action"]
      };
      var data = [];
      var i = 1;
      this.csvList.forEach(element => {
        let payment = ''
        let Status = ''
        let adt = '';
        let waitTime
        let endtime
        let starttime
        let hours
        let miniutes
        if (element.tripEndTime || element.tripStartTime) {
          endtime = moment(element.tripEndTime)
          starttime = moment(element.tripStartTime)
          miniutes = endtime.diff(starttime, 'minutes')
          hours = endtime.diff(starttime, 'hours') // 745
          if (miniutes >= 60) {
            miniutes = miniutes - (hours * 60);
          }
          adt = hours + 'hour' + '' + miniutes + '' + 'Minutes'
          console.log(adt, "adttt")
          // var duration = moment.duration(element.tripEndTime.diff(element.tripStartTime));
          // var hours = duration.asHours();
          // console.log((element.tripEndTime.getMoment().diff(element.tripStartTime).getMoment(), 'days'),'=====')

        }
        if (element.tripStartTime - element.timearrived) {
          var time = element.tripStartTime - element.timearrived
          // var min = Math.floor((time/1000/60) << 0);
          let min = Math.floor(time / 60000);
          waitTime = min
        }
        if (element.isPaidInitial == 0 && element.status >= 3) {
          switch (Number(element.isPaid)) {
            case 0: payment = "customer not available";
              break;
            case 1: payment = "paid";
              break;
            case 2: payment = " customer return product"
              break;
            case 3: payment = 'Product Delivered but not paid'
          }
        }
        if (element.isPaidInitial == 1 && element.status >= 3) {
          switch (Number(element.isPaid)) {
            case 0: payment = "customer not available";
              break;
            case 1: payment = "paid";
              break;
            case 2: payment = " customer return product"
          }
        }
        if (element.driverId) {
          if (element.status >= 4 && element.status != 11) {
            status = "Completed"
          }
          switch (Number(element.status)) {
            case 0: status = "on the way";
              break;
            case 1: status = "Arrived at source";
              break;
            case 2: status = " On trip";
              break;
            case 3: status = 'Payment pending';
              break;
            case 11: status = 'Cancelled'
          }
        }
        if (!element.driverId) {
          switch (Number(element.status)) {
            case 0: status = "Pending";
              break;
            case 11: status = 'Cancelled'
          }
        }
        element.createdAt = moment(element.createdAt).format('ll');
        data.push({
          // SRno:i++,
          day: moment(element.date).format("dddd"),
          date: moment(element.date).format("DD MMM YYYY"),
          orderid: element.OrderNo,
          username: element.firstName + " " + element.lastName,
          Phonenumber: !element.phone ? element.userId.countryCode + " " + element.userId.phone : element.CountryCode + " " + element.phone,
          //  Phonenumber:element.countryCode + "" + element.phone,
          merchantname: element.storeId ? element.storeId.storeName : '',
          riderId: element.driverId ? element.driverId.riderId : '',
          drivername: element.driverId.firstName + " " + element.driverId.lastName,
          drivernumber: element.driverId.countryCode + " " + element.driverId.phone,
          drivertype: element.driverId.driverAdmin ? element.driverId.driverAdmin.companyName : 'InHouse',
          category: element.vehicleType && (element.vehicleType.type == 0) ? 'Driver' : 'Rider',
          source: element.source.name,
          Destination: element.destination.name,
          Distance: element.distance + "Miles",
          fare: element.fare,
          productamount: element.itemAmount,
          intinalpayment: element.isPaidInitial == 0 ? 'COD' : 'Online',
          payment: payment,
          timeplaced: moment(element.date).format("DD MMM YYYY hh:mm a"),
          accepted: moment(element.date).format("DD MMM YYYY  hh:mm a"),
          timearrived: moment(element.timearrived).format("DD MMM YYYY hh:mm a"),
          timepicked: moment(element.tripStartTime).format("DD MMM YYYY hh:mm a"),
          timedelivered: moment(element.tripEndTime).format("DD MMM YYYY hh:mm a"),
          adt: adt,
          waitTime: waitTime,
          userrating: element.userRate ? element.userRate.rating : 'No Rating',
          driverrating: element.driverRate ? element.driverRate.rating : 'No Rating',
          // shiftStartTime: element.shiftStartTime.hour +"" +":" +element.shiftStartTime.minute +"-" + element.shiftEndTime.hour +"" +":" +element.shiftEndTime.minute,
          Status: status,
          Action: ''
          // ridername : element.userId ? element.userId.firstName +"" +element.userId.lastName : element.firstName  +"" +element.lastName, 

        })
      });

      new AngularCsv(data, 'Booking  report', options);
    })
  }





  getBookingList(current) {
    this.currentPage = current;
    this._services.getBookingListFromDb({ page: current }).subscribe((res: any) => {
      this.bookings = res.booking;
      console.log("booking data", this.bookings);

      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 25,
        totalItems: res.total
      }
    })
  }

  changeBookingStatus(item, flag) {
    console.log(item);
    if (flag == true) {
      if (!item.radioVal) {
        return this.toastr.warning('please select type');
      }
    }

    let obj = { status: parseInt(item.status) + 1, isPaid: item.radioVal, bookingId: item._id }
    console.log("data", obj)
    this._services.changeBookingStatusFromDb(obj).subscribe((res) => {
      console.log("data", obj)
      if (res['success'] == true) {
        this.searchFilter(this.currentPage);
      }


    });
  }
  async changeBookingStatuscancel(item: any) {
    if (item.status == 0 || item.status == 1) {
      console.log("item.status", item.status)
      let obj: any = {
        required: true,
        autocapitalize: 'off'
      }
      const { value: text } = await Swal.fire({
        title: 'Are you sure to cancel trip?',
        input: 'text',
        inputPlaceholder: 'please remark',
        inputAttributes: obj,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      })

      if (text) {
        // console.log(list._id, status);
        let form = {
          bookingId: item._id,
          status: 11,
          remark: text
        }
        this._services.changeBookingStatusFromDb(form)
          .subscribe(
            (data: any) => {
              if (data.success) {
                Swal.fire(
                  'cancelled!',
                  '',
                  'success'
                )
                this.ngOnInit();
                this.searchFilter(this.currentPage);
                // this.booking();
              }
              else {
                this.toastr.success(data.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
              }


            }

          )
      }
    } //end of if
  } // close of sweetalerme
  booking() {
    this.router.navigate(['/admin/admin-booking'])
  }
  createBooking() {
    this.router.navigate(['/admin/create-booking']);
  }
  createcarBooking() {
    this.router.navigate(['/admin/car-booking']);
  }
  onChange(event) {
    // this._services.getBookingListFromDb(this.field).subscribe((res) => {
    //   if (res['success'] == true) {
    //     this.bookings = res['booking'];
    //   }

    // })
  }

  assignDriver(item) {
    let data = { 'bookingId': item._id, 'vehicleType': item.vehicleType._id, 'latitude': item.source.latitude, 'longitude': item.source.longitude, 'adminId': this.adminId }
    console.log(`datta `, data);
    this._services.changeBookingStatus(data).subscribe((res: any) => {
      if (res && res.success == true) {
        return this.searchFilter(this.currentPage);
      }
      else {
        this.snackBar.open(res.message, 'x', {
          duration: 5000,
        })
      }
    },
      err => {
        alert(err);
      })
  }


}
