import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  adminType: number
  faqList: any = [];
  baseUrl: string
  adminId: string
  skip: number = 0
  pending: number = 0
  limit: number = 10
  search: string = ''
  totalRecords: number
  filteredRecords: number

  loading = false;
  page = 1;
  permissions: any;
  usersession: any;
  
  constructor(
    private service: ApiService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.usersession  = JSON.parse(localStorage.getItem('curruntUser'));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser.adminType==1?curruntUser._id:curruntUser.adminId
    // this.adminId = curruntUser._id;
   console.log('id',this.adminId)

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if(JSON.parse(localStorage.getItem('permissions'))) 
    this.permissions = JSON.parse(localStorage.getItem('permissions')).cms 
 
    this.getFaq(this.adminId);

  }

  getFaq(id) {
    this.service.getFaq(id).subscribe(
      (resp: any) => {
        this.faqList = resp.faq;
      })
  }

  editClick(id){
    this.router.navigate(['/admin/faq/' + id]);
  }



  OnAddFaqClick(){
    this.router.navigate(['/admin/faq/add']);
  }
  
}
