import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
// import {MessagingService} from '../../services/messaging.service';
import { Howl, Howler } from "howler";
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.scss']
})
export class NavbarAdminComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  message: any;
  id: any;
  resetForm: FormGroup;
  notificationValue: any;
  @Output() notification = new EventEmitter();
  adminId: any;
  notifications: any;
  flags = {
    isNewNotification: false
  };
  // soundFile = new Howl({
  //   src: ["assets/sound/sound.mp3"],
  //   loop: true,
  //   volume: 0.5
  // });

  constructor(private fb: FormBuilder,
    private router: Router,
    // public messagingService:MessagingService,
    public service: ApiService, public snackBar: MatSnackBar,
    private common: CommonService,
    private chatService: ChatService
  ) {
    this.chatService.startUp()
    this.id = JSON.parse(localStorage.getItem('curruntUser'))
  }

  ngOnInit() {
    this.resetForm = this.fb.group({
      oldpassword: ['', [Validators.required]],
      newpassword: ['', [Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)]],
      cnfpassword: ['', [Validators.required]],

    })
    if (!localStorage.getItem('curruntUser')) {
      this.logout()
    }
    var notiVal = localStorage.getItem('notification');
    if (this.message) {
      this.notificationValue = 1
    }
    this.common.getNotification().subscribe((result: boolean) => {
      this.flags.isNewNotification = result;
    })
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['']);
  }

  notificationlist() {
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.service.notifications(this.adminId, 5).subscribe(
      (res: any) => {
        this.common.setNotification(false);
        this.notifications = res.Notification
      })
  }

  Setting() {
    this.router.navigate(['admin/setting'])
  }

  pen(message) {
    if (message === 'Ride request failed ,Assign Rider Manually') {
      this.router.navigate(['/admin/pending-booking'])
    } else if (message === "Driver Request") {
      this.router.navigate(['/admin/manage-drivers'])
    } else if (message === 'User Request') {
      this.router.navigate(['/admin/payment'])
    }
  }

  goToDashboard() {
    this.router.navigate(['/admin/admin-dashboard']);
  }

  changePassword() {
    let obj = {
      oldPassword: this.resetForm.value.oldpassword,
      newPassword: this.resetForm.value.newpassword,
      adminId: this.id._id
    }
    this.service.ChangePassword(obj).subscribe((res: any) => {
      if (res.success == true) {
        this.closebutton.nativeElement.click();
        this.resetForm.reset();
        this.snackBar.open(res.message, 'Dismiss', {
          duration: 5000,
        })
      } else {
        this.snackBar.open(res.message, 'Dismiss', {
          duration: 5000,
        })
      }
    })
  }
}
