import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-discount-coupon',
  templateUrl: './discount-coupon.component.html',
  styleUrls: ['./discount-coupon.component.scss']
})
export class DiscountCouponComponent implements OnInit {
  search: string = ''
  field:any='';
  keyword:any='';
  DiscountData:any =[];
  adminId:any;
  usersession: any;
  permissions: any;
  constructor( public snackBar: MatSnackBar,private service:ApiService,private route: ActivatedRoute,private router: Router) {
 
   }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    
    this.usersession  = JSON.parse(localStorage.getItem('curruntUser'));
    
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if(JSON.parse(localStorage.getItem('permissions'))) 
    this.permissions = JSON.parse(localStorage.getItem('permissions')).discount
   
 
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.getDiscount();

  }
  goToAddCoupon(){
    this.router.navigate(['/admin/add-coupon']);
  }
  editCoupon(user){
     var id = user._id;
     this.router.navigate(['/admin/add-coupon', {id}]);

  }
  getDiscount(){
    var data= {
      "adminId":this.adminId,
      "skip":0,
      "limit":10
    }
    this.service.getCouponList(data).subscribe((res)=>{
      if(res['success'] == true){
        // console.log(res);
        this.DiscountData = res['data'];
      }
    });
  }
  updateStatus(list: any, status) {
    // console.log(list._id, status);
    let form = {
      "promoId": list._id,
      "status": status ? 0 : 1
    }

    this.service.changeCouponStatus(form).subscribe(
        (data: any) => {
          if (data.success) {
            // console.log('status-----', data);
            this.snackBar.open(data.message, 'x', {
              duration: 5000,
            })
          }
        }
      )
  }
}
