import { Component, OnInit } from '@angular/core';
import { AngularCsv } from 'angular7-csv';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-driver-payment',
  templateUrl: './driver-payment.component.html',
  styleUrls: ['./driver-payment.component.scss']
})
export class DriverPaymentComponent implements OnInit {
  adminId: any;
  paymentData: any = [];
  page = 1;
  keyword: any
  field: any = 'All';
  admin: any
  onload: boolean = false
  onlinePayment: any
  earningAfterLastPayment: number
  OfflinePayment: any
  today = new Date()
  currentPage: Number;
  noPayment: boolean = true
  paymentStatus: string
  handler: any = null;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  usersession: any;
  permissions: any;
  bookType: any = 'All'
  outstandingAmout: number;
  //paymentButton: boolean;
  constructor(private service: ApiService, private router: Router, public snackBar: MatSnackBar) {
  }
  ngOnInit() {
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.currentPage = 1
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));


    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).payment
    this.getPaymentList(this.currentPage);
    // this.getdataFromJson()
    // this.loadStripe();
  }
  getPaymentList(page) {

    let data =
    {
      page: page
    }

    if (this.keyword) {
      data['keyword'] = this.keyword;
    }
    if (this.field) {
      data['field'] = this.field;
    }
    if (this.bookType) {
      data['bookType'] = this.bookType
    }
    this.service.getpaymentData(data).subscribe((res) => {
      console.log(res);
      this.onload = true
      this.admin = res
      this.calculateOutstading(res);
      //   this.paymentData = this.admin.data
      this.onload = true
      this.config = {
        id: "page",
        currentPage: page,
        itemsPerPage: 10,
        totalItems: this.admin.total
      }
    });
  }

  // getdataFromJson() {
  //   this.service.getDatafromJSON().subscribe(res => {
  //     console.log(res)
  //     this.calculateOutstading(res);

  //   })
  // }


  calculateOutstading(res) {
    debugger
    // this method is to calculate outstand after last payment
    let date = new Date()

    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].lastPaidOn == date && res.data[i].earningAfterLastPayment == 0) {//paid today only or no rides after last payment
        this.outstandingAmout = 0;
        let temp = res.data[i]
        temp['outstandingAmount'] = this.outstandingAmout;
        //  temp = { ...temp, this.outstandingAmout}
        res.data[i] = temp
        this.noPayment = true
      } else {
        let onlineEarning = res.data[i].wallet;
        let offlineEarning = res.data[i].cash;
        let earningAfterLastPayment = onlineEarning + offlineEarning
        let adminPercentage = res.data[i].commission;
        let adminMoney = earningAfterLastPayment * (adminPercentage / 100);
        let driverMoney = earningAfterLastPayment - adminMoney;
        if (offlineEarning >= driverMoney) {//driver have more cash then his percentage after all drive
          this.noPayment = true
          this.outstandingAmout = 0
          let temp = res.data[i]
          temp['outstandingAmount'] = this.outstandingAmout;
          temp['earningAfterLastPayment'] = earningAfterLastPayment
          res.data[i] = temp
          console.log("sarplus declined")
        } else {
          if (onlineEarning > adminMoney) {// online payment is more then admin money
            this.outstandingAmout = onlineEarning - adminMoney;
            this.noPayment = false
            let temp = res.data[i]
            temp['outstandingAmount'] = this.outstandingAmout;
            temp['earningAfterLastPayment'] = earningAfterLastPayment;
            res.data[i] = temp
          }

        }
      }


    }

    console.log(res.data[0]);
    this.paymentData = res.data
  }

  paymentDone(event, i) {


    let date1 = new Date();
    let date = moment().valueOf();
    // console.log(i);
    let data = {
      driverId: i,
      lastPayment: date
    }
    this.service.updateDriver(data).subscribe(res => {
      if (res['success']) {
        let resp = res
        this.snackBar.open(res['message'], 'x', {
          duration: 5000,
        })
        this.paymentStatus = ''
        this.getPaymentList(this.currentPage);
      }
    })

  }


  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getPaymentList(newPage)
  }
  resetFilter() {
    this.field = 'All',
      this.bookType = 'All',
      this.keyword = ''
    this.getPaymentList(this.currentPage);
  }
  exportCSV() {
    let data1 =
    {
      isExport: 0,
      page: 1
    }
    this.service.getpaymentData(data1).subscribe((res: any) => {
      console.log(res);
      this.onload = true
      this.admin = res
      this.paymentData = this.admin.data

      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: ["Driver Name ", "Contact Number", "Total Earning"]
      };
      var data = [];
      this.paymentData.forEach(element => {
        element.createdAt = moment(element.createdAt).format('ll');
        data.push({
          drivername: element.firstName + " " + element.lastName,
          Contact: element.countryCode + " " + element.phone,
          earning: Math.round(element.earning * 100) / 100,
        })
      });

      new AngularCsv(data, 'Driver Payment report', options);
    })
  }

  pay(amount) {
    //this method will call payment gateway method with amount
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_aeUUjYYcx4XNfKVW60pmHTtI',
      locale: 'auto',
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        console.log(token)
        alert('Token Created!!');
      }
    });

    handler.open({
      name: 'RYDZ ',
      description: 'Payment to Driver',
      amount: amount * 100
    });

  }



  // loadStripe() {
  //   //Dont touch anything in this.. This is payment Gateway method
  //   if (!window.document.getElementById('stripe-script')) {
  //     var s = window.document.createElement("script");
  //     s.id = "stripe-script";
  //     s.type = "text/javascript";
  //     s.src = "https://checkout.stripe.com/checkout.js";
  //     s.onload = () => {
  //       this.handler = (<any>window).StripeCheckout.configure({
  //         key: 'pk_test_aeUUjYYcx4XNfKVW60pmHTtI',
  //         locale: 'auto',
  //         token: function (token: any) {
  //           // You can access the token ID with `token.id`.
  //           // Get the token ID to your server-side code for use.
  //           console.log(token)
  //           alert('Payment Success!!');
  //         }
  //       });
  //     }

  //     window.document.body.appendChild(s);
  //   }
  // }

}

