import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './auth/auth.guard'
import { EditGeofenceComponent } from './Pages/edit-geofence/edit-geofence.component'; // this line you need

import { LoginComponent } from './Pages/admin/login/login.component';
import { AdminDashboardComponent } from './Pages/admin/admin-dashboard/admin-dashboard.component';
import { ManageUserComponent } from './Pages/admin/manage-user/manage-user.component';
import { ManageMerchantsComponent } from './Pages/admin/manage-merchants/manage-merchants.component';
import { ManageDriversComponent } from './Pages/admin/manage-drivers/manage-drivers.component';
import { ManageCarCategoryComponent } from './Pages/admin/manage-car-category/manage-car-category.component';
import { PaymentComponent } from './Pages/admin/payment/payment.component';
import { AdminBookingComponent } from './Pages/admin/admin-booking/admin-booking.component';
import { AddCarCategoriesComponent } from './Pages/admin/add-car-categories/add-car-categories.component';
import { ManageAddUserComponent } from './Pages/admin/manage-add-user/manage-add-user.component';
import { AddMerchantComponent } from './Pages/admin/add-merchant/add-merchant.component';
import { AddDriversComponent } from './Pages/admin/add-drivers/add-drivers.component';
import { GeofencingComponent } from './Pages/admin/geofencing/geofencing.component';
// import { ReviewComponent } from './Pages/admin/review/review.component';
import { NotificationComponent } from './Pages/admin/notification/notification.component';
import { AnalyticsComponent } from './Pages/admin/analytics/analytics.component';
import { DocumentComponent } from './Pages/admin/document/document.component';
import { ManageDeliveryAdminComponent } from './Pages/admin/manage-delivery-admin/manage-delivery-admin.component';
import { ManageDriverAdminComponent } from './Pages/admin/manage-driver-admin/manage-driver-admin.component';
import { AddDriversAdminComponent } from './Pages/admin/add-drivers-admin/add-drivers-admin.component';
import { AddDeliveryAdminComponent } from './Pages/admin/add-delivery-admin/add-delivery-admin.component';
import { ManageMerchantDocumentsComponent } from './Pages/admin/manage-merchant-documents/manage-merchant-documents.component';
import { AddCouponComponent } from './Pages/admin/add-coupon/add-coupon.component';
import { DiscountCouponComponent } from './Pages/admin/discount-coupon/discount-coupon.component';
import { ForgotPasswordComponent } from './Pages/admin/forgot-password/forgot-password.component';
import { ViewUserActivityComponent } from './Pages/admin/view-user-activity/view-user-activity.component';
import { TrackingComponent } from './Pages/admin/tracking/tracking.component';
import { VacantDriversListComponent } from './Pages/admin/vacant-drivers-list/vacant-drivers-list.component';
import { SubadminComponent } from './Pages/admin/subadmin/subadmin.component';
import { AddSubAdminComponent } from './Pages/admin/add-sub-admin/add-sub-admin.component';
import { EditSubAdminComponent } from './Pages/admin/edit-sub-admin/edit-sub-admin.component';
import { CreateBookingComponent } from './Pages/admin/create-booking/create-booking.component';
import { ViewMerchantDashboardComponent } from './Pages/admin/view-merchant-dashboard/view-merchant-dashboard.component';
import { SettingComponent } from './Pages/admin/setting/setting.component';
import { UsertermComponent } from './Pages/admin/userterm/userterm.component';
import { UserpolicyComponent } from './Pages/admin/userpolicy/userpolicy.component';
import { DriverpolicyComponent } from './Pages/admin/driverpolicy/driverpolicy.component';
import { DrivertermComponent } from './Pages/admin/driverterm/driverterm.component';
import { AboutusComponent } from './Pages/admin/aboutus/aboutus.component';
import { FaqComponent } from './Pages/admin/faq/faq.component';
import { AddFaqComponent } from './Pages/admin/add-faq/add-faq.component';
import { ManageReviewComponent } from './Pages/admin/manage-review/manage-review.component';
import { ManageDriverDocumentComponent } from './Pages/admin/manage-driver-document/manage-driver-document.component';
import { ScheduledComponent } from './Pages/admin/scheduled/scheduled.component';
import { ReviewsComponent } from './Pages/admin/reviews/reviews.component'; // <-- import the module
import { DriverPaymentComponent } from './Pages/admin/driver-payment/driver-payment.component';
import { ManageRevenueComponent } from './Pages/admin/manage-revenue/manage-revenue.component'; // <-- import the module
import { PendingBookingComponent } from './Pages/admin/pending-booking/pending-booking.component'; // <-- import the module
import { CarBookingComponent } from './Pages/car-booking/car-booking.component'; // this line you need
import { DriverLocationComponent } from './Pages/admin/manage-drivers/driver-location/driver-location.component'; // this line you need
import { CodComponent } from './Pages/admin/cod/cod.component'; // this line you need
import { P404Component } from './Pages/admin/p404/p404.component'; // this line you need
import { PerformanceComponent } from './Pages/performance/performance.component'; // this line you need
import { InvoiceComponent } from './Pages/invoice/invoice.component'; // this line you need
import { InvoicePdfComponent } from './Pages/admin/invoice-pdf/invoice-pdf.component'; // this line you need
import { GdprComponent } from './Pages/admin/gdpr/gdpr.component';
import { QueriesComponent } from './Pages/admin/queries/queries.component';
import { PrivacyComponent } from './Pages/privacy/privacy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'admin/admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-user',
    component: ManageUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/privacy',
    component: PrivacyComponent,
    
  },
  {
    path: 'admin/car-booking',
    component: CarBookingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-merchants',
    component: ManageMerchantsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/edit-sub-admin/:id',
    component: EditSubAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/edit-geofence/:id',
    component: EditGeofenceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/revenue',
    component: ManageRevenueComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-drivers',
    component: ManageDriversComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-car-category',
    component: ManageCarCategoryComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'admin/subadmin',
    component: SubadminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/admin-booking',
    component: AdminBookingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/performance',
    component: PerformanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/pending-booking',
    component: PendingBookingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/payment',
    component: PaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/cod',
    component: CodComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/driverpayment',
    component: DriverPaymentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/ViewMerchant',
    component: ViewMerchantDashboardComponent,

  },
  {
    path: 'admin/add-car-categories',
    component: AddCarCategoriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-review',
    component: ManageReviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-add-user',
    component: ManageAddUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/Add-merchant',
    component: AddMerchantComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/add-drivers',
    component: AddDriversComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/add-sub-admin',
    component: AddSubAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/geofencing',
    component: GeofencingComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'admin/review',
  //   component: ReviewComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'admin/setting',
    component: SettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/notification',
    component: NotificationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/scheduled',
    component: ScheduledComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'admin/analytics',
    component: AnalyticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/driver-document',
    component: DocumentComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'admin/create-booking',
    component: CreateBookingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-delivery-admin',
    component: ManageDeliveryAdminComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'admin/manage-driver-admin',
    component: ManageDriverAdminComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'admin/add-drivers-admin',
    component: AddDriversAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/driverLocation',
    component: DriverLocationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/add-delivery-admin',
    component: AddDeliveryAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-merchant-documents',
    component: ManageMerchantDocumentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/manage-driver-documents',
    component: ManageDriverDocumentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/add-coupon',
    component: AddCouponComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/discount-coupon',
    component: DiscountCouponComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'admin/view-user-activity',
    component: ViewUserActivityComponent

  },
  {
    path: 'admin/view-review',
    component: ReviewsComponent

  },
  {
    path: 'admin/tracking',
    component: TrackingComponent
  },
  {
    path: 'admin/vacantDrivers',
    component: VacantDriversListComponent
  },
  {
    path: 'admin/invoice',
    component: InvoiceComponent
  },
  {
    path: 'admin/invoicePdf/:id',
    component: InvoicePdfComponent
  },
  {
    path: 'admin/user-policy',
    component: UserpolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/user-terms',
    component: UsertermComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/driver-terms',
    component: DrivertermComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/gdpr',
    component: GdprComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/driver-policy',
    component: DriverpolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/faq',
    component: FaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/queries',
    component: QueriesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/about-us',
    component: AboutusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/faq/:id',
    component: AddFaqComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: P404Component
  }
]

@NgModule({


  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
