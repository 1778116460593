import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit {

  coupon: any = {};
  editCoupon: string = '';
  adminId: any;
  couponID: any;
  startDate: any;
  endDate: any;
  today = new Date();
  constructor(private service: ApiService, public snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.couponID = params['id'];
      if (this.couponID != '' && this.couponID != undefined && this.couponID != null) {
        this.getCoupon();
        this.editCoupon = 'Edit';
      }
    });
  }
  getCoupon() {
    this.service.getCouponProfile(this.couponID).subscribe((res) => {

      if (res['success'] == true) {
        this.coupon = res['promotion'];
        this.startDate = res['promotion'].startdate;
        this.endDate = res['promotion'].enddate;
        this.coupon.startdate = moment.utc(res['promotion'].startdate).format();
        this.coupon.enddate = moment.utc(res['promotion'].enddate).format();
      }

    });
  }
  getstartTime(ev) {
    this.startDate = ev.value.valueOf();
  }
  getendTime(ev) {
    this.endDate = ev.value.valueOf();
  }
  goToManageDiscount() {
    this.router.navigate(['/admin/discount-coupon']);
  }


  onSubmit(form) {

    if (form.valid) {

      var couponData;
      if (this.editCoupon != '') {

        couponData = {
          'name': this.coupon.name,
          'discountType': this.coupon.discountType,
          'description': this.coupon.description,
          'startdate': this.startDate,
          'dayslimit': this.coupon.dayslimit,
          'discount': this.coupon.discount,
          'code': this.coupon.code,
          'enddate': this.endDate,
          'promoId': this.couponID
        }

        // console.log(couponData);
        this.service.editCoupon(couponData).subscribe((res) => {

          if (res['success'] == true) {

            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })

          }
          else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })

          }
        });
      } else {
        couponData = {
          'name': this.coupon.name,
          'discountType': this.coupon.discountType,
          'description': this.coupon.description,
          'startdate': this.coupon.startdate.valueOf(),
          'dayslimit': this.coupon.dayslimit,
          'maxlimit': this.coupon.maxlimit,
          // 'maxamount':this.coupon.maxamount,
          'discount': this.coupon.discount,
          'code': this.coupon.code,
          'adminId': this.adminId,
          'enddate': this.coupon.enddate.valueOf()
        }

        // console.log(couponData);
        this.service.addCoupon(couponData).subscribe((res) => {

          if (res['success'] == true) {
            this.coupon = '';

            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })

            this.router.navigate(['/admin/discount-coupon']);

          } else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
          }
        });
      }

    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        allowOutsideClick: false,
        text: 'Please fill all details',
      })
    }


  }



}
