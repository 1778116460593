import { Component, OnInit ,ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';

// import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar,MatInput } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-cod',
  templateUrl: './cod.component.html',
  styleUrls: ['./cod.component.scss']
})
export class CodComponent implements OnInit {
  startDate: any;
  endDate: any;
  driveradmin: any
  keyword: string;
  bookings = [];
  storeId: string
  adminState: string
  bookingList: any;
  query: string
  search: any;
  store: string
  selected: true
  field: any = '';
  adminId: any;
  readioSelected: any='';

  currentPage: Number;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 25,
  }
  status: any;
  usersession: any;
  permissions: any;
  onload: boolean = false

  @ViewChild('enddate', {
    read: MatInput
  }) enddate: MatInput;
  @ViewChild('startdate', {
    read: MatInput
  }) startdate: MatInput;
  csvList: any;
  constructor(
    private _services: ApiService,
    private toastr: ToastrService,
    private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }
  ngOnInit(){
    if (JSON.parse(localStorage.getItem('permissions')))
    this.permissions = JSON.parse(localStorage.getItem('permissions')).cod
    console.log(this.permissions,"permissionss")
  localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
  let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
  this.adminId = curruntUser._id;
  this.currentPage = 1
  this.searchFilter(this.currentPage);
}

getStartDate(ev) {
  this.startDate = moment(ev.value).valueOf();
  // console.log('Startdate', this.startDate)
}

getEndDate(ev) {
  console.log("DATE", ev)
  this.endDate = moment(ev.value).valueOf();
  // console.log('enddate', this.endDate)
}

searchFilter(page) {
  let data =
  {
    page: page,
    isCod:1

  };
   if (this.startDate) {
    data['startdate'] = this.startDate;
  }
  if (this.endDate) {
    data['enddate'] = this.endDate;
  }
 

  this._services.filterBookingListFromDb(data).subscribe((res: any) => {
    this.bookings = res.booking;
    this.bookings.forEach(ele => {
      ele.totalAmount = ele.itemAmount + ele.fare;
    });
    // this.driveradmin=res.bookings.driverId.driverAdmin
    this.onload = true
    this.config = {
      id: "page",
      currentPage: page,
      itemsPerPage: 25,
      totalItems: res.total
    }

  });
}

resetFilter() {
  this.field = '';
  this.keyword = '';
  this.status = '';
  this.startDate = '',
    this.endDate = '',
    this.startdate.value = null;
  this.enddate.value = null;
  this.searchFilter(this.currentPage);

}

pageChange(newPage: number) {
  console.log("newPage", newPage)
  // this.getBookingList(newPage)
  this.searchFilter(newPage)
}


exportCSV() {
  let data =
  {
    page: 1,
    isCod:1
  };
  if (this.startDate) {
    data['startdate'] = this.startDate;
  }
  if (this.endDate) {
    data['enddate'] = this.endDate;
  }
  this._services.getCsvBookingList(data).subscribe((res:any) => {
      console.log(res);
      this.csvList = res.booking
      // this.driversList = this.drivers.driver
      this.onload = true
      var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      useBom: true,
      headers: ["Order No.","Rider Name", "Rider Id"," Amount","Order Status","Merchant Name","Date"]
    };
    var data = [];
    this.csvList.forEach(element => {
      let status =''
      if(element.driverId){
                if(element.status >= 4 && element.status !=11){
                  status="Completed"
                }
                switch(Number(element.status)){
                  case 0 : status= "on the way";
                  break;
                  case 1 :status= "Arrived at source";
                  break;
                  case 2 : status= " On trip";
                  break;
                  case 3: status ='Payment pending';
                  break;
                  case 11 : status='Cancelled'
                }
              }
              if(!element.driverId){
                 switch(Number(element.status)){
                  case 0 : status= "Pending";
                  break;
                  case 11 : status='Cancelled'
                }
              }
      data.push({
             OrderNo:element.OrderNo? element.OrderNo : "",
             riderName: element.firstName + " " + element.lastName,
             riderId: element.driverId?element.driverId.riderId:'',
             Amount:element.itemAmount?element.itemAmount:"0",
             orderStatus:status,
             merchantname:element.storeId?element.storeId.storeName:'',
             date: moment(element.date).format('MMMM Do YYYY'),  
      })
    });
  
    new AngularCsv(data, 'COD  Report', options);
  })
}


}

