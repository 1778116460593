import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  startDate: any;
  endDate: any;
  dataSource: any;
  chartConfig: Object;
  field: string = '';
  usersession: any;
  permissions: any;
  adminId: any;
  //  enddate:any;
  constructor(private service:ApiService, private router: Router, private route: ActivatedRoute,public snackBar: MatSnackBar) {
    this.chartConfig = {
      width: '100%',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
    };
    this.getDay(1,'');


  }
  select() {
    switch (this.field) {
      case 'day':
        this.getDay(1,'');
        break;
      case 'week':
        this.getWeek(2,'');
        break;
      case 'month':
        this.getMonth(3,'');
        break;
      case 'year':
        this.getYear(4,'');
        break;
      case 'date':
        this.getDateData(1, this.startDate,this.endDate);
        break;
      default:
        this.getWeek(2,'');
        break;
    }
  }
  ngOnInit() {
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).analytics
     localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
  }
  getstartTime(ev) {

    this.startDate = moment(ev.value).valueOf();
    // this.startDate = ev.value;
    // console.log('date', this.startDate)
  }
  //  getendTime(ev){
  //    this.endDate = ev.value;
  //  }
  apply() {
    this.getDateData(1, this.startDate,this.endDate);
  }

  getEndDate(ev) {
    this.endDate = moment(ev.value).valueOf();
    // console.log('enddate', this.endDate)
  }
  getDay(type,date) {
    this.service.getSelectedDateDataFromDb(type, date).subscribe((res)=>{

      if(res['success']==true){

        // console.log("getDayData-----", res['data']);
    this.dataSource = {
      "chart": {
        "caption": "Day analytics",
        "subCaption": "",
        "xAxisName": "Hours",
        "yAxisName": "",
        "numberSuffix": "",
        "theme": "fusion",
      },
      "data": [
        {
          "label": "1:00p.m. - 2:00a.m.",
          "value": "0"
        },
        {
          "label": "2:00p.m. - 4:00a.m.",
          "value": "0"
        },
        {
          "label": "4:00p.m. - 6:00a.m.",
          "value": "0"
        },
        {
        "label": "6:00a.m. - 8:00a.m.",
        "value": "0"
      }, 
      {
        "label": "8:00a.m. - 10:00a.m.",
        "value": "0"
      },
      {
        "label": "10:00a.m. - 12:00p.m.",
        "value": "0"
      },
      {
        "label": "12:00p.m. - 14:00p.m.",
        "value": "0"
      },
      {
        "label": "14:00p.m. - 16:00p.m.",
        "value": "0"
      },
      {
        "label": "16:00p.m. - 18:00p.m.",
        "value": "0"
      },
      {
        "label": "18:00p.m. - 20:00p.m.",
        "value": "0"
      },
      {
        "label": "20:00p.m. - 22:00p.m.",
        "value": "0"
      },
      {
        "label": "22:00p.m. - 24:00a.m.",
        "value": "0"
      }
      ]
    }

    for (var i = 0; i < res['data'].length; i++) {
      this.dataSource.data[i].value = res['data'][i];
    }
    // console.log(this.dataSource.data)
    }
  });
  
  }
  getYear(type, date) {
    this.service.getSelectedDateDataFromDb(type, date).subscribe((res)=>{

      if(res['success']==true){

        // console.log("getDayData-----", res['data']);
    this.dataSource = {
      "chart": {
        "caption": "Yearly analytics",
        "subCaption": "",
        "xAxisName": "Months",
        "yAxisName": "",
        "numberSuffix": "",
        "theme": "fusion",
      },
      "data": [{
        "label": "Jan",
        "value": "290"
      }, {
        "label": "Feb",
        "value": "0"
      }, {
        "label": "March",
        "value": "0"
      }, {
        "label": "April",
        "value": "0"
      }, {
        "label": "May",
        "value": "0"
      }, {
        "label": "June",
        "value": "0"
      }, {
        "label": "July",
        "value": "0"
      },
      {
        "label": "Aug",
        "value": "0"
      },
      {
        "label": "Sep",
        "value": "0"
      },
      {
        "label": "Oct",
        "value": "0"
      },
      {
        "label": "Nov",
        "value": "0"
      },
      {
        "label": "Dec",
        "value": "0"
      },
      ]
    };

    for (var i = 0; i < res['data'].length; i++) {
      this.dataSource.data[i].value = res['data'][i];
    }
    // console.log(this.dataSource.data)
  }
});
}
  /*getDays(){
  
    this.dataSource = {
      "chart": {
        "caption": "Analytics",
        "subCaption": "",
        "xAxisName": "Days",
        "yAxisName": "",
        "numberSuffix": "K",
        "theme": "fusion",
      },
      "data": [{
        "label": "Day 1",
        "value": "100"
      }, {
        "label": "Day 2",
        "value": "200"
      }, {
        "label": "Day 3",
        "value": "180"
      }, {
        "label": "Day 4",
        "value": "290"
      }, {
        "label": "Day 5",
        "value": "95"
      }
    ]
    };
  }*/
  getMonth(type, date) {

    this.service.getSelectedDateDataFromDb(type, date).subscribe((res)=>{

      if(res['success']==true){

        // console.log("getmonthData-----", res['data']);

    this.dataSource = {
      "chart": {
        "caption": "Monthly analytics",
        "subCaption": "",
        "xAxisName": "Week",
        "yAxisName": "",
        "numberSuffix": "",
        "theme": "fusion",
      },
      "data": [{
        "label": "Week 1",
        "value": "100"
      }, {
        "label": "Week 2",
        "value": "200"
      }, {
        "label": "Week 3",
        "value": "180"
      }, {
        "label": "Week 4",
        "value": "290"
      }, {
        "label": "Week 5",
        "value": "95"
      }
      ]
    };

      for (var i = 0; i < res['data'].length; i++) {
        this.dataSource.data[i].value = res['data'][i];
      }
      //  console.log(this.dataSource.data)
    }

    });
  }


  getWeek(type, date) {

    this.service.getSelectedDateDataFromDb(type, date).subscribe((res)=>{

      if(res['success']==true){

        // console.log(res['data']);
    this.dataSource = {
      "chart": {
        "caption": "Weekly analytics",
        "subCaption": "",
        "xAxisName": "Days",
        "yAxisName": "",
        "numberSuffix": "",
        "theme": "fusion",
      },
      "data": [{
        "label": "Sunday",
        "value": "0"
      }, {
        "label": "Monday",
        "value": "0"
      }, {
        "label": "Tuesday",
        "value": "0"
      }, {
        "label": "Wednesday",
        "value": "0"
      }, {
        "label": "Thursday",
        "value": "0"
      }, {
        "label": "Friday",
        "value": "0"
      }, {
        "label": "Saturday",
        "value": "0"
      }]
    };

    for (var i = 0; i < res['data'].length; i++) {
      this.dataSource.data[i].value = res['data'][i];
    }
    // console.log(this.dataSource.data)
    }
    });
  }

  getDateData(type, start,end){
    this.service.getSelectedDateFromDb(type, start,end).subscribe((res)=>{

      if(res['success']==true){

        // console.log("selected date-----", res['data']);

        
        this.dataSource = {
          "chart": {
            "caption": "Day analytics",
            "subCaption": "",
            "xAxisName": "Hours",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [
            {
              "label": "1:00p.m. - 2:00a.m.",
              "value": "0"
            },
            {
              "label": "2:00p.m. - 4:00a.m.",
              "value": "0"
            },
            {
              "label": "4:00p.m. - 6:00a.m.",
              "value": "0"
            },
            {
            "label": "6:00a.m. - 8:00a.m.",
            "value": "0"
          }, {
            "label": "8:00a.m. - 10:00a.m.",
            "value": "0"
          },
          {
            "label": "10:00a.m. - 12:00p.m.",
            "value": "0"
          },
          {
            "label": "12:00p.m. - 14:00p.m.",
            "value": "0"
          },
          {
            "label": "14:00p.m. - 16:00p.m.",
            "value": "0"
          },
          {
            "label": "16:00p.m. - 18:00p.m.",
            "value": "0"
          },
          {
            "label": "18:00p.m. - 20:00p.m.",
            "value": "0"
          },
          {
            "label": "20:00p.m. - 22:00p.m.",
            "value": "0"
          },
          {
            "label": "22:00p.m. - 24:00a.m.",
            "value": "0"
          },
          
          ]

      }
      for (var i = 0; i < res['data'].length; i++) {
        this.dataSource.data[i].value = res['data'][i];
      }
      // console.log(this.dataSource.data)
    }
    });



  }
}
