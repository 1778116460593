import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss']
})
export class QueriesComponent implements OnInit {
  adminType: number
  QueryList: any = [];
  // baseUrl: string
  adminId: string
  // skip: number = 0
  // pending: number = 0
  limit: number = 10
  // search: string = ''
  totalRecords: number
  field: any = '';
  keyword: any = '';
  status: any = '';

  loading = false;
  page = 1;
  permissions: any;
  usersession: any;
  onload: boolean = false
  currentPage: Number;

  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  
  constructor(
    private service: ApiService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.usersession  = JSON.parse(localStorage.getItem('curruntUser'));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser.adminType==1?curruntUser._id:curruntUser.adminId

    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));

    if(JSON.parse(localStorage.getItem('permissions'))) 
    this.permissions = JSON.parse(localStorage.getItem('permissions')).cms 
    this.currentPage = 1
    this.getQuery(this.currentPage);
  }

  getQuery(current) {
    this.currentPage = current;
    this.service.getQueries(this.currentPage, this.limit).subscribe((res:any) => {
      this.QueryList = res.data
      this.onload = true
      this.config = {
        id: "page",
        currentPage: this.currentPage,
        itemsPerPage: 10,
        totalItems: res.length
      }
    });
  }

  pageChange(event) {
    this.getQuery(event)
  }
  

}
