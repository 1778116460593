import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MapsAPILoader, MouseEvent } from '@agm/core';
// import { google } from '@google/maps';
import { ChatService } from '../../../services/chat.service'
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;


declare const google: any;
@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss'],
  providers: [ChatService]
})
export class CreateBookingComponent implements OnInit {
  // [x: string]: any;
  kmDistance: any
  isCod: any
  getdirection: boolean = false

  storeId: any
  public showSelect: boolean = true
  public showSelectVehicle: boolean = true
  data: any = {}
  storename: any = localStorage.getItem('storeName')
  submitting: boolean = false
  formValid: boolean = false
  formdata: boolean = false
  requestSent: boolean = false
  latitude: any
  latitude1: any
  slabsList: any = []
  SlabsArr: any = []
  bookingFormValue: any
  handler: any
  farechange: any
  fd: any = {}
  longitude1: any
  drivers: any
  selectedvehicleType: any
  stores: any
  showbtn: boolean = false
  CountryCode: any = "+"
  address1: string;
  isPaid: any
  private geoCoder;
  totalamount: any = 0;
  subtotal: any = 0;
  user: any = {}
  timetaken: any
  longitude: any
  selectedZoom: number;

  timeOut = 0;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('search1')
  public dropElementRef: ElementRef;
  zoom: number;
  zoom1: number;
  time: any;
  merchantregisters: any;
  formatedAddress: any;
  vehicleTypes: any = []
  baseFare: any = 0
  minFare: any
  distance: any
  driverId: string
  driverList: any = []
  fare: any = 0
  payment: boolean = false

  merchants: string
  userId: any
  show: boolean = false;
  respons: boolean = false;
  dir: { search: { lat: any; lng: any; }; destination: { lat: any; lng: any; }; };
  // speed: number = 57824;
  bookingProducts: any
  public showRoute: boolean = false;
  public origin: any = {};
  public destination: any = {};
  multiple: boolean = true
  options: ["oki"]
  public dropdownOptions: any = []
  public dropdownOptions1: any = []
  public dist: any
  slabfare: any = 0


  theCheckbox = false;

  disabled: boolean
  // config = {
  //   displayKey: "name",
  //   search: true,
  //   height: 'auto',
  //   placeholder: 'Search and select products ',
  //   customComparator: () => { },
  //   moreText: 'more',
  //   noResultsFound: 'No results found!',
  //   searchPlaceholder: 'Search',
  //   searchOnKey: 'name'
  // }
  // config1 = {
  //   displayKey: "storeName",
  //   search: true,
  //   height: 'auto',
  //   placeholder: 'Search and select merchants',
  //   customComparator: () => { },
  //   moreText: 'more',
  //   noResultsFound: 'No results found!',
  //   searchPlaceholder: 'Search',
  //   searchOnKey: 'storeName'
  // }
  body: any
  merchantsList: any;
  adminId: any;
  vehicleType: string = '';
  phone: any;
  note: string;
  address: any;
  merchantId: any;
  country: any;
  country1: any;
  distKm: number;
  firstfare: number;
  baseFareValue: any;
  calculatebaseFare: any;
  slabperkm: any;
  fairperkm: number;
  dataArr: { slabPerKm: any; fairPerKm: any, fareChangekm: any, price: number; };

  // public async handleAddressChange(address: Address, pickup) {
  //   if (pickup) {

  //     this.pickupAddress = address.formatted_address
  //     this.origin = {
  //       lat: address.geometry.location.lat(),
  //       lng: address.geometry.location.lng(),
  //       name: address.formatted_address

  //     }
  //     if (this.destination.lat && this.origin.lat) {
  //       this.showSelectVehicle = false
  //       await this.calculateDistance()
  //       this.showRoute = true
  //       this.drivers = ''
  //       this.baseFare = ''
  //       this.vehicleType = ''


  //     }

  //   }
  //   else {

  //     this.destinationAddress = address.formatted_address
  //     this.destination = {
  //       lat: address.geometry.location.lat(),
  //       lng: address.geometry.location.lng(),
  //       name: address.formatted_address
  //     }
  //     if (this.origin.lat && this.destination.lat) {

  //       this.showSelectVehicle = false

  //       await this.calculateDistance()

  //       this.showRoute = true
  //       this.drivers = ''
  //       this.baseFare = ''
  //       this.vehicleType = ''
  //     }
  //   }
  // }

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient,
    private service: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private chatService: ChatService,
    private ngZone: NgZone,

  ) { }


  ngOnInit() {

    let adminId = "5c500a018f80ec6aee8ce942"
    this.data.CountryCode = "+"
    this.service.getMerchantsBooking(adminId).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.merchantsList = res['stores'];
      }
      console.log("merchant", this.merchantsList)

    });


    let data = {
      adminId: adminId,

    }
    this.service.getAllType(data)
      .subscribe(
        (data: any) => {
          this.vehicleTypes = data.vehicles

        })


    this.service.getStoreProducts({ storeId: this.storeId })
      .subscribe(
        (res: any) => {

          this.dropdownOptions = res.products

        })
    this.service.getMerchants()
      .subscribe(
        (res: any) => {
          this.dropdownOptions1 = res.stores

        })
    this.setCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {

      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place, "place")
          place.address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country = key.long_name
              console.log("addres", this.country)
            }
          })
          if (place.geometry === undefined || place.geometry === null) {

            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          //set latitude, longitude and zoom
          //  this.searchElementRef.nativeElement.value=this.address;
        });
      });
      let autocomplete1 = new google.maps.places.Autocomplete(this.dropElementRef.nativeElement
      )
        ;
      autocomplete1.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete1.getPlace();
          place.address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country1 = key.long_name
              console.log("addres", this.country1)
            }
          })
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude1 = place.geometry.location.lat();
          this.longitude1 = place.geometry.location.lng();
          this.zoom = 12;
          // this.searchElementRef.nativeElement.value = this.address;
          //set latitude, longitude and zoom

        });
      });
    });



  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        console.log("currentLOcation")
        this.getAddress(this.latitude, this.longitude)
      });
    }
  }





  async calculateDistance() {

    let services = new google.maps.DistanceMatrixService();
    const search = new google.maps.LatLng(this.latitude, this.longitude);
    const destination = new google.maps.LatLng(this.latitude1, this.longitude1);

    var cc = await this.getTime(services, search, destination)


    this.timetaken = cc.time
    console.log("cc", cc)
    this.kmDistance = cc.kmDistance
    this.distance = cc.distance
    return (1)
    // const distance = google.maps.geometry.spherical.computeDistanceBetween(search, destination);
    // this.distance = distance

    // return Math.round(distance / 1000)
  }


  getDirections() {
    console.log(this.country, this.country1)
    if (this.country == this.country1) {
      console.log("this", this.country, this.country1)
      this.getdirection = true
      this.showSelect = false
      if (this.longitude && this.longitude && this.latitude1 && this.longitude1) {
        this.show = true
        this.showbtn = true
        this.dir = {
          search: { lat: (this.latitude), lng: (this.longitude) },
          destination: { lat: this.latitude1, lng: this.longitude1 }
        }

      }
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        allowOutsideClick: false,
        text: 'Source and distination should be in same country',
      })
    }

  }
  editDirection() {
    this.showSelectVehicle = false
    this.show = false
    this.getdirection = false

    this.showbtn = false
    this.drivers = ''
    this.driverId = ''
    this.baseFare = ''
    this.fare = ''
    this.timetaken = ''
    this.vehicleType = ''
    this.distance = ''
    this.fare = ''
  }
  async onChange(event) {

    await this.calculateDistance()
    let obj = this.vehicleTypes.find(o => o._id == event);
    if (this.slabsList && this.slabsList.length > 0) {
      if (obj) {
        console.log(this.slabsList)
        this.slabsList.sort(function (a, b) {
          return a.fareChangekm - b.fareChangekm;
        });
        console.log(this.slabsList, "order", this.baseFare)
        this.selectedvehicleType = obj._id
        this.driverList = []
        this.getDriverslist()
        const dist = Number(this.kmDistance) / 1609
        this.distKm = dist

        this.baseFare = 0 + this.baseFareValue
        var amount = 0
        console.log("dist", dist, this.distance.split(' ')[0])
        console.log("obj", obj, this.baseFare, "===============")
        var startAt = 0;

        this.calculatebaseFare = this.baseFare
        var sum = 0;
        var distances = 0;
        for (var item of this.slabsList) {
          // startAt = 0;
          this.farechange = item.fareChangekm
          distances = item.fareChangekm - startAt
          var calculte = distances * this.calculatebaseFare;
          this.slabperkm = startAt + '-' + this.farechange

          sum = sum + calculte;

          this.fairperkm = calculte
          this.dataArr = {
            slabPerKm: this.slabperkm,
            fairPerKm: distances,
            fareChangekm: this.calculatebaseFare,
            price: this.fairperkm,
            // fareChangekm:farechangekm
          }

          console.log("sum", sum)
          this.calculatebaseFare = item.fairPerKm;
          startAt = item.fareChangekm;
          this.SlabsArr.push(this.dataArr)



        }

        distances = this.distance.split(' ')[0] - startAt
        this.slabperkm = 'Above' + '' + startAt
        console.log(distances, "distance")
        var actualDistanceCal = sum + (distances * this.calculatebaseFare)
        this.fairperkm = distances * this.calculatebaseFare
        console.log(sum, actualDistanceCal, "actualdistance")
        this.dataArr = {
          slabPerKm: this.slabperkm,
          fairPerKm: distances,
          fareChangekm: this.calculatebaseFare,
          price: this.fairperkm,

          // fareChangekm:farechangekm
        }
        this.SlabsArr.push(this.dataArr)
        console.log(this.SlabsArr, "dataArr")
        this.fare = actualDistanceCal
        console.log(sum, "sum", this.fare)
        this.fare = parseInt(this.fare) ? parseInt(this.fare) : 0
        this.subtotal = this.totalamount + this.fare
        console.log("this", this.subtotal)
      }
      else {
        this.selectedvehicleType = ''
      }
    }
    else {
      if (obj) {
        this.selectedvehicleType = obj._id
        this.driverList = []
        this.getDriverslist()
        const dist = Number(this.kmDistance) / 1609
        this.distKm = dist
        this.baseFare = obj.baseFare
        var amount = 0
        console.log("dist", dist, this.distance.split(' ')[0])
        console.log("obj", obj)
        if (dist < obj.fareChangekm) {
          if ((obj.fareRate) * dist <= obj.baseFare) {
            amount = obj.baseFare
          }
          else {
            console.log("3333333")
            amount = Math.round((obj.fareRate) * dist)
          }
        } else {
          let additionalKm = dist - obj.fareChangekm
          amount = (obj.fareChangekm * obj.fareRate) + Math.round((obj.fareRateAfter) * Number(additionalKm))
          if (amount < obj.baseFare) {
            amount = obj.baseFare
          }
          console.log("else", additionalKm, Math.round((obj.fareRateAfter) * Number(additionalKm)), (obj.fareChangekm * obj.fareRate))

        }

        // console.log("amount",amount)
        // this.fare=amount > 3 ? amount : 0
        this.fare = parseInt(this.fare) ? parseInt(this.fare) : 0
        this.subtotal = this.totalamount + this.fare
        console.log("this", this.subtotal)
      }
      else {
        this.selectedvehicleType = ''
      }
    }
  }
  getDistanceMatrix = (service, data) => new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response, status) => {
      if (status === 'OK') {
        resolve(response)
      } else {
        reject(response);
      }
    })
  });

  getTime = async (services, search, destination) => {

    const result = await this.getDistanceMatrix(
      services,
      {
        origins: [search],
        destinations: [destination],
        travelMode: 'DRIVING',
        // units: imperial
      }
    )
    console.log("result['rows'][0].elements[0].distance", result['rows'].elements)

    return {
      kmDistance: result['rows'][0].elements[0].distance.value,
      distance: result['rows'][0].elements[0].distance.value,
      time: result['rows'][0].elements[0].duration.text,

    }
  };
  selectionChanged(event) {

    this.bookingProducts = event.value.map((product) => {
      return product._id
    })
  }
  searchFilter() {
    if (this.phone.length >= 7) {
      this.service.getSearchList(this.phone, this.CountryCode, this.storeId).subscribe((res: any) => {
        if (res['success'] == true) {
          if (res.booking.length == 0) {
            return
          }
          this.data = res['booking'][0]

          this.dropElementRef.nativeElement.value = res['booking'][0].destination.name
          this.latitude1 = res['booking'][0].destination.latitude
          this.longitude1 = res['booking'][0].destination.longitude
          this.getAddress1(res['booking'][0].destination.latitude, res['booking'][0].destination.longitude)

        }
      });
    }
  }
  async formCallback(form) {
    return new Promise((resolve, reject) => {
      form['distance'] = this.distKm
      this.chatService.createBooking(form)
        .subscribe((message: any) => {
          // form.value = ''
          console.log("mesage", message)
          this.timeOut = message.status != '2' ? this.timeOut : 0
          console.log("booking", message)
          if (message.status == '3') {
            this.formdata = false
          }
          if (message.status == '0' || message.status == '3' || (message.status == '2' && this.driverId)) {
            // console.log("form.value.driverId", form.value.driverId)
            clearInterval(this.handler)
            this.submitting = false
            this.requestSent = false
            this.toastr.success(message.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
            this.drivers = ''
            this.selectedvehicleType = ''
            this.vehicleType = ''
            this.driverId = ''
            this.formValid = false
            this.totalamount = 0
            this.fare = 0
            this.baseFare = 0
            this.subtotal = 0
            this.totalamount = 0
            this.kmDistance = ''
            this.distance = ''
            this.timetaken = ''
            if (message.status == '0') {
              console.log("resolve(1)")
              resolve(1)
            }

            else if (message.status == '2' && this.driverId) {

              console.log("driveriD........222221")
              console.log("resolve(2)")
              resolve(2)
            }
          }
          else if (message.status == '2' && !this.driverId) {
            resolve(4)
          }
          else {
            this.fd = form
            this.timeOut = 0
            console.log("resolve(3)")
            resolve(3)
          }

        });
    })
  }
  Amount() {
    console.log(this.totalamount, "kkk")
    //   this.totalamount= this.totalamount && !isNaN(this.totalamount)?Number(this.totalamount):0
    this.subtotal = Number(this.totalamount) + Number(this.fare);
    // console.log("tota", Number(this.subtotal+this.totalamount))
  }
  async sendbooking(form) {
    form = form ? form : this.fd
    this.fd = form ? form : this.fd
    let formSubmitted;
    console.log(this.timeOut, "timeout")
    if (this.timeOut >= 3) {
      this.fd = undefined
      console.log(this.timeOut, "tine")
      this.timeOut = 0

      clearInterval(this.handler)
      this.submitting = false
      this.toastr.warning('No Rider Available your Request Sent to Admin to Assign Rider Manually ', "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
    } else {

      console.log(this.fd, "formdata")
      if (form && (!this.driverId || (!this.requestSent && this.driverId))) {
        // this.requestSent = true;
        this.timeOut = this.timeOut + 1
        // this.requestSent = true;
        form.value.timeOut = this.timeOut
        console.log('insdie conditon', this.fd)
        if (!form.value.driverId) {
          form.value.driverId = ""
        }
        formSubmitted = await this.formCallback(form.value);

        console.log(formSubmitted, 'formSubmitted')
        if (formSubmitted == 4 || formSubmitted == 3) {
          this.sendbooking(this.fd)
        } else if (formSubmitted == 1) {
          // form.reset()
          // this.reset()
          // this.formdata=false
          // this.drivers=''  
          // this.vehicleType=''
          // this.selectedvehicleType=''
          // this.fare=0,
          // this.formValid=false
          // this.totalamount=0
          // this.subtotal=0      
          // this.isCod='',
          // this.driverId=''
          // this.timeOut =0
          form.reset()
          this.reset()
          this.ngOnInit()
          this.showSelect = true
          this.formdata = false
          this.drivers = ''
          this.getdirection = false
          this.vehicleType = ''
          this.selectedvehicleType = ''
          this.fare = 0,
            this.formValid = false
          this.totalamount = 0
          this.subtotal = 0
          this.driverId = ''
          this.timeOut = 0
          $('.paymentType').prop('checked', false);
        }
      }
      else {
        console.log("elsecase booking")
        if (this.drivers) {
          clearInterval(this.handler)
          this.drivers = ''
          this.vehicleType = ''
          this.selectedvehicleType = '',
            this.baseFare = 0,
            this.fare = 0,
            this.formValid = false
          this.totalamount = 0
          this.subtotal = 0
          this.timetaken = '',
            this.driverId = ''
          this.formValid = false

          this.totalamount = 0,
            this.isCod = '',
            this.submitting = false
          this.requestSent = false
          this.toastr.warning('Driver unable to accept your request ', "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
          this.timeOut = 0
        }
      }
    }
  }


  cod(event) {
    console.log("eveby", event)
    this.isCod = event.target.value
    this.isPaid = 1
    console.log("is", this.isCod)
    this.showSelectVehicle = false
    this.payment = Number(event.target.value) ? false : true
    this.totalamount = 0
    this.subtotal = this.totalamount + this.fare
    this.vehicleType = ''
    this.drivers = ''
    this.baseFare = 0,
      this.subtotal = 0
    this.fare = 0
    this.distance = 0
    this.timetaken = ''
  }
  isOnline(event) {
    console.log("eveby", event)
    this.isCod = event.target.value
    this.isPaid = 0
    this.payment = Number(event.target.value) ? false : true
    console.log("is", this.isCod)
    this.totalamount = 0
    this.vehicleType = ''
    this.drivers = ''
    this.baseFare = 0,
      this.subtotal = 0
    this.fare = 0
    this.distance = 0
    this.timetaken = ''
    this.showSelectVehicle = false

  }

  reset() {
    console.log("reset")
    this.bookingFormValue = ''
    this.baseFare = ''
    this.note = ''
    this.CountryCode = "+"
    this.show = false
    this.timetaken = ''
    this.driverId = ''
    this.fare = ''
    this.searchElementRef.nativeElement.value = ''
    this.dropElementRef.nativeElement.value = ''
    this.dropElementRef
    this.distance = ''
    this.vehicleType = ''
    this.latitude = ''
    this.longitude = ''
    this.latitude1 = ''
    this.longitude1 = ''
    this.isCod = ''
    this.showbtn = false;
  }

  markerDragEnds($event: MouseEvent) {
    this.latitude1 = $event.coords.lat;
    this.longitude1 = $event.coords.lng;
    this.getAddress1(this.latitude1, this.longitude1);
    this.dropElementRef.nativeElement.value = this.address1;
    this.drivers = ''
    this.baseFare = ''
    this.fare = ''
    this.vehicleType = ''
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.searchElementRef.nativeElement.value = this.address;
    this.drivers = ''
    this.baseFare = ''
    this.fare = ''
    this.vehicleType = ''
  }
  back() {
    console.log("/ghjgjhg")
    this.router.navigate(['/admin/admin-booking'])
  }
  getDriverslist() {
    let data = {
      adminId: "5c500a018f80ec6aee8ce942",
      vehicleType: this.selectedvehicleType,
      latitude: this.latitude,
      longitude: this.longitude
    }
    this.service.getDrivers(data).subscribe
      ((data: any) => {

        this.driverList = data.drivers

      })


  }
  getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          console.log("result", results[0])
          this.address = results[0].formatted_address;
          this.searchElementRef.nativeElement.value = this.address;
          results[0].address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country = key.long_name
              console.log("addres", this.country)
            }


          })
        } else {
          // window.alert('No results found');
        }
      } else {
      }



    });
  }
  getAddress1(latitude1, longitude1) {
    this.geoCoder.geocode({ 'location': { lat: latitude1, lng: longitude1 } }, (results, status) => {

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address1 = results[0].formatted_address;
          this.dropElementRef.nativeElement.value = this.address1;
          console.log("addresss drop", this.address1)
          results[0].address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country1 = key.long_name
              console.log("addres", this.country)
            }


          })
          this.calculateDistance()
        }
      }
    });
  }

  getProductList() {
    this.service.getStoreProducts({ storeId: this.storeId })
      .subscribe(
        (res: any) => {
          this.dropdownOptions = res.products

        })
  }

  onChangemerchant(event) {
    this.SlabsArr = []
    console.log("event", event)
    this.merchantId = event.target.value

    let obj = this.merchantsList.find(o => o._id == this.merchantId);
    console.log(obj, "obbb")
    this.merchants = obj._id
    this.latitude = obj.location.lat
    this.longitude = obj.location.long
    this.slabsList = obj.slabs
    this.baseFareValue = Number(obj.baseFare);
    console.log("thlat", this.latitude, "long", this.longitude, this.slabsList, obj.baseFare, this.baseFare)
    this.getAddress(this.latitude, this.longitude)
    // this.getProductList()
  }

  onChangeDriver(event) {

    let obj = this.driverList.find(o => o._id == event);
    if (obj)
      this.driverId = obj._id
    else {
      this.driverId = ''
    }

  }
  // toggleVisibility(c) {
  // this.paid = c.target.checked;
  //   this.totalamount=0
  //   this.payment=c.target.checked
  //   this.subtotal=this.totalamount + this.fare

  // }


  clearValues() {

    this.vehicleType = ''


  }




  AddBooking(bookingForm: NgForm) {
    this.submitting = true
    console.log(bookingForm.valid, bookingForm.value, this.formValid, "AddBooking")
    // bookingForm.value.products = this.bookingProducts;
    bookingForm.value.adminId = "5c500a018f80ec6aee8ce942";
    bookingForm.value.storeId = this.merchantId;
    bookingForm.value.driverId = this.driverId;
    bookingForm.value.source = {
      name: this.searchElementRef.nativeElement.value,
      latitude: this.latitude,
      longitude: this.longitude,
    }
    bookingForm.value.destination = {
      name: this.dropElementRef.nativeElement.value,
      latitude: this.latitude1,
      longitude: this.longitude1
    }
    bookingForm.value.fare = this.fare;
    bookingForm.value.note = this.note;
    bookingForm.value.itemAmount = this.totalamount,
      bookingForm.value.subtotalFare = this.subtotal,
      bookingForm.value.vehicleType = this.selectedvehicleType;
    bookingForm.value.bookingType = 1;
    bookingForm.value.isCod = this.isCod
    bookingForm.value.isPaid = this.isPaid
    bookingForm.value.slabs = this.SlabsArr
    bookingForm.value.isPaidInitial = this.isPaid
    this.bookingFormValue = {}
    this.handler = 0
    bookingForm.value.timeOut = this.timeOut
    this.bookingFormValue = bookingForm.value
    this.sendbooking(bookingForm)
    this.handler = setInterval(this.sendbooking.bind(this), 17000)

  }



  // async validateForm(form: NgForm) {
  //   let i = 0;
  //   const temp = [];
  //   console.log("form", form)
  //   this.formdata = false
  //   let FormKeys = Object.keys(form.value);
  //   console.log(FormKeys);
  //   for (i = 0; i < FormKeys.length; i++) {
  //     console.log(this.isCod, FormKeys[i] == "Amount", FormKeys[i], form.value[FormKeys[i]])
  //     if (((FormKeys[i] != "drivers" && FormKeys[i] != "note") && !form.value[FormKeys[i]]) || (this.isCod == 0 && FormKeys[i] == "Amount" && !form.value[FormKeys[i]]) || form.value.CountryCode == '+') {
  //       if (temp.length) break;
  //       temp.push(FormKeys[i]);
  //       // this.toastw(`${FormKeys[i]} is required`)
  //       if (FormKeys[i] != "Amount") {
  //        }
  //       this.formdata = true
  //       //  break;
  //     }
  //     console.log("outsideLoop", form.value)
  //   }
  //   console.log(temp);
  //   if (temp.length) 
  //   return this.toastw(`${temp[0]} is required.`);
  //   this.formdata = true;
  //   if (!this.formdata && this.showSelect)
  //    return this.toastw('Please select payment type first.');
  //   if (!this.formdata && !this.isCod || (Number(this.isCod) == 1 && Number(form.value.Amount) <= 0)) return this.toastw('Please fill amount first.');
  //   // console.log("kkkkkk", this.formdata == false, (!this.formdata && this.isCod == 0), !this.formdata && (this.isCod && form.value['Amount']))
  //   // if (this.formdata == false || (!this.formdata && this.isCod == 0) || (!this.formdata && this.isCod && form.value['Amount'])) {
  //   if (this.formdata) {
  //     // this.formdata = true
  //     this.AddBooking(form);

  //     this.formValid = true
  //   }
  // }
  // toastw(mesg) {
  //   this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  // }
  async validateForm(form: NgForm) {
    console.log("forma", form.value);
    this.formdata = false
    let regx = new RegExp(/^(\+?\d{1,3}|\d{1,4})$/)
    console.log("regx.test(form.value.CountryCode)", regx.test(form.value.CountryCode));
    if (!(regx.test(form.value.CountryCode || (form.value.CountryCode.length != 3)))) {
      return this.toastw("CountryCode is invalid.")
    }
    if (form.value.phone == undefined || form.value.phone == "") {
      return this.toastw("phone is required.")
    }
    // let Num = /^[0-9]*$/;
    console.log("Num.test(form.value.phone)", regx.test(form.value.countrycode), form.value.phone.length);
    // if (!(Num.test(form.value.phone))) {
    //   return this.toastw("phone Number is invalid.")
    // }
    if (form.value.firstName == undefined) {
      return this.toastw("firstName is required.")
    }
    if (form.value.lastName == undefined) {
      return this.toastw("lastName is required.")
    }
    if (this.dropElementRef.nativeElement.value == undefined || this.dropElementRef.nativeElement.value == "") {
      return this.toastw("Destination is required.")
    }
    if (this.merchants == undefined || this.merchants == "") {
      return this.toastw("merchant  is required.")
    }

    if (!this.formdata && (!this.showSelect && !this.isCod)) {
      return this.toastw('Please select payment type first.');
    }
    if (form.value.vehicleType == undefined || form.value.vehicleType == "") {
      return this.toastw("vehicleType is required.")
    }
    if (!this.formdata && !this.isCod || (Number(this.isCod) == 1 && Number(form.value.Amount) <= 0)) {
      return this.toastw('Please fill amount first.');
    }

    this.AddBooking(form);

    this.formValid = true

  }
  toastw(mesg) {
    this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  }

  Country(event) {
    if (event.target.value.length == 0) {
      console.log("jhgjhg", event.target.value.length)
      event.target.value = "+"
    }
    if (event.target.value.charAt(0) !== "+") {
      event.target.value = "+" + event.target.value
      console.log("eveney Down", event.target.value.charAt(0))
    }
  }
}