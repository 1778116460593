import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-delivery-admin',
  templateUrl: './add-delivery-admin.component.html',
  styleUrls: ['./add-delivery-admin.component.scss']
})
export class AddDeliveryAdminComponent implements OnInit {

  user:any={}
  response:any;
  userId:any;
  adminId:any;
  userInfo:any;
  editUser:string='';
  state:any;
  constructor(private route: ActivatedRoute,private router: Router, private service:ApiService, public snackBar: MatSnackBar) { }
  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.user.countryCode = '+';
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      // console.log(this.userId);
      if(this.userId!='' && this.userId!=undefined && this.userId!=null){
        this.getUserInfo();
        this.editUser = 'Edit'
        // console.log("params----"+params['id']);
      }
      
      
    });

  
 
  }

  


  onSubmit(form){

    if(form.valid){
      if(this.editUser!=''){
        var data = {
          "adminId":this.userId,
          "firstName": this.user.firstName,
          "lastName": this.user.lastName,
          "email": this.user.email,
          "password":this.user.password,
          "phone":this.user.phone,
          "countryCode":this.user.countryCode
        }
  
        this.service.editDeliveryAdminInfo(data).subscribe((res)=>{
    
          // console.log(JSON.stringify(res));
    
          if(res['success']==true){
            // console.log(res);
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.router.navigate(['/admin/manage-delivery-admin']);
            // this.user='';
          } else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
          
          }
    
        });
  
      } else {
        
        
        var userData = {
          "firstName": this.user.firstName,
          "lastName": this.user.lastName,
          "email": this.user.email,
          "password":this.user.password,
          "phone":this.user.phone,
          "state":this.user.state,
          "countryCode":this.user.countryCode
        }
        // console.log(userData);
        this.service.registerDeliveryAdmin(userData).subscribe((res)=>{
    
          // console.log(JSON.stringify(res));
    
          if(res['success']==true){
            // console.log(res);
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.user='';
            this.router.navigate(['/admin/manage-delivery-admin']);
          } else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
          }
    
        });
      }
    } else{
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor:'#821727',
        allowOutsideClick:false,
        text: 'Please fill all details',
      })
    }
    
    
   
   
}

resetFormValue(){
  this.getUserInfo();
  // alert('123');
  // this.user = '';

}

getUserInfo(){
  // console.log("userId----", this.userId);
  this.service.getDeliveryAdminInfoFromDb(this.userId).subscribe((res)=>{

    if(res['success']==true){

      // console.log("userInfo", res);
      this.user = res['admin'];

    }

  });
}


goToManageUser()
{
  this.router.navigate(['/admin/manage-delivery-admin']);
}


handleStateChnage(address){
  this.state='';
  address.address_components.forEach(key => {
   
    if (key.types[0] == 'administrative_area_level_1') {
      this.state = key.long_name
    }
    // console.log('state---', this.state)

  });
}

changeValue(vm){
  // this.user.countryCode= '+'+vm.target.value
  if(this.user.countryCode!=null && this.user.countryCode.includes('+')){
    this.user.countryCode = vm.target.value;
  } else {
    this.user.countryCode= '+'+vm.target.value;
    
  }
  // console.log('changeValue', this.user.countryCode);
}

}