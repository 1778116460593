import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Howl, Howler } from "howler";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // soundFile = new Howl({
  //   src: ["assets/sound/sound.mp3"],
  //   loop: false,
  //   volume: 0.5
  // });
  isNewNotification = new BehaviorSubject<boolean>(false);

  constructor() { }
  getNotification(): Observable<boolean> {
    return this.isNewNotification.asObservable();
  }
  setNotification(value: boolean) {
    console.log(value);
    this.isNewNotification.next(value);
  }
}
