import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
  adminList: any;

  onload: boolean = false

  adminId: string
  adminType: number
  skip: number = 0
  limit: number = 10
  search: string = ''
  field: any = 'All';
  keyword: any = '';
  status: any = '';
  currentPage: Number;
  admin: any
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  permissions: any = {}
  usersession: any = {}
  constructor(private route: ActivatedRoute, private router: Router, private service: ApiService, public snackBar: MatSnackBar, ) { }

  ngOnInit(): void {
    // const that = this;
    // this.adminId = JSON.parse(localStorage.getItem('curruntUser'))._id
    // this.adminType = JSON.parse(localStorage.getItem('curruntUser')).adminType
    // let data = {
    //   adminId: this.adminId,
    //   skip: this.skip,
    //   limit: this.limit,
    //   search: this.search
    // }
    // console.log("currentUrl====", this.router.url);
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.service.currentUserPath = this.router.url;
    // console.log(this.service.currentUserPath);

    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).users
    this.currentPage = 1
    this.searchFilter(this.currentPage);
  }

  goToAddUser() {
    this.router.navigate(['/admin/manage-add-user']);
  }

  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.searchFilter(newPage)
  }

  exportCSV() {
    let data1 =
    {
      isExport: 0,
      page: 1
    }
    this.service.getAllAdminList(data1).subscribe((res: any) => {

      if (res['success'] == true) {
        this.admin = res;
        this.onload = true

        console.log(this.admin, "admin")
        this.adminList = this.admin.user
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          useBom: true,
          headers: ["Name", "E-mail", "createdAt", "Mobile", "Total Booking", "Current Booking", "Completed",
            "Cancellation Rate", "Paymenent not complete", "Reviews", "status"]
        };
        var data = [];
        this.adminList.forEach(element => {
          // element.createdAt = moment(element.createdAt).format('ll');
          data.push({
            Name: element.firstName + " " + element.lastName,
            email: element.email,
            createdAt: element.createdAt,
            Mobile: element.countryCode + "" + element.phone,
            Booking: element.bookings,
            CurrentBooking: element.current,
            Completed: element.complete,
            CancellationRate: element.cancelRate,
            Paymenent: element.incompletePAyment,
            Reviews: element.userRating,
            Status: element.status ? "active" : "disable"

            // ridername : element.userId ? element.userId.firstName +"" +element.userId.lastName : element.firstName  +"" +element.lastName, 

          })

        });
        new AngularCsv(data, 'Customer  report', options);

      }
    })
  }



  searchFilter(page) {
    let data =
    {
      page: page
    };
    if (this.status) {
      data['status'] = this.status;
    }

    if (this.field) {
      data['field'] = this.field;
    }
    if (this.keyword) {
      data['keyword'] = this.keyword;
    }
    this.service.getAllAdminList(data).subscribe((res) => {

      if (res['success'] == true) {
        // console.log(res['admin']);
        this.admin = res;
        this.onload = true

        console.log(this.admin, "admin")
        this.adminList = this.admin.user
        console.log(this.adminList, "adminList")
        this.onload = true
        this.config = {
          id: "page",
          currentPage: page,
          itemsPerPage: 10,
          totalItems: this.admin.total


        }
      }
    });
  }

  resetFilter() {
    this.field = 'All';
    this.keyword = '';
    this.status = '';
    this.searchFilter(1);

  }


  editUser(list) {
    // console.log("user----", user);
    var id = list._id;
    console.log("id", id)
    this.router.navigate(['/admin/manage-add-user', { id }]);
  }
  deleteClick(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        let form = {


          id: result.value
        }
        this.service.deleteDrivers(id).subscribe(res => {

          Swal.fire(
            'Deleted!',
            'User has been deleted.',
            'success'
          )
          this.searchFilter(this.currentPage)
        });
      }
      else {
        Swal.fire(
          ' Cancelled!',

        )
        // showCancelButton: true, 
      }
    })
  }
  changeToggle(list: any, status, event) {
    console.log("event", event.target);

    // console.log("status", status);
    if (status == 1) {
      console.log('khghghjgh')
      Swal.fire({
        title: 'Are you sure?',
        text: "Please remark",
        type: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },

        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      }).then((result) => {

        if (result.value) {
          let form = {
            userId: list._id,
            status: status ? 0 : 1,
            remark: result.value
          }

          this.service.chnageUserStatus(form)
            .subscribe(
              (data: any) => {
                console.log('khghghjgh')

                if (data.success) {
                  Swal.fire(
                    'Status Changed Successfully!',
                    '',
                    'success'
                  )
                  this.ngOnInit()
                  // console.log("uncheckedToggle",this.uncheckedToggle)
                }


              }

            )
        }
        else {
          //code for cacel

          console.log("cancel for  active user")
          this.ngOnInit()

        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to Change Status",
        type: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',

      }).then((result) => {
        // console.log('result',result);
        if (result.value) {
          console.log('khghghjgh')
          let form = {
            userId: list._id,
            status: status ? 0 : 1,
            remark: ''
          }

          this.service.chnageUserStatus(form)
            .subscribe(
              (data: any) => {

                if (data.success) {
                  console.log('khghghjgh')
                  Swal.fire(

                    'Status Changed Successfully!',
                    '',
                    'success'
                  )
                  this.ngOnInit()
                }

              }
            )
        } else {

          console.log("no", status)

          this.ngOnInit()
          console.log("cancel for not active user")

          //if cancled 

        }
      })
    }




  }

  viewActivityLog(user) {
    var id = user._id;
    this.router.navigate(['/admin/view-user-activity', { id }]);

  }
  viewReview(user) {
    var id = user._id;
    this.router.navigate(['/admin/view-review', { id }]);
  }

  // goToPage(n: number): void {
  //   this.page = n;
  //   let data = {
  //     adminId: this.adminId,
  //     skip: (n - 1) * this.limit,
  //     limit: this.limit,
  //     search: this.search
  //   }
  //   this.getAdminListFromDb(data);
  // }

  // onNext(): void {
  //   this.page++;
  //   let data = {
  //     adminId: this.adminId,
  //     skip: (this.page - 1) * this.limit,
  //     limit: this.limit,
  //     search: this.search
  //   }
  //   this.getAdminListFromDb(data);
  // }

  // onPrev(): void {
  //   this.page--;
  //   let data = {
  //     adminId: this.adminId,
  //     skip: (this.page - 1) * this.limit,
  //     limit: this.limit,
  //     search: this.search
  //   }

  //   this.getAdminListFromDb(data);
  // }
}

