import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { debug } from 'util';
// import {NgxDocViewerModule} from 'ngx-doc-viewer';
// import {PdfViewerModule} from 'ng2-pdf-viewer'
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  public licence: any
  public plate: any
  public inzi: any
  public rc: any
  public document1: string
  public document2: string
  public document3: string
  public document4: string;
  public driverId: any
  public baseUrl: any
  public storeId: any
  documentData: any = [];
  public allowDoc = ['jpg', 'jpeg', 'png', 'pdf', 'JPG', 'PNG', 'JPEG', 'PDF'];
  public imageExtentions = ['jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG'];
  // public docExtentions = ['doc','docx']
  public allowDocError: string;

  constructor(
    private service: ApiService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.baseUrl = this.service.baseUrl
    this.route.params.subscribe(params => {
      this.driverId = params['id']
    });

    this.getDriverDocFromDb();

    // this.service.getMerchantDoc(this.merchantId)
    //   .subscribe(
    //     (data: any) => {
    //       let store = data.store
    //       this.storeId = store._id
    //       if (store) {
    //         console.log("data", data);
    //         // this.document1 = data._id
    //         this.document1 = this.baseUrl + store.document1
    //         // this.document2 = data._id
    //         this.document2 = this.baseUrl + store.document2
    //         // this.document3 = data._id
    //         this.document3 = this.baseUrl + store.document3
    //       }

    //       else {
    //         this.merchantId = 'noId'
    //       }
    //     })
  }

  // onDocumen1tAdded(event) {
  //   this.document1 = event.target.files[0]
  //   this.changeImage(this.document1, 'document1')
  // }

  // onDocumen2tAdded(event) {
  //   this.document2 = event.target.files[0]


  //   this.changeImage(this.document2, 'document2')
  // }

  // onDocumen3tAdded(event) {
  //   this.document3 = event.target.files[0]
  //   this.changeImage(this.document3, 'document3')
  // }

  onDocumentChanage(event, doc) {

    var file = event.target.files[0];
    this.allowDocError = '';
    if (this.allowFile(file.name)) {
      this.changeImage(file, doc);
    } else {
      // event.target.value='';
      this.allowDocError = 'Please choose file jpg,jpeg,pdf and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg,pdf and png',
      })
    }
  }


  checkFileExtention(filename) {
    return filename.split('.').pop();
  }

  changeImage(file, doc) {
    // console.log('file--',file);

    var docName;

    switch (doc.name) {
      case 'Licence':
        docName = 'licence'
        break
      case 'Plate':
        docName = 'plate'
        break
      case 'Inzi':
        docName = 'inzi'
        break
      case 'Rc':
        docName = 'rc'
        break
    }



    let docId = { "docId": doc._id };
    // console.log(docName);
    let formDocuments = new FormData()
    formDocuments.append(docName, file)
    formDocuments.append('data', JSON.stringify(docId));

    this.service.updateDriverDocuments(formDocuments)
      .subscribe((res) => {
        if (res['success'] == true) {
          this.getDriverDocFromDb();
        }

      })
  }


  getDriverDocFromDb() {
    
    this.service.getDriverDoc(this.driverId).subscribe((res) => {
      if (res['success'] == true) {
        console.log(res);
        let temp = []
        temp = res['docs'];
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].name == "Licence") {
            temp[i].name = "Driving License"
          }
          if (temp[i].name == "Inzi") {
            temp[i].name = "Proof of Insurance"
          }
          if (temp[i].name == "Rc") {
            temp[i].name = "Registration"
          }
          if (temp[i].name == "Plate") {
            temp[i].name = "Plate Number"
          }
        }
        this.documentData = temp
      }
    });


  }
  toDataURL(url) {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }
  async forceDownload(baseUrl, name) {
    const a = document.createElement("a");
    a.href = await this.toDataURL(baseUrl + name);
    a.download = name.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);


    // var url = baseUrl+name;
    // var xhr = new XMLHttpRequest();
    // xhr.open("GET", url, true);
    // xhr.responseType = "blob";
    // xhr.onload = function () {
    //   var urlCreator = window.URL;
    //   console.log(this.response);
    //   var imageUrl = urlCreator.createObjectURL(this.response);
    //   var tag = document.createElement('a');
    //   tag.href = imageUrl
    //   console.log(`${imageUrl}.${this.response.type.split('/').pop()}`);
    //   tag.download = name.split('/').pop();
    //   console.log(tag);
    //   document.body.appendChild(tag);
    //   tag.click();
    //   document.body.removeChild(tag);
    // }
    // xhr.send();
  }

  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }
  goToBack() {
    this.router.navigate(['/admin/manage-drivers']);
  }
}

