import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MapsAPILoader, MouseEvent } from '@agm/core';
// import { google } from '@google/maps';
import { ChatService } from '../../services/chat.service'
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-car-booking',
  templateUrl: './car-booking.component.html',
  styleUrls: ['./car-booking.component.scss'],
  providers: [ChatService]

})
export class CarBookingComponent implements OnInit {
  country: any;
  country1: any
  [x: string]: any;
  kmDistance: any
  getdirection: boolean = false

  data: any = {}
  storename: any = localStorage.getItem('storeName')
  submitting: boolean = false
  formValid: boolean = false
  selectedvehicleType: any
  stores: any
  showbtn: boolean = false
  CountryCode: any = "+"
  address1: string;
  private geoCoder;
  user: any = {}
  timetaken: any
  selectedZoom: number;
  handler: any
  timeOut = 0;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('search1')
  public dropElementRef: ElementRef;
  zoom: number;
  zoom1: number;
  time: any;
  merchantregisters: any;
  formatedAddress: any;
  vehicleTypes: any = []
  baseFare: any = 0
  minFare: any
  distance: any
  driverId: string
  driverList: any = []
  fare: any = 0
  merchants: string
  userId: any
  show: boolean = false;
  respons: boolean = false;
  dir: { search: { lat: any; lng: any; }; destination: { lat: any; lng: any; }; };
  // speed: number = 57824;
  bookingProducts: any
  public showRoute: boolean = false
  public showSelectVehicle: boolean = true
  public origin: any = {};
  public destination: any = {};
  multiple: boolean = true
  options: ["oki"]
  public dropdownOptions: any = []
  public dropdownOptions1: any = []
  public dist: any
  type: any = 1
  paid = false;
  theCheckbox = false;

  disabled: boolean

  body: any
  merchantsList: any;
  adminId: any;



  constructor(
    private mapsAPILoader: MapsAPILoader,
    private http: HttpClient,
    private service: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private chatService: ChatService,
    private ngZone: NgZone,

  ) { }


  ngOnInit() {

    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser.adminType == 1 ? curruntUser._id : curruntUser.adminId
    this.data.CountryCode = "+"
    this.service.getMerchantsBooking(this.adminId).subscribe((res: any) => {
      if (res['success'] == 1) {
        this.merchantsList = res['stores'];
      }
      console.log("merchant", this.merchantsList)

    });


    // adminId: adminId,


    // this.service.getVehicleListFromDb(this.adminId)
    //   .subscribe(
    //     (data: any) => {
    //       this.vehicleTypes = data.vehicles

    //     })


    this.service.getVehicleListFromDb(this.adminId).subscribe((res) => {

      if (res['success'] == true) {
        this.vehicleTypes = res['vehicletype'];
        console.log("vehicle type", this.vehicleTypes);
      }
    });


    this.service.getStoreProducts({ storeId: this.storeId })
      .subscribe(
        (res: any) => {

          this.dropdownOptions = res.products

        })
    this.service.getMerchants()
      .subscribe(
        (res: any) => {
          this.dropdownOptions1 = res.stores

        })
    this.setCurrentLocation();
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {

      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          place.address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country = key.long_name
              console.log("addres", this.country)
            }
          })
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          //set latitude, longitude and zoom
          this.searchElementRef.nativeElement.value = this.address;
        });
      });
      let autocomplete1 = new google.maps.places.Autocomplete(this.dropElementRef.nativeElement
      )
        ;
      autocomplete1.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete1.getPlace();
          place.address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country1 = key.long_name
              console.log("addres", this.country1)
            }
          })
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude1 = place.geometry.location.lat();
          this.longitude1 = place.geometry.location.lng();
          this.zoom = 12;
          // this.searchElementRef.nativeElement.value = this.address;
          //set latitude, longitude and zoom

        });
      });
    });



  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        console.log("currentLOcation")
        this.getAddress(this.latitude, this.longitude)
      });
    }
  }





  async calculateDistance() {

    let services = new google.maps.DistanceMatrixService();
    const search = new google.maps.LatLng(this.latitude, this.longitude);
    const destination = new google.maps.LatLng(this.latitude1, this.longitude1);

    var cc = await this.getTime(services, search, destination)


    this.timetaken = cc.time
    console.log("cc", cc)
    this.kmDistance = cc.kmDistance
    this.distance = cc.distance
    return (1)
    // const distance = google.maps.geometry.spherical.computeDistanceBetween(search, destination);
    // this.distance = distance

    // return Math.round(distance / 1000)
  }


  getDirections() {
    console.log(this.country, this.country1)
    if (this.country == this.country1) {
      this.getdirection = true

      this.showSelectVehicle = false
      if (this.longitude && this.longitude && this.latitude1 && this.longitude1) {
        this.show = true
        this.showbtn = true
        this.dir = {
          search: { lat: (this.latitude), lng: (this.longitude) },
          destination: { lat: this.latitude1, lng: this.longitude1 }
        }

      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          confirmButtonColor: '#821727',
          allowOutsideClick: false,
          text: 'Source and distination should be in same country',
        })
      }

    }

  }
  editDirection() {
    this.showSelectVehicle = false
    this.show = false
    this.getdirection = false

    this.showbtn = false
    this.drivers = ''
    this.baseFare = ''
    this.fare = ''
    this.timetaken = ''
    this.vehicleType = ''
    this.distance = ''
    this.fare = ''
  }
  async onChange(event) {


    await this.calculateDistance()

    let obj = this.vehicleTypes.find(o => o._id == event);


    if (obj) {
      this.selectedvehicleType = obj._id
      this.driverList = []
      // this.getDriverslist()
      const dist = Number(this.kmDistance) / 1609
      this.distKm = dist
      this.baseFare = obj.baseFare
      var amount = 0
      // console.log("dist", dist, this.distance.split(' ')[0])

      //console.log("obj", obj)
      if (dist < obj.fareChangekm) {
        //if distance is less than fareChangeKm

        if ((obj.fareRate) * dist <= obj.baseFare) {
          //if amount  is less than 

          amount = obj.baseFare
        }
        else {

          // console.log("3333333")
          amount = Math.round((obj.fareRate) * dist)
        }
      } else {
        let additionalKm = dist - obj.fareChangekm
        amount = (obj.fareChangekm * obj.fareRate) + Math.round((obj.fareRateAfter) * Number(additionalKm))
        if (amount < obj.baseFare) {
          amount = obj.baseFare
        }
        console.log("else", additionalKm, Math.round((obj.fareRateAfter) * Number(additionalKm)), (obj.fareChangekm * obj.fareRate))

      }
      // this.fare=amount > 3 ? amount : 0
      //  this.fare = parseInt(amount) ? parseInt(amount) : 0
      this.fare = amount
    }
  }
  getDistanceMatrix = (service, data) => new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response, status) => {
      if (status === 'OK') {
        resolve(response)
      } else {
        reject(response);
      }
    })
  });

  getTime = async (services, search, destination) => {
    const result = await this.getDistanceMatrix(
      services,
      {
        origins: [search],
        destinations: [destination],
        travelMode: 'DRIVING'
      },

    )
    console.log("result['rows'][0].elements[0].distance", result['rows'][0].elements[0].distance)

    return {
      kmDistance: result['rows'][0].elements[0].distance.value,
      distance: result['rows'][0].elements[0].distance.value,
      time: result['rows'][0].elements[0].duration.text,

    }
  };
  selectionChanged(event) {

    this.bookingProducts = event.value.map((product) => {
      return product._id
    })
  }
  searchFilter() {
    if (this.phone.length >= 7) {
      this.service.getSearchList(this.phone, this.CountryCode, this.storeId).subscribe((res: any) => {
        if (res['success'] == true) {
          if (res.booking.length == 0) {
            return
          }
          this.data = res['booking'][0]

          this.dropElementRef.nativeElement.value = res['booking'][0].destination.name
          this.latitude1 = res['booking'][0].destination.latitude
          this.longitude1 = res['booking'][0].destination.longitude
          this.getAddress1(res['booking'][0].destination.latitude, res['booking'][0].destination.longitude)

        }
      });
    }
  }

  async formCallback(form) {
    return new Promise((resolve, reject) => {
      form['distance'] = this.distKm
      this.chatService.createBooking(form)
        .subscribe((message: any) => {
          // form.value = ''
          console.log("mesage", message)
          this.timeOut = message.status != '2' ? this.timeOut : 0
          console.log("booking", message)
          if (message.status == '0' || message.status == '3') {
            // console.log("form.value.driverId", form.value.driverId)
            clearInterval(this.handler)
            this.submitting = false
            this.requestSent = false
            this.toastr.success(message.message, "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });

            this.vehicleType = ''
            this.timeOut = 0
            this.fare = 0
            this.subtotal = 0
            if (message.status == '0') {
              console.log("resolve(1)")
              resolve(1)
            }
          }
          else if (message.status == '2' && !this.driverId) {
            resolve(4)
          }
          else {
            this.fd = form
            this.timeOut = 0
            console.log("resolve(3)")
            resolve(3)
          }

        });
    })
  }
  Country(event) {
    if (event.target.value.length == 0) {
      console.log("jhgjhg", event.target.value.length)
      event.target.value = "+"
    }
    if (event.target.value.charAt(0) !== "+") {
      event.target.value = "+" + event.target.value
      console.log("eveney Down", event.target.value.charAt(0))
    }
  }

  async sendbooking(form) {
    form = form ? form : this.fd
    this.fd = form ? form : this.fd
    let formSubmitted;
    console.log(this.timeOut, "timeout")
    if (this.timeOut >= 3) {
      this.fd = undefined
      console.log(this.timeOut, "tine")
      this.timeOut = 0

      clearInterval(this.handler)
      this.submitting = false
      this.toastr.warning('No Rider Available your Request Sent to Admin to Assign Rider Manually ', "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
    } else {

      console.log(this.fd, "formdata")
      if (form && (!this.driverId || (!this.requestSent && this.driverId))) {
        // this.requestSent = true;
        this.timeOut = this.timeOut + 1
        // this.requestSent = true;
        form.value.timeOut = this.timeOut
        console.log('insdie conditon')
        if (!form.value.driverId) {
          form.value.driverId = ""
        }
        formSubmitted = await this.formCallback(form.value);

        console.log(formSubmitted, 'formSubmitted')
        if (formSubmitted == 4 || formSubmitted == 3) {
          this.sendbooking(this.fd)
        } else if (formSubmitted == 1) {
          form.reset()
          this.reset()
          this.drivers = ''
          this.fare = 0,
            this.driverId = ''
          this.baseFare = 0
          this.totalamount = 0
          this.subtotal = 0
          this.paid = true
          this.timeOut = 0
        }

      }
      else {
        console.log("elsecase booking")
        if (this.drivers) {
          clearInterval(this.handler)
          this.drivers = ''
          this.vehicleType = ''
          this.fare = ''
          this.driverId = ''
          this.baseFare = '',
            this.distance = '',
            this.timetaken = '',
            this.subtotal = '',
            this.totalamount = '',
            this.paid = true,
            this.submitting = false
          this.requestSent = false
          this.toastr.warning('Driver unable to accept your request ', "", { closeButton: true, easeTime: 700, timeOut: 1200, positionClass: 'toast-bottom-center' });
          this.timeOut = 0
        }


        //   if (formSubmitted == 1) {
        //     form.reset()
        //     this.reset();
        //   }
        // }
        //   if (formSubmitted == 2) {
        //     console.log("driveriD")
        //     this.drivers = ''
        //     this.vehicleType = ''
        //     this.fare = ''
        //     this.baseFare = '',
        //     this.distance = '',
        //     this.timetaken = '',
        //     this.paid = true
        //   }
      }
    }
  }


  reset() {
    console.log("reset")
    this.bookingFormValue = ''
    this.baseFare = ''
    this.note = ''
    this.CountryCode = "+"
    this.show = false
    this.timetaken = ''
    this.driverId = ''
    this.fare = ''
    this.searchElementRef.nativeElement.value = ''
    this.dropElementRef.nativeElement.value = ''
    this.dropElementRef
    this.distance = ''
    this.vehicleType = ''
    this.latitude = ''
    this.longitude = ''
    this.latitude1 = ''
    this.longitude1 = ''
    this.paid = true
    this.showbtn = false;
  }

  markerDragEnds($event: MouseEvent) {
    this.latitude1 = $event.coords.lat;
    this.longitude1 = $event.coords.lng;
    this.getAddress1(this.latitude1, this.longitude1);
    this.dropElementRef.nativeElement.value = this.address1;
    this.drivers = ''
    this.baseFare = ''
    this.fare = ''
    this.vehicleType = ''
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
    this.searchElementRef.nativeElement.value = this.address;
    this.drivers = ''
    this.baseFare = ''
    this.fare = ''
    this.vehicleType = ''
  }
  back() {
    console.log("/ghjgjhg")
    this.router.navigate(['/admin/admin-booking'])
  }
  getDriverslist() {
    let data = {
      adminId: "5c500a018f80ec6aee8ce942",
      vehicleType: this.selectedvehicleType,
      latitude: this.latitude,
      longitude: this.longitude
    }
    this.service.getDrivers(data).subscribe
      ((data: any) => {

        this.driverList = data.drivers

      })


  }
  getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          results[0].address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country = key.long_name
              console.log("addres", this.country)
            }


          })
          this.searchElementRef.nativeElement.value = this.address;
          console.log("search", this.address)

        } else {
          // window.alert('No results found');
        }
      } else {
      }



    });
  }
  getAddress1(latitude1, longitude1) {
    this.geoCoder.geocode({ 'location': { lat: latitude1, lng: longitude1 } }, (results, status) => {

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address1 = results[0].formatted_address;
          results[0].address_components.forEach(key => {
            if (key.types[0] === 'country') {
              this.country1 = key.long_name
              console.log("addres", this.country)
            }


          })
          this.dropElementRef.nativeElement.value = this.address1;
          console.log("addresss drop", this.address1)
          this.calculateDistance()
        }
      }
    });
  }

  getProductList() {
    this.service.getStoreProducts({ storeId: this.storeId })
      .subscribe(
        (res: any) => {
          this.dropdownOptions = res.products

        })
  }

  onChangemerchant(event) {
    console.log("event", event)
    this.merchantId = event.target.value

    let obj = this.merchantsList.find(o => o._id == this.merchantId);
    console.log(obj, "obbb")
    this.merchants = obj._id
    this.latitude = obj.location.lat
    this.longitude = obj.location.long
    console.log("thlat", this.latitude, "long", this.longitude)
    this.getAddress(this.latitude, this.longitude)
    // this.getProductList()
  }

  onChangeDriver(event) {

    let obj = this.driverList.find(o => o._id == event);
    if (obj)
      this.driverId = obj._id
    else {
      this.driverId = ''
    }

  }
  toggleVisibility(c) {
    this.paid = c.target.checked;


  }


  clearValues() {

    this.vehicleType = ''


  }




  AddBooking(bookingForm: NgForm) {
    this.submitting = true
    // bookingForm.value.products = this.bookingProducts;
    bookingForm.value.adminId = "5c500a018f80ec6aee8ce942";
    bookingForm.value.storeId = this.merchants;
    bookingForm.value.driverId = this.driverId;
    bookingForm.value.source = {
      name: this.searchElementRef.nativeElement.value,
      latitude: this.latitude,
      longitude: this.longitude,
    }
    bookingForm.value.destination = {
      name: this.dropElementRef.nativeElement.value,
      latitude: this.latitude1,
      longitude: this.longitude1
    }
    bookingForm.value.fare = this.fare;
    bookingForm.value.note = this.note;
    bookingForm.value.vehicleType = this.selectedvehicleType;
    bookingForm.value.bookingType = 1;
    bookingForm.value.isPaid = this.paid ? 1 : 0
    this.bookingFormValue = {}
    this.handler = 0
    bookingForm.value.timeOut = this.timeOut
    this.bookingFormValue = bookingForm.value
    this.sendbooking(bookingForm)
    this.handler = setInterval(this.sendbooking.bind(this), 15000)

  }


  validateForm(form: NgForm) {
    let i = 0;
    let FormKeys = Object.keys(form.value);
    console.log("FormKeys", FormKeys, form.value[FormKeys[9]]);
    for (i = 0; i < FormKeys.length; i++) {
      console.log("FormKeys[i]", FormKeys[i]);

      if (form.value[FormKeys[i]] == "" || form.value[FormKeys[i]] == undefined) {

        this.toastw(`${FormKeys[i]} is required`)
        break;
      }
    }

    this.formValid = true
  }
  toastw(mesg) {
    this.toastr.warning(mesg, "", { closeButton: true, timeOut: 1000, positionClass: 'toast-bottom-center' });
  }

}