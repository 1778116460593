import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  countData: any = [];
  newData: any = [];
  totalData: any = [];
  adminId: any;
  dataSource: any = {};
  dataSource1: any = {};
  chartConfig1: Object;

  chartConfig: Object;
  type: any = 2
  type1: any = 2

  field: any;
  baseurl: string;

  constructor(private service: ApiService, private route: ActivatedRoute, private router: Router, private common: CommonService) {
    this.chartConfig = {
      width: '100%',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
    };
    this.getuser();
    this.chartConfig1 = {
      width: '100%',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
    };

    this.getbooking();

  }

  ngOnInit() {
    this.baseurl = this.service.baseUrl
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.getuser();
    this.getData();
  }
  testRun() {
    this.common.setNotification(true);
  }
  view(ev) {
    // this.router.navigate(['/admin/view-dashboarddata',{"view":ev}]);
    switch (ev) {
      case 'New Users':
        this.router.navigate(['/admin/manage-user']);
        break;
      case 'New Bookings':
        this.router.navigate(['/admin/admin-booking']);
        break;
      case 'Total Users':
        this.router.navigate(['/admin/manage-user']);
        break;
      case 'Total Bookings':
        this.router.navigate(['/admin/admin-booking']);
        break;
    }
  }
  selectbooking() {
    switch (this.type) {
      case '1':
        this.getbookingWeek();
        break;
      case '2':
        this.getbooking();
        break;
      default:
        this.getbookingWeek();
        break;
    }
  }
  selectuser() {
    switch (this.type1) {
      case '1':
        this.getuserWeek();
        break;
      case '2':
        this.getuser();
        break;
      default:
        this.getuserWeek();
        break;
    }
  }
  getuser() {
    this.service.getusergraph(this.field = "user", this.type1).subscribe((res) => {

      if (res['success'] == true) {
        console.log("getuserData-----", res['data']);
        this.dataSource1 = {
          "chart": {
            "caption": "Monthly analytics",
            "subCaption": "",
            "xAxisName": "Months",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Jan",
            "value": "290"
          }, {
            "label": "Feb",
            "value": "0"
          }, {
            "label": "March",
            "value": "0"
          }, {
            "label": "April",
            "value": "0"
          }, {
            "label": "May",
            "value": "0"
          }, {
            "label": "June",
            "value": "0"
          }, {
            "label": "July",
            "value": "0"
          },
          {
            "label": "Aug",
            "value": "0"
          },
          {
            "label": "Sep",
            "value": "0"
          },
          {
            "label": "Oct",
            "value": "0"
          },
          {
            "label": "Nov",
            "value": "0"
          },
          {
            "label": "Dec",
            "value": "0"
          },
          ]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource1.data[i].value = res['data'][i];
        }
        // console.log(this.dataSource.data)
      }
    });
  }
  getuserWeek() {
    this.service.getusergraph(this.field = "user", this.type1).subscribe((res) => {
      if (res['success'] == true) {

        // console.log(res['data']);
        this.dataSource1 = {
          "chart": {
            "caption": "Weekly analytics",
            "subCaption": "",
            "xAxisName": "Days",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Sunday",
            "value": "0"
          }, {
            "label": "Monday",
            "value": "0"
          }, {
            "label": "Tuesday",
            "value": "0"
          }, {
            "label": "Wednesday",
            "value": "0"
          }, {
            "label": "Thursday",
            "value": "0"
          }, {
            "label": "Friday",
            "value": "0"
          }, {
            "label": "Saturday",
            "value": "0"
          }]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource1.data[i].value = res['data'][i];
        }
        // console.log(this.dataSource.data)
      }
    });
  }
  getbooking() {
    this.service.getbookinggraph(this.field = "booking", this.type).subscribe((res) => {

      if (res['success'] == true) {

        console.log("getDayData-----", res['data']);
        this.dataSource = {
          "chart": {
            "caption": "Monthly analytics",
            "subCaption": "",
            "xAxisName": "Months",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Jan",
            "value": "290"
          }, {
            "label": "Feb",
            "value": "0"
          }, {
            "label": "March",
            "value": "0"
          }, {
            "label": "April",
            "value": "0"
          }, {
            "label": "May",
            "value": "0"
          }, {
            "label": "June",
            "value": "0"
          }, {
            "label": "July",
            "value": "0"
          },
          {
            "label": "Aug",
            "value": "0"
          },
          {
            "label": "Sep",
            "value": "0"
          },
          {
            "label": "Oct",
            "value": "0"
          },
          {
            "label": "Nov",
            "value": "0"
          },
          {
            "label": "Dec",
            "value": "0"
          },
          ]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource.data[i].value = res['data'][i];
        }
      }
    });
  }
  getbookingWeek() {

    this.service.getbookinggraph(this.field = "booking", this.type).subscribe((res) => {
      if (res['success'] == true) {

        // console.log(res['data']);
        this.dataSource = {
          "chart": {
            "caption": "Weekly analytics",
            "subCaption": "",
            "xAxisName": "Days",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Sunday",
            "value": "0"
          }, {
            "label": "Monday",
            "value": "0"
          }, {
            "label": "Tuesday",
            "value": "0"
          }, {
            "label": "Wednesday",
            "value": "0"
          }, {
            "label": "Thursday",
            "value": "0"
          }, {
            "label": "Friday",
            "value": "0"
          }, {
            "label": "Saturday",
            "value": "0"
          }]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource.data[i].value = res['data'][i];
        }
        // console.log(this.dataSource.data)
      }
    });
  }

  getData() {
    this.service.getDataList(`v1/admin/customer/dashboard/`).subscribe((res) => {
      if (res['success'] == true) {
        console.log("requiredDataDashboard", res);

        this.countData = res['count'];
        this.newData = res['newlist'];
        this.totalData = res['totalList'];
      }
    })
  }

}
