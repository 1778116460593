import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular7-csv';
import * as moment from 'moment';

// import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar, MatInput } from '@angular/material';
@Component({
  selector: 'app-manage-revenue',
  templateUrl: './manage-revenue.component.html',
  styleUrls: ['./manage-revenue.component.scss']
})
export class ManageRevenueComponent implements OnInit {
  // startDate: any;
  // endDate: any;
  adminId: any
  dataSource: any = {};
  chartConfig: Object;
  type: any = 2
  usersession: any;
  config: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  }
  currentPage: Number;
  onload: boolean = false

  page = 1;

  permissions: any;
  Data: any;
  admin: any;
  revenue: any;
  startDate: any;
  endDate: any;
  //  enddate:any;

  @ViewChild('enddate', {
    read: MatInput
  }) enddate: MatInput;
  @ViewChild('startdate', {
    read: MatInput
  }) startdate: MatInput;
  constructor(private service: ApiService, private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar) {
    this.chartConfig = {
      width: '100%',
      height: '400',
      type: 'column2d',
      dataFormat: 'json',
    };
    this.getWeek();


  }
  select() {
    switch (this.type) {
      // case 'day':
      //   this.getDay(1,'');
      //   break;
      case '2':
        this.getWeek();
        break;
      case '4':
        this.getYear();
        break;
      default:
        this.getWeek();
        break;
    }
  }
  ngOnInit() {
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.usersession = JSON.parse(localStorage.getItem('curruntUser'));
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.currentPage = 1
    if (JSON.parse(localStorage.getItem('permissions')))
      this.permissions = JSON.parse(localStorage.getItem('permissions')).revenue
    this.getWeek();
    this.getPaymentList(this.currentPage);
  }



  exportCSV(driverId) {

    // var id = driverId._id;
    // console.log("driverid",id)
    let data1 = {
      id: driverId._id,
      startdate: this.startDate,
      enddate: this.endDate,
    }
    console.log("date", this.startDate, this.endDate)
    this.service.getdriverdata(data1).subscribe((res: any) => {
      console.log(res, "export");
      this.revenue = res['revenue'];

      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: []
      };
      var data = [];
      let total = 0
      this.revenue.forEach(element1 => {
        total = element1.totalAmount

        data.push({ "Driver Name ": "Driver Name", "Contact Number": "Contact Number", "Email": "Email" })
        data.push({
          drivername: element1.firstName + " " + element1.lastName,
          Contact: element1.countryCode + " " + element1.phone,
          Email: element1.email,

        })

        if (element1.currentBookings.length > 0 && element1.currentBookings[0].OrderNo) {
          data.push({})
          data.push({ "OrderNo": "OrderNo", "Source": "Source", "Destination": "Destination", "Time": "Time", "Fare": "Fare", "Product Amount": "Product Amount", "Initial Payment": "Initial Payment", "merchant": "merchant", "payment": "payment", "Distance": "distance" })
          element1.currentBookings.forEach(element => {
            let status = ''

            if (element.isPaidInitial == 1 || element.isPaidInitial == 0) {
              switch (Number(element.isPaid)) {
                case 0: status = "customer not available";
                  break;
                case 1: status = "paid";
                  break;
                case 2: status = "customer return product"
                  break;
                case 3: status = 'Product Delivered but not paid'
              }
            }
            data.push({

              OrderNo: element.OrderNo ? element.OrderNo : "",
              source: element.source.name ? element.source.name : "",
              destination: element.destination.name ? element.destination.name : "",
              tripStartTime: moment(element.tripStartTime).format('MMMM Dd YYYY, h:mm:ss a'),
              fare: element.fare,
              ProductAmount: element.itemAmount ? element.itemAmount : "0",
              InitialPayment: element.isPaidInitial ? "Online" : "COD",
              merchant: element.storeId ? element.storeId.storeName : '',
              payment: status,
              distance: element.distance ? element.distance : ''

            })
          });
          data.push({
            source: "",
            destination: "",
            tripStartTime: "Total",
            fare: total,
          })
        }
      });

      new AngularCsv(data, 'Driver Booking report', options);
    })

  }
  exportAllCSV() {
    let data = {
      page: 1,
      startdate: this.startDate,
      enddate: this.endDate,
      sendall: 1
    }

    this.service.getrevenueData(data).subscribe((res: any) => {
      console.log(res, "data");
      this.revenue = res['drivers']
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        useBom: true,
        headers: []
      };
      var data = [];
      let total = 0
      this.revenue.forEach(element1 => {
        total = element1.totalAmount

        data.push({ "Driver Name ": "Driver Name", "Contact Number": "Contact Number", "Email": "Email", "TotalEarnings": "TotalEarnings" })
        data.push({
          drivername: element1.firstName + " " + element1.lastName,
          Contact: element1.countryCode + " " + element1.phone,
          Email: element1.email,
          totalAmount: element1.totalAmount,

        })

        if (element1.currentBookings.length > 0) {
          data.push({})
          data.push({ "OrderNo": "OrderNo", "Source": "Source", "Destination": "Destination", "Time": "Time", "Fare": "Fare", "Product Amount": "Product Amount", "Initial Payment": "Initial Payment", "merchant": "merchant", "payment": "payment", "Distance": "distance" })
          element1.currentBookings.forEach(element => {
            let status = ''

            if (element.isPaidInitial == 1 || element.isPaidInitial == 0) {
              switch (Number(element.isPaid)) {
                case 0: status = "customer not available";
                  break;
                case 1: status = "paid";
                  break;
                case 2: status = " customer return product"
                  break;
                case 3: status = 'Product Delivered but not paid'
              }
            }
            data.push({
              OrderNo: element.OrderNo ? element.OrderNo : "",
              source: element.source.name ? element.source.name : "",
              destination: element.destination.name ? element.destination.name : "",
              tripStartTime: moment(element.tripStartTime).format('MMMM Do YYYY, h:mm:ss a'),
              fare: element.fare,
              ProductAmount: element.itemAmount ? element.itemAmount : "0",
              InitialPayment: element.isPaidInitial ? "Online" : "COD",
              merchant: element.storeId ? element.storeId.storeName : '',
              payment: status,
              distance: element.distance ? element.distance : ''

            })
          });
          data.push({
            source: "",
            destination: "",
            tripStartTime: "Total",
            fare: total,
          })
        }
      });

      new AngularCsv(data, 'Driver Report', options);
    })

  }
  getPaymentList(page) {
    let data = {
      page: page,
      startdate: this.startDate,
      enddate: this.endDate,
      sendall: 0
    }

    this.service.getrevenueData(data).subscribe((res: any) => {
      console.log(res, "data");
      this.onload = true

      this.admin = res['drivers']
      // this.paymentData = this.admin.data
      this.onload = true
      this.config = {
        id: "page",
        currentPage: page,
        itemsPerPage: 10,
        totalItems: res.total
      }
    });
  }
  pageChange(newPage: number) {
    console.log("newPage", newPage)
    this.getPaymentList(newPage)
  }
  getStartDate(ev) {
    this.startDate = moment(ev.value).startOf('day').valueOf();
    console.log('Startdate', this.startDate)
  }

  resetFilter() {
    this.startDate = '',
      this.endDate = '',
      this.startdate.value = null;
    this.enddate.value = null;
    this.getPaymentList(this.currentPage);

  }

  getEndDate(ev) {
    console.log("DATE", ev)
    this.endDate = moment(ev.value).startOf('day').valueOf();
    console.log('enddate', this.endDate)
  }
  // getstartTime(ev) {

  //   this.startDate = moment(ev.value).valueOf();
  //   // this.startDate = ev.value;
  //   // console.log('date', this.startDate)
  // }
  //  getendTime(ev){
  //    this.endDate = ev.value;
  //  }
  // apply() {
  //   this.getDateData(1, this.startDate,this.endDate);
  // }

  // getEndDate(ev) {
  //   this.endDate = moment(ev.value).valueOf();
  //   // console.log('enddate', this.endDate)
  // }
  // getDay(type,date) {
  //   this.service.getSelectedDateDataFromDb(type, date).subscribe((res)=>{

  //     if(res['success']==true){

  //       // console.log("getDayData-----", res['data']);
  //   this.dataSource = {
  //     "chart": {
  //       "caption": "Day analytics",
  //       "subCaption": "",
  //       "xAxisName": "Hours",
  //       "yAxisName": "",
  //       "numberSuffix": "",
  //       "theme": "fusion",
  //     },
  //     "data": [
  //       {
  //         "label": "1:00p.m. - 2:00a.m.",
  //         "value": "0"
  //       },
  //       {
  //         "label": "2:00p.m. - 4:00a.m.",
  //         "value": "0"
  //       },
  //       {
  //         "label": "4:00p.m. - 6:00a.m.",
  //         "value": "0"
  //       },
  //       {
  //       "label": "6:00a.m. - 8:00a.m.",
  //       "value": "0"
  //     }, 
  //     {
  //       "label": "8:00a.m. - 10:00a.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "10:00a.m. - 12:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "12:00p.m. - 14:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "14:00p.m. - 16:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "16:00p.m. - 18:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "18:00p.m. - 20:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "20:00p.m. - 22:00p.m.",
  //       "value": "0"
  //     },
  //     {
  //       "label": "22:00p.m. - 24:00a.m.",
  //       "value": "0"
  //     }
  //     ]
  //   }

  //   for (var i = 0; i < res['data'].length; i++) {
  //     this.dataSource.data[i].value = res['data'][i];
  //   }
  //   // console.log(this.dataSource.data)
  //   }
  // });

  // }
  getYear() {
    this.service.getdata(this.type, this.adminId).subscribe((res) => {

      if (res['success'] == true) {

        // console.log("getDayData-----", res['data']);
        this.dataSource = {
          "chart": {
            "caption": "Yearly analytics",
            "subCaption": "",
            "xAxisName": "Months",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Jan",
            "value": "290"
          }, {
            "label": "Feb",
            "value": "0"
          }, {
            "label": "March",
            "value": "0"
          }, {
            "label": "April",
            "value": "0"
          }, {
            "label": "May",
            "value": "0"
          }, {
            "label": "June",
            "value": "0"
          }, {
            "label": "July",
            "value": "0"
          },
          {
            "label": "Aug",
            "value": "0"
          },
          {
            "label": "Sep",
            "value": "0"
          },
          {
            "label": "Oct",
            "value": "0"
          },
          {
            "label": "Nov",
            "value": "0"
          },
          {
            "label": "Dec",
            "value": "0"
          },
          ]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource.data[i].value = res['data'][i];
        }
        // console.log(this.dataSource.data)
      }
    });
  }
  /*getDays(){
  
    this.dataSource = {
      "chart": {
        "caption": "Analytics",
        "subCaption": "",
        "xAxisName": "Days",
        "yAxisName": "",
        "numberSuffix": "K",
        "theme": "fusion",
      },
      "data": [{
        "label": "Day 1",
        "value": "100"
      }, {
        "label": "Day 2",
        "value": "200"
      }, {
        "label": "Day 3",
        "value": "180"
      }, {
        "label": "Day 4",
        "value": "290"
      }, {
        "label": "Day 5",
        "value": "95"
      }
    ]
    };
  }*/
  // getMonth() {

  //   this.service.getdata(this.type, this.adminId).subscribe((res)=>{

  //     if(res['success']==true){

  //       // console.log("getmonthData-----", res['data']);

  //   this.dataSource = {
  //     "chart": {
  //       "caption": "Monthly analytics",
  //       "subCaption": "",
  //       "xAxisName": "Week",
  //       "yAxisName": "",
  //       "numberSuffix": "",
  //       "theme": "fusion",
  //     },
  //     "data": [{
  //       "label": "Week 1",
  //       "value": "100"
  //     }, {
  //       "label": "Week 2",
  //       "value": "200"
  //     }, {
  //       "label": "Week 3",
  //       "value": "180"
  //     }, {
  //       "label": "Week 4",
  //       "value": "290"
  //     }, {
  //       "label": "Week 5",
  //       "value": "95"
  //     }
  //     ]
  //   };

  //     for (var i = 0; i < res['data'].length; i++) {
  //       this.dataSource.data[i].value = res['data'][i];
  //     }
  //     //  console.log(this.dataSource.data)
  //   }

  //   });
  // }


  getWeek() {

    this.service.getdata(this.type, this.adminId).subscribe((res) => {

      if (res['success'] == true) {

        // console.log(res['data']);
        this.dataSource = {
          "chart": {
            "caption": "Weekly analytics",
            "subCaption": "",
            "xAxisName": "Days",
            "yAxisName": "",
            "numberSuffix": "",
            "theme": "fusion",
          },
          "data": [{
            "label": "Sunday",
            "value": "0"
          }, {
            "label": "Monday",
            "value": "0"
          }, {
            "label": "Tuesday",
            "value": "0"
          }, {
            "label": "Wednesday",
            "value": "0"
          }, {
            "label": "Thursday",
            "value": "0"
          }, {
            "label": "Friday",
            "value": "0"
          }, {
            "label": "Saturday",
            "value": "0"
          }]
        };

        for (var i = 0; i < res['data'].length; i++) {
          this.dataSource.data[i].value = res['data'][i];
        }
        // console.log(this.dataSource.data)
      }
    });
  }

  // getDateData(type, start,end){
  //   this.service.getSelectedDateFromDb(type, start,end).subscribe((res)=>{

  //     if(res['success']==true){

  //       // console.log("selected date-----", res['data']);


  //       this.dataSource = {
  //         "chart": {
  //           "caption": "Day analytics",
  //           "subCaption": "",
  //           "xAxisName": "Hours",
  //           "yAxisName": "",
  //           "numberSuffix": "",
  //           "theme": "fusion",
  //         },
  //         "data": [
  //           {
  //             "label": "1:00p.m. - 2:00a.m.",
  //             "value": "0"
  //           },
  //           {
  //             "label": "2:00p.m. - 4:00a.m.",
  //             "value": "0"
  //           },
  //           {
  //             "label": "4:00p.m. - 6:00a.m.",
  //             "value": "0"
  //           },
  //           {
  //           "label": "6:00a.m. - 8:00a.m.",
  //           "value": "0"
  //         }, {
  //           "label": "8:00a.m. - 10:00a.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "10:00a.m. - 12:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "12:00p.m. - 14:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "14:00p.m. - 16:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "16:00p.m. - 18:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "18:00p.m. - 20:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "20:00p.m. - 22:00p.m.",
  //           "value": "0"
  //         },
  //         {
  //           "label": "22:00p.m. - 24:00a.m.",
  //           "value": "0"
  //         },

  //         ]

  //     }
  //     for (var i = 0; i < res['data'].length; i++) {
  //       this.dataSource.data[i].value = res['data'][i];
  //     }
  //     // console.log(this.dataSource.data)
  //   }
  //   });



  // }
}

