import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { stringify } from '@angular/compiler/src/util';
import { STRING_TYPE } from '@angular/compiler/src/output/output_ast';
import { AmazingTimePickerService } from 'amazing-time-picker';


@Component({
  selector: 'app-add-drivers',
  templateUrl: './add-drivers.component.html',
  styleUrls: ['./add-drivers.component.scss']
})

export class AddDriversComponent implements OnInit {
  user: any = {}
  newformat: any
  formatedAddress: any;
  city: any;
  Time: any
  state: any;
  country: any;
  hour: any
  min: any
  startmin: any
  endhour: any
  minutes: any
  endmin: any
  minutess: any
  commission
  postCode: any;
  riderId: any
  profilePic: any;
  drivingLicense: any;
  numberPlate: any;
  taxiPermit: any;
  carLicense: any;
  adminId: any;
  hours: any
  shiftEndTimes: any = "00:00"
  shiftEndTime: any
  shiftStartTime: any;
  shiftStartTimes: any = "00:00"
  driverId: any;
  accountHolderName: any
  accountNumber: any
  swiftCode: any
  startTimes: any
  endTimes: any
  editDriver: any = '';
  vihicleList: any;
  endhours: any
  options: {
    types: [],
    // componentRestrictions: { country: 'UA' }
  }
  public allowDoc = ['jpg', 'jpeg', 'png', 'pdf'];
  public allowDocError: string;
  constructor(private route: ActivatedRoute, private atp: AmazingTimePickerService, private router: Router, private service: ApiService, public snackBar: MatSnackBar) { }
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    this.user.countryCode = '+';
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser.adminType == 1 ? curruntUser._id : curruntUser.adminId
    this.route.params.subscribe(params => {
      this.driverId = params['id'];
      // console.log(this.driverId);
      if (this.driverId != '' && this.driverId != undefined && this.driverId != null) {
        this.getDriverInfo();
        this.editDriver = 'Edit';
        // console.log("params----"+params['id']);
      }
    });
    console.log(this.shiftStartTimes);

    this.getVehicleList();
  }

  goToManageDriver() {
    this.router.navigate(['/admin/manage-drivers']);
  }

  open(event) {
    console.log("event", event)
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      console.log(time, amazingTimePicker);
      this.shiftStartTimes = time
      console.log(time, this.shiftStartTimes);
      let times = this.shiftStartTimes.split(":")
      this.hour = parseInt(times[0].trim());
      this.min = parseInt(times[1].trim());
      console.log("hour", this.hour)
      console.log("min", this.min)
    });
    // let time = this.shiftStartTimes.split(":")
    // this.hour = parseInt(time[0].trim());
    // this.min = parseInt(time[1].trim());
    // console.log("hour", this.hour)
    // console.log("min", this.min)
  }
  getTime(event) {
    console.log("event", event)
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      console.log(time, amazingTimePicker);
      this.shiftEndTimes = time
      console.log(time, this.shiftEndTimes);
      let endTime = this.shiftEndTimes.split(":")
      this.endhour = parseInt(endTime[0].trim());
      this.endmin = parseInt(endTime[1].trim());
      console.log("hour", this.endhour)
      console.log("min", this.endmin)
    });

  }
  handleCountryChnage(address) {
    this.country = '';

    // console.log(address);

    // if (address.formatted_address) {
    //   this.formatedAddress = address.formatted_address
    // }
    address.address_components.forEach(key => {
      // if(key.types[0]=='locality'){
      //   this.city = key.long_name
      // }
      // // if (key.types[0] === 'administrative_area_level_2') {
      // //   this.city = key.long_name
      // // }
      // if (key.types[0] == 'administrative_area_level_1') {
      //   this.state = key.long_name
      // }
      if (key.types[0] == 'country') {
        this.country = key.long_name
      }
      // if (key.types[0] =='postal_code') {
      //   this.postCode = key.long_name
      // }
    });
  }

  handleStateChnage(address) {
    this.state = '';
    address.address_components.forEach(key => {

      if (key.types[0] == 'administrative_area_level_1') {
        this.state = key.long_name
      }
    });
  }

  handleCityChnage(address) {
    this.city = '';
    address.address_components.forEach(key => {
      if (key.types[0] == 'locality') {
        this.city = key.long_name
      }
    });
  }
  onSubmit(form) {

    console.log(form.valid, this.drivingLicense, this.numberPlate, this.taxiPermit)
    if (form.valid) {
      if (this.editDriver == '') {
        if (this.carLicense && this.drivingLicense && this.numberPlate && this.taxiPermit) {


          let shiftStartTime = this.hour || this.min ? {
            'hour': this.hour, 'minute': this.min
          } : undefined
          let shiftEndTime = this.endhour || this.endmin ? {
            'hour': this.endhour, 'minute': this.endmin
          } : undefined

          var driverData = {
            'firstName': this.user.firstName,
            'lastName': this.user.lastName,
            'email': this.user.email,
            'phone': this.user.phone,
            'vehicleType': this.user.vehicleType,
            'countryCode': this.user.countryCode,
            'adminId': this.adminId,
            'Commission': this.commission,
            'country': this.country,
            'state': this.state,
            'city': this.city,
            'password': this.user.password,
            'vehicleName': this.user.vehicleName,
            'vehicleNumber': this.user.vehicleNumber,
            'model': this.user.model,
            shiftStartTime,
            shiftEndTime
            ,
            'riderId': this.user.riderId,
            'vehicleColor': this.user.vehicleColor,
            'manufacturerName': this.user.manufacturerName
          }
          console.log("driverData..........", driverData)
          this.service.createDriver(driverData, this.carLicense, this.drivingLicense, this.numberPlate, this.taxiPermit).subscribe((res) => {

            if (res['success'] == true) {
              this.user = '';
              this.snackBar.open(res['message'], 'x', {
                duration: 5000,
              })
              this.router.navigate(['/admin/manage-drivers']);

            } else {
              this.snackBar.open(res['message'], 'x', {
                duration: 5000,
              })
            }

          });

        }

      } else {
        console.log(this.hour, this.min, this.endhour, this.endmin)
        let shiftStartTime = this.hour || this.min ? {
          'hour': this.hour, 'minute': this.min
        } : undefined
        let shiftEndTime = this.endhour || this.endmin ? {
          'hour': this.endhour, 'minute': this.endmin
        } : undefined
        var data = {
          'firstName': this.user.firstName,
          'lastName': this.user.lastName,
          'email': this.user.email,
          'phone': this.user.phone,
          'vehicleType': this.user.vehicleType,
          'countryCode': this.user.countryCode,
          'adminId': this.adminId,
          'driverId': this.driverId,
          'country': this.country,
          'commission': this.commission,
          'state': this.state,
          'shiftStartTime': shiftStartTime,
          'shiftEndTime': shiftEndTime,
          'city': this.city,
          'riderId': this.user.riderId,
          'vehicleName': this.user.vehicleName,
          'vehicleNumber': this.user.vehicleNumber,
          'model': this.user.model,
          'vehicleColor': this.user.vehicleColor,
          'manufacturerName': this.user.manufacturerName
        }
        console.log(data, "data")
        this.service.updateDriver(data).subscribe((res) => {
          if (res['success'] == true) {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.router.navigate(['/admin/manage-drivers']);
          }
          else {
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
          }

        });

      }
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        allowOutsideClick: false,
        text: 'Please fill all details',
      })
    }
  }
  onDrivingLicenseUpload(event) {
    this.allowDocError = '';
    var fileName = event.target.files[0];
    if (this.allowFile(fileName.name))
      this.drivingLicense = event.target.files[0];
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    }
  }

  onNumberPlateUpload(event) {

    // console.log("this.profilePic", this.numberPlate);
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.numberPlate = event.target.files[0];
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    }
  }

  onTaxiPermitUpload(event) {

    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.taxiPermit = event.target.files[0];
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    }
  }

  onCarLicenseUpload(event) {
    this.allowDocError = '';
    var fileName = event.target.files[0];
    console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name))
      this.carLicense = event.target.files[0];
    else {
      event.target.value = '';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    }
  }

  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }

  getDriverInfo() {

    this.service.getDriverInfoFromDb(this.driverId).subscribe((res) => {

      if (res['success'] == true) {
        console.log(res);

        this.country = res['driver'].country;
        this.state = res['driver'].state;
        this.city = res['driver'].city;
        this.user = res['driver'];
        this.Time = res
        console.log("time", this.Time)
        this.startTimes = this.Time.driver.shiftStartTime.hour,
          console.log("startTimes.......1", this.startTimes)
        this.newformat = this.hours >= 12 ? 'PM' : 'AM'
        this.hours = this.startTimes % 12
        this.hours = this.hours ? this.hours : 12;
        this.commission = res['driver'].commission
        this.startmin = this.Time.driver.shiftStartTime.minute
        this.startmin = this.startmin < 10 ? '0' + this.startmin : this.startmin;
        this.shiftStartTimes = this.hours + ':' + this.startmin + ' ';
        this.user.vehicleType = res['driver'].vehicleType._id
        console.log("res", res)
        console.log("shiftStartTimes", this.shiftStartTimes)
        this.endTimes = this.Time.driver.shiftEndTime.hour;
        this.endhours = this.endTimes % 12
        this.endhours = this.endhours ? this.endhours : 12;
        this.endmin = this.Time.driver.shiftEndTime.minute
        this.endmin = parseInt(this.endmin < 10 ? '0' + this.endmin : this.endmin);
        this.shiftEndTimes = this.endhours + ':' + this.endmin + ' ';
        this.minutess = parseInt(this.endmin < 10 ? '0' + this.endmin : this.endmin);
        this.shiftEndTimes = this.endhours + ':' + this.minutess + ' ' + this.newformat;
        this.shiftEndTimes = this.endTimes + ':' + this.endmin;
        console.log("shiftEndTimes............", this.shiftEndTimes)
      }
    });
  }
  getVehicleList() {
    this.service.getVehicleListFromDb(this.adminId).subscribe((res) => {

      if (res['success'] == true) {
        this.vihicleList = res['vehicletype'];
      }
    });
  }

  changeValue(vm) {
    // this.user.countryCode= '+'+vm.target.value
    if (this.user.countryCode != null && this.user.countryCode.includes('+')) {
      this.user.countryCode = vm.target.value;
    } else {
      this.user.countryCode = '+' + vm.target.value;

    }
    console.log('changeValue', this.user.countryCode);
  }
}
