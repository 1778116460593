import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vacant-drivers-list',
  templateUrl: './vacant-drivers-list.component.html',
  styleUrls: ['./vacant-drivers-list.component.scss']
})
export class VacantDriversListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  goBack()
  {
    
  }
}
