import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-manage-add-user',
  templateUrl: './manage-add-user.component.html',
  styleUrls: ['./manage-add-user.component.scss']
})
export class ManageAddUserComponent implements OnInit {
  user:any={}
  response:any;
  userId:any;
  adminId:any;
  userInfo:any;
  editUser:string='';
  regrexEmail:any;
  
  constructor(private route: ActivatedRoute,private router: Router, private service:ApiService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    // this.regrexEmail = '^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,3})$';
    this.user.countryCode = '+';
    this.regrexEmail = '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      // console.log(this.userId);
      if(this.userId!='' && this.userId!=undefined && this.userId!=null){
        this.getUserInfo();
        this.editUser = 'Edit'
        // console.log("params----"+params['id']);
      }
      
      
    });

  
 
  }

  


  onSubmit(form){

    if(form.valid){
    
    // alert('fdsfsf');
    if(this.editUser!=''){
      var data = {
        "userId":this.userId,
        "firstName": this.user.firstName,
        "lastName": this.user.lastName,
        "email": this.user.email,
        "password":this.user.password,
        "phone":this.user.phone,
        "countryCode":this.user.countryCode
      }

      this.service.editUserInfo(data).subscribe((res)=>{
  
        // console.log(JSON.stringify(res));
  
        if(res['success']==true){
          // console.log(res);
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
          setTimeout(() => {
            this.router.navigate(['/admin/manage-user'])
        }, 500);  
    
        

          // this.user='';
        } else {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }
  
      });
      

    } else {
      
      
      var userData = {
        "adminId":this.adminId,
        "firstName": this.user.firstName,
        "lastName": this.user.lastName,
        "email": this.user.email,
        "password":this.user.password,
        "phone":this.user.phone,
        "countryCode":this.user.countryCode
      }
      // console.log(userData);
      this.service.registerUser(userData).subscribe((res)=>{
  
        // console.log(JSON.stringify(res));
  
        if(res['success']==true){
          // console.log(res);
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
          this.user='';
          this.router.navigate(['/admin/manage-user']);
        } else {
          this.snackBar.open(res['message'], 'x', {
            duration: 5000,
          })
        }
  
      });
    }
  } else {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      confirmButtonColor:'#821727',
      allowOutsideClick:false,
      text: 'Please fill all details',
    })
  }
   
}

resetFormValue(){
 
  let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
  this.adminId = curruntUser._id;
  this.route.params.subscribe(params => {
    this.userId = params['id'];
    // console.log(this.userId);
    if(this.userId!='' && this.userId!=undefined && this.userId!=null){
      this.getUserInfo();
      this.editUser = 'Edit'
      // console.log("params----"+params['id']);
    }
    
    
  });
}

getUserInfo(){
  // console.log("userId----", this.userId);
  this.service.getUserInfoFromDb(this.userId).subscribe((res)=>{

    if(res['success']==true){

      // console.log("userInfo", res);
      this.user = res['user'];

    }

  });
}


goToManageUser()
{
  this.router.navigate(['/admin/manage-user']);
}


changeValue(vm){
 
  // this.user.countryCode= '+'+vm.target.value
  if(this.user.countryCode!=null && this.user.countryCode.includes('+')){
    this.user.countryCode = vm.target.value;
  } else {
    this.user.countryCode= '+'+vm.target.value;
    
  }
  console.log('changeValue', this.user.countryCode);
}
}
