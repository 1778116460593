import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-car-categories',
  templateUrl: './add-car-categories.component.html',
  styleUrls: ['./add-car-categories.component.scss']
})
export class AddCarCategoriesComponent implements OnInit {
  user: any = {};
  image: any
  activeImage: any
  url: any = this.service.baseUrl
  vehicleImage: any;
  vehicleActiveImage: any;
  history: any
  adminId: any;
  urlvImage: any = ""
  urlactiveImage: any = ""
  carId: any;
  editCar: string = '';
  public allowDoc = ['jpg', 'jpeg', 'png'];
  public allowDocError: string;

  defaultDisable: boolean = false;

  constructor(private service: ApiService, public snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) {

    this.history = window.history
  }

  ngOnInit() {
    // console.log("currentUrl====", this.router.url);
    localStorage.setItem("curruntPath", JSON.stringify(this.router.url));
    let curruntUser = JSON.parse(localStorage.getItem('curruntUser'));
    this.adminId = curruntUser._id;
    this.route.params.subscribe(params => {
      this.carId = params['id'];
      if (this.carId != '' && this.carId != undefined && this.carId != null) {
        this.getCarInfo();
        console.log('hjgjhfhj', this.url)
        this.editCar = 'Edit';
        //     console.log("params----"+params['id']);
      }
    });

  }
  getCarInfo() {
    this.service.getCarInfoFromDb(this.carId).subscribe((res) => {

      if (res['success'] == true) {
        this.user = res['category'];
        this.urlvImage = this.service.baseUrl + this.user.image
        this.urlactiveImage = this.service.baseUrl + this.user.activeImage
        console.log("this.service.baseUrl + this.user.image", this.service.baseUrl + this.user.image);


        console.log("vehicleImagevehicleImage", this.service.baseUrl + this.user.activeImage)

      }
    });
  }
  onSubmit(form) {
    if (form.valid) {

      this.defaultDisable = true;
      if (this.editCar != '') {
        var carData = {
          'name': this.user.name,
          'fareRate': this.user.fareRate,
          'fareChangekm': this.user.fareChangekm,
          'baseFare': this.user.baseFare,
          'fareRateAfter': this.user.fareRateAfter,
          'typeId': this.carId,
          'persons': this.user.persons,
          'type': 0
        }
        this.vehicleActiveImage = this.vehicleActiveImage ? this.vehicleActiveImage : '';
        this.vehicleImage = this.vehicleImage ? this.vehicleImage : '';

        this.service.editCarInfo(carData, this.vehicleImage, this.vehicleActiveImage).subscribe((res) => {
          if (res['success'] == true) {
            // console.log(res);
            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })
            this.history.back()
          } else {
            // console.log('please try later')
          }

        });

      } else if (this.vehicleImage && this.vehicleActiveImage) {
        var carData1 = {
          'name': this.user.name,
          'fareRate': this.user.fareRate,
          'fareChangekm': this.user.fareChangekm,
          'baseFare': this.user.baseFare,
          'fareRateAfter': this.user.fareRateAfter,
          'adminId': this.adminId,
          'persons': this.user.persons,
          'type': 0
        }
        // console.log("user-----", carData1);
        // console.log(this.vehicleImage, this.vehicleActiveImage);
        this.service.createCarCategories(carData1, this.vehicleImage, this.vehicleActiveImage).subscribe((res) => {

          if (res['success'] == true) {
            this.user = '';

            this.snackBar.open(res['message'], 'x', {
              duration: 5000,
            })

            this.router.navigate(['/admin/manage-car-category']);

          }

        });
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          confirmButtonColor: '#821727',
          allowOutsideClick: false,
          text: 'Please fill all details',
        })
      }

    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        allowOutsideClick: false,
        text: 'Please fill all details',
      })
    }

  }
  allowFile(filename) {
    if (filename)
      return this.allowDoc.indexOf(/[^.]+$/.exec(filename)[0]) != -1 ? true : false;
  }

  onImageUpload(event) {
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name)) {
      this.vehicleImage = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlvImage = event.target.result;
      }
    }

    // else {
    //   event.target.value = '';
    //   // this.allowDocError = 'Please choose file jpg,jpeg and png';
    //   Swal.fire({
    //     type: 'error',
    //     title: 'Oops...',
    //     confirmButtonColor: '#821727',
    //     text: 'Please select only file jpg,jpeg and png',
    //   })
    // }
  }
  onActiveImageUpload(event) {

    console.log("vehicleActiveImage ", this.vehicleActiveImage);
    this.allowDocError = '';
    var fileName = event.target.files[0];
    // console.log(this.allowFile(fileName.name));
    if (this.allowFile(fileName.name)) {
      this.vehicleActiveImage = event.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.urlactiveImage = event.target.result;
      }
    }
    else {
      event.target.value = '';
      // this.allowDocError = 'Please choose file jpg,jpeg and png';
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        confirmButtonColor: '#821727',
        text: 'Please select only file jpg,jpeg and png',
      })
    }
  }
  goToManageCarCategory() {
    this.router.navigate(['/admin/manage-car-category']);
  }
}
