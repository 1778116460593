import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private service: ApiService,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) { }


  login(form) {
    const baseUrl = this.service.baseUrl
    if (form.userName !== '' && form.password !== '') {
      this.loggedIn.next(true);
      this.http.post(baseUrl + `v1/admin/customer/login`, form)
        .subscribe(
          (data: any) => {
            
            if (data.success) {
              localStorage.setItem("curruntUser", JSON.stringify(data.driver));
              // this.router.navigate(['/admin/admin-dashboard']);
            } else {
              this.snackBar.open(data.message, 'x', {
                duration: 5000,
              })
            }
          }
        )

    }
  }


  logout() {
    this.loggedIn.next(false);
    localStorage.clear();
    this.router.navigate(['']);
  }
}
